import { Button } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useIndividualCustomerStore from "@/stores/useIndividualCustomerStore";

import FilterSheet from "@/components/individualCustomers/filterSheet";
import FilterTags from "@/components/individualCustomers/filterTags";
import RemindWarrantySheet from "@/components/individualCustomers/remindWarrantySheet";
import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import FilterIcon from "@/assets/icons/filter.svg?react";

const IndividualCustomers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [openRemindWarrantySheet, setOpenRemindWarrantySheet] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const { customers, loading, filters, getIndividuaCustomers, setFilters } =
    useIndividualCustomerStore();

  useEffect(() => {
    getIndividuaCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getIndividuaCustomers();
  };

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    setFilters({ ...filters, size, page: 1 });
    getIndividuaCustomers();
  };

  const onClickItem = (e, id) => {
    e.stopPropagation();
    navigate(`/customers/individual/${id}`);
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end pb-4"></div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
              className="min-w-[150px] font-medium"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={3} />
            </Table>
          ) : customers.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("common.customer")}</TableHead>
                <TableHead>{t("common.email")}</TableHead>
                <TableHead>{t("common.phone")}</TableHead>
                <TableHead>{t("common.actions")}</TableHead>
              </TableHeader>
              <TableBody>
                {customers?.map((customer) => (
                  <TableRow
                    role="button"
                    onClick={(e) => onClickItem(e, customer.id)}
                    key={customer.id}
                  >
                    <TableCell>
                      {customer.firstName} {customer.lastName}
                    </TableCell>
                    <TableCell>{customer.email}</TableCell>
                    <TableCell>
                      <Tag size="sm">{customer.phone}</Tag>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="secondaryGray"
                        disabled={
                          customer.emailVerified && customer.phoneVerified
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedClientId(customer.id);
                          setOpenRemindWarrantySheet(true);
                        }}
                      >
                        {t("clientsPage.remindWarranty")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("clientsPage.clientCouldNotFound")}
              desc={t("clientsPage.clientCouldNotFoundDesc")}
            />
          )}
          <div className="flex flex-col items-center">
            {filters.totalPages > 1 && (
              <Pagination
                totalPages={filters.totalPages}
                currentPage={filters.page}
                pageSize={filters.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            <PageSizeSelect
              onPageSizeChange={onPageSizeChange}
              value={filters.size}
            />
          </div>
        </div>
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
      <RemindWarrantySheet
        open={openRemindWarrantySheet}
        onOpenChange={setOpenRemindWarrantySheet}
        selectedClientId={selectedClientId}
      />
    </main>
  );
};

export default IndividualCustomers;
