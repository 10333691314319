import useAuthStore from "@/stores/useAuthStore";

const CheckRole = ({ children, section, action }) => {
  const user = useAuthStore((state) => state.user);
  // action is either VIEW or EDIT
  // section is either PRODUCTS, ACTIVATIONS, APPS, CLIENTS, DOCUMENTS, CLAIMS

  // example of roles array
  //   {
  //     "PRODUCTS": [
  //         "VIEW",
  //         "EDIT"
  //     ],
  //     "ACTIVATIONS": [
  //         "VIEW",
  //         "EDIT"
  //     ],
  //     "APPS": [
  //         "VIEW",
  //         "EDIT"
  //     ],
  //     "CLIENTS": [
  //         "VIEW",
  //         "EDIT"
  //     ],
  //     "DOCUMENTS": [
  //         "VIEW",
  //         "EDIT"
  //     ],
  //     "CLAIMS": [
  //         "VIEW",
  //         "EDIT"
  //     ]
  // }

  const roles = user?.roles;

  if (!roles?.[section]) return children;

  const hasRole = roles?.[section]?.includes(action);

  return hasRole ? children : null;
};

export default CheckRole;
