import { Button, Input, useToast } from "@egaranti/components";

import React, { useEffect } from "react";

import axios from "@/lib/axios";

let uniqueId = 0;
const generateUniqueId = () => {
  return `temp-id-${uniqueId++}`;
};

const TemporaryForm = () => {
  const { toast } = useToast();
  const [data, setData] = React.useState([]);

  useEffect(() => {
    axios.get("/merchant-trendyol-integrations/seller").then((res) => {
      setData(
        res.data.map((item) => ({
          sellerName: item.sellerName,
          supplierId: item.supplierId,
          apiKey: item.apiKey,
          apiSecretKey: item.apiSecretKey,
          id: item.id,
          exists: true,
        })),
      );
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = data.filter(
      (item) =>
        item.sellerName && item.supplierId && item.apiKey && item.apiSecretKey,
    );

    axios
      .post("/merchant-trendyol-integrations/seller", payload)
      .then((res) => {
        toast({
          variant: "success",
          title: "Kaydedildi",
        });
      });
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setData((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, [name]: value };
        }
        return item;
      }),
    );
  };

  const handleDelete = (id) => {
    setData((prev) => prev.filter((item) => item.id !== id));

    const itemToDelete = data.find((item) => item.id === id);
    if (itemToDelete?.exists) {
      axios
        .delete(`/merchant-trendyol-integrations/seller/${id}`)
        .then((res) => {
          toast({
            variant: "success",
            title: "Silindi",
          });
        });
    }
  };

  const addNewItem = () => {
    setData([...data, { id: generateUniqueId() }]);
  };

  return (
    <div className="formBox flex max-w-md flex-col gap-4">
      <h2 className="text-2xl font-semibold text-gray-700">Bayi Hesapları</h2>
      {data?.map((item, index) => (
        <div key={item.id} className="flex gap-4 border-b pb-4">
          <div className="flex flex-1 flex-col gap-4">
            <h4 className="text-lg font-semibold text-gray-700">
              Bayi {index + 1}
            </h4>
            <Input
              placeholder="Bayi Adı"
              name="sellerName"
              value={item.sellerName || ""}
              onChange={(e) => handleChange(e, item.id)}
            />
            <Input
              placeholder="tedarikçi id (supplierId)"
              name="supplierId"
              value={item.supplierId || ""}
              onChange={(e) => handleChange(e, item.id)}
            />
            <Input
              name="apiKey"
              placeholder="apiKey"
              value={item.apiKey || ""}
              onChange={(e) => handleChange(e, item.id)}
            />
            <Input
              name="apiSecretKey"
              placeholder="apiSecretKey"
              value={item.apiSecretKey || ""}
              onChange={(e) => handleChange(e, item.id)}
            />
          </div>
          <Button
            className="bg-red-500 text-white hover:bg-red-600"
            onClick={() => handleDelete(item.id)}
          >
            Sil
          </Button>
        </div>
      ))}
      <Button variant="secondaryColor" onClick={addNewItem}>
        Yeni Bayi Ekle
      </Button>
      <Button onClick={handleSubmit}>Kaydet</Button>
    </div>
  );
};

export default TemporaryForm;
