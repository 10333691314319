import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@egaranti/components";
import { Tabs } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ServiceActivationService from "@/services/serviceActivationService";

import AddProduct from "@/components/settings/serviceActivation/edit/addProduct";
import LinkedProducts from "@/components/settings/serviceActivation/edit/linkedProducts";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ActivationDetails = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(1);
  const [activationType, setActivationType] = useState("LINK");
  const [redirectionValue, setRedirectionValue] = useState("");
  const [countryCode, setCountryCode] = useState("TR");
  const [doesActivationExists, setDoesActivationExists] = useState(false);
  const [activationId, setActivationId] = useState("");
  const [claimOptions, setClaimOptions] = useState([]);
  const [newClaimOption, setNewClaimOption] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [selectProductActiveTab, setSelectProductActiveTab] = useState(1);
  const { toast } = useToast();

  useEffect(() => {
    fetchActivation();
    fetchClaimOptions();
  }, []);

  const fetchClaimOptions = () => {
    ServiceActivationService.getClaimOptions().then((res) => {
      setClaimOptions(res);
    });
  };

  const fetchActivation = () => {
    ServiceActivationService.getClaimActivations().then((res) => {
      if (res.type !== null) {
        setDoesActivationExists(true);
        setActivationId(res.id);
        setActivationType(res.type);
        setRedirectionValue(
          res.type === "PHONE" ? res.redirectValue.slice(3) : res.redirectValue,
        );
      }
    });
  };

  const onSubmit = () => {
    const bodyObj = {};

    if (activationType === "PHONE") {
      bodyObj.type = activationType;
      bodyObj.redirectValue = redirectionValue;
      bodyObj.countryCode = countryCode;
    } else {
      bodyObj.type = activationType;
      bodyObj.redirectValue = redirectionValue;
    }

    if (doesActivationExists) {
      ServiceActivationService.updateActivation(activationId, bodyObj).then(
        () => {
          toast({
            title: t("settingsPage.activationUpdateSuccess"),
            variant: "success",
          });
          fetchActivation();
        },
      );
    } else {
      ServiceActivationService.createActivation(bodyObj).then(() => {
        toast({
          title: t("settingsPage.activationCreateSuccess"),
          variant: "success",
        });
        fetchActivation();
      });
    }
  };

  const onDelete = () => {
    ServiceActivationService.deleteActivation(activationId).then(() => {
      toast({
        title: t("settingsPage.activationDeleteSuccess"),
        variant: "success",
      });
      setDoesActivationExists(false);
      setActivationId("");
    });
  };

  const addNewItem = (item) => {
    ServiceActivationService.createClaimOption(item).then(() => {
      fetchClaimOptions();
      toast({
        title: t("serviceActivationPage.optionCreateSuccess"),
        variant: "success",
      });
    });
  };
  const deleteItem = (id) => {
    ServiceActivationService.deleteClaimOption(id).then(() => {
      fetchClaimOptions();
      toast({
        title: t("serviceActivationPage.optionDeleteSuccess"),
        variant: "success",
      });
    });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/activations">
          {t("settingsPage.activations")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.serviceActivation")}
        </BreadcrumbItem>
      </Breadcrumb>
      <header className="container mx-auto my-6">
        <Tabs
          tabs={[
            { id: 1, title: t("serviceActivationPage.redirectionSetting") },
            { id: 2, title: t("serviceActivationPage.claimSettings") },
          ]}
          selectedTabId={activeTab}
          onTabChange={(id) => setActiveTab(id)}
        />
      </header>
      {activeTab === 1 ? (
        <div className="container mx-auto mb-20 flex flex-col items-center justify-center gap-6">
          <div className="formBox">
            <h2 className="mb-5 font-medium">
              {t("settingsPage.routingInformation")}
            </h2>
            <div className="mb-5 flex flex-col gap-4">
              <Label>{t("settingsPage.category")}</Label>
              <Select
                defaultValue={activationType}
                onValueChange={(value) => {
                  setRedirectionValue("");
                  setActivationType(value);
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="EMAIL">
                    {t("settingsPage.email")}
                  </SelectItem>
                  <SelectItem value="LINK">{t("settingsPage.link")}</SelectItem>
                  <SelectItem value="PHONE">
                    {t("settingsPage.phone")}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            {activationType === "LINK" && (
              <div className="flex flex-col gap-4">
                <Label>{t("settingsPage.referralLink")}</Label>
                <Input
                  value={redirectionValue}
                  onChange={(e) => setRedirectionValue(e.target.value)}
                />
              </div>
            )}
            {activationType === "PHONE" && (
              <div className="flex flex-col gap-4">
                <Label>{t("settingsPage.phoneNumber")}</Label>
                <div className="flex gap-4">
                  <Select
                    onValueChange={(e) => setCountryCode(e)}
                    defaultValue="TR"
                  >
                    <SelectTrigger className="w-[150px]">
                      <SelectValue placeholder="Ülke" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="TR">+90</SelectItem>
                      <SelectItem value="UK">+44</SelectItem>
                    </SelectContent>
                  </Select>
                  <Input
                    value={redirectionValue}
                    onChange={(e) => setRedirectionValue(e.target.value)}
                  />
                </div>
              </div>
            )}
            {activationType === "EMAIL" && (
              <div className="flex flex-col gap-4">
                <Label>{t("settingsPage.emailAddress")}</Label>
                <Input
                  value={redirectionValue}
                  onChange={(e) => setRedirectionValue(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="flex w-full justify-end gap-4">
            {doesActivationExists && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button className="rounded-lg border border-[#E3E7EF] bg-red-600 px-4 py-2 text-gray-200 disabled:opacity-50">
                    {t("settingsPage.deleteActivation")}
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {t("settingsPage.warning")}
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      {t("settingsPage.deleteWarning")}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
                    <button
                      onClick={onDelete}
                      className="rounded-lg border border-[#E3E7EF] bg-red-600 px-4 py-2 text-gray-200 disabled:opacity-50"
                    >
                      {t("settingsPage.deleteActivation")}
                    </button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
            <Button onClick={onSubmit}>{t("common.save")}</Button>
          </div>
          {doesActivationExists && (
            <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
              <div className="formBox">
                <h2 className="mb-5 font-medium">
                  {t("settingsPage.selectedProducts")}
                </h2>
                <div className="border-b">
                  <Tabs
                    tabs={[
                      { id: 1, title: t("settingsPage.affiliatedProducts") },
                      { id: 2, title: t("settingsPage.addProduct") },
                    ]}
                    selectedTabId={selectProductActiveTab}
                    onTabChange={(id) => setSelectProductActiveTab(id)}
                  />
                </div>
                {selectProductActiveTab === 1 && <LinkedProducts />}
                {selectProductActiveTab === 2 && <AddProduct />}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="container mx-auto flex flex-col items-center justify-center gap-6">
          <div className="formBox">
            <h2 className="mb-5 font-medium">
              {t("serviceActivationPage.reasons")}
            </h2>
            <div className="flex max-w-lg flex-col gap-4">
              {claimOptions?.map((item, index) => (
                <div key={item.id} className="flex items-center gap-2">
                  <div className="flex w-full flex-col gap-2">
                    <Label>
                      {index + 1}. {t("serviceActivationPage.reason")}
                    </Label>
                    <Input
                      disabled
                      value={item.option}
                      key={item.id}
                      className="cursor-not-allowed"
                    />
                  </div>
                  <button
                    onClick={() => deleteItem(item.id)}
                    className="mt-4 rounded-lg px-4 py-2 text-red-500 disabled:opacity-50"
                  >
                    {t("common.delete")}
                  </button>
                </div>
              ))}
            </div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <button className="mt-4 flex items-center gap-2 text-gray-600 disabled:opacity-15">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_4364_35624)">
                      <path
                        d="M10.0003 6.6665V13.3332M6.66699 9.99984H13.3337M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z"
                        stroke="currentColor"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4364_35624">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {t("additionalServicesPage.addArticle")}
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {t("serviceActivationPage.addReason")}
                  </AlertDialogTitle>
                  <AlertDialogDescription></AlertDialogDescription>
                </AlertDialogHeader>
                <Input
                  value={newClaimOption}
                  onChange={(e) => setNewClaimOption(e.target.value)}
                />
                <AlertDialogFooter>
                  <AlertDialogCancel id="cancel">
                    {t("common.cancel")}
                  </AlertDialogCancel>
                  <Button
                    onClick={() => {
                      addNewItem(newClaimOption);
                      setNewClaimOption("");
                      document.getElementById("cancel").click();
                    }}
                  >
                    {t("common.save")}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>
      )}
    </main>
  );
};

export default ActivationDetails;
