import axios from "@/lib/axios";

class AdditionaPackagesService {
  constructor() {
    this.api = axios;
  }

  // /merchant-extra-warranty-plans
  async getExtraWarrantyPlans(query) {
    const data = await this.api.get("/merchant-extra-warranty-plans", {
      params: query,
    });

    return data;
  }

  async getExtraWarrantyPlan(id) {
    const data = await this.api.get(`/merchant-extra-warranty-plans/${id}`);

    return data;
  }

  async getMaintenancePlans(query) {
    const data = await this.api.get("/merchant-maintenance-plans", {
      params: query,
    });

    return data;
  }

  async getExtraWarrantyPackagesForPlan(merchantExtraWarrantyPlanId) {
    const data = await this.api.get(
      `/merchant-extra-warranty-packages?merchantExtraWarrantyPlanId=${merchantExtraWarrantyPlanId}`,
    );

    return data;
  }

  async getMaintenancePlan(id) {
    const data = await this.api.get(`/merchant-maintenance-plans/${id}`);

    return data;
  }

  async createExtraWarrantyPlan(data, formData) {
    const response = await this.api.post(
      "/merchant-extra-warranty-plans",
      formData,
      {
        params: {
          title: data.title,
          description: data.description,
          expiredTime: data.expiredTime,
          activated: true,
        },
      },
    );
    return response;
  }

  async createMaintenancePlan(data, formData) {
    const response = await this.api.post(
      "/merchant-maintenance-plans",
      formData,
      {
        params: {
          title: data.title,
          description: data.description,
          expiredTime: data.expiredTime,
          activated: true,
        },
      },
    );
    return response;
  }

  async updateExtraWarrantyPlan(id, data, formData) {
    const response = await this.api.put(
      `/merchant-extra-warranty-plans/${id}`,
      formData,
      {
        params: {
          title: data.title,
          description: data.description,
          expiredTime: data.expiredTime,
          activated: data.activated,
        },
      },
    );
    return response;
  }

  async unlinkProducts(documentId, data) {
    const response = await this.api.put(
      `/merchant-extra-warranty-plans/${documentId}/unlink-products`,
      data,
    );

    return response;
  }

  async unlinkProductsFilter(documentId, data) {
    const response = await this.api.put(
      `/merchant-extra-warranty-plans/${documentId}/unlink-products-filter`,
      data,
    );

    return response;
  }

  async linkProducts(documentId, data) {
    const response = await this.api.put(
      `/merchant-extra-warranty-plans/${documentId}/link-products`,
      data,
    );

    return response;
  }

  async linkProductsFilter(documentId, data) {
    const response = await this.api.put(
      `/merchant-extra-warranty-plans/${documentId}/link-products-filter`,
      data,
    );

    return response;
  }

  async getExtraWarrantyUnlinkedProducts(query) {
    const data = await this.api.get(
      "/merchant-extra-warranty-plans/unlinked-products",
      { params: query },
    );

    return data;
  }

  async getExtraWarrantyLinkedProducts(id, query) {
    const data = await this.api.get(
      `/merchant-extra-warranty-plans/${id}/linked-products`,
      { params: query },
    );

    return data;
  }

  async createExtraWarrantyPackage(merchantExtraWarrantyPlanId, data) {
    const response = await this.api.post(
      `/merchant-extra-warranty-packages?merchantExtraWarrantyPlanId=${merchantExtraWarrantyPlanId}`,
      data,
    );
    return response;
  }

  async updateExtraWarrantyPackage(id, data) {
    const response = await this.api.put(
      `/merchant-extra-warranty-packages/${id}`,
      data,
    );
    return response;
  }

  async deleteExtraWarrantyPlan(id) {
    const response = await this.api.delete(
      `/merchant-extra-warranty-plans/${id}`,
    );
    return response;
  }

  async deleteExtraWarrantyPackage(id) {
    const response = await this.api.delete(
      `/merchant-extra-warranty-packages/${id}`,
    );
    return response;
  }

  async getMaintenancePackagesForPlan(merchantMaintenancePlanId) {
    const data = await this.api.get(
      `/merchant-maintenance-packages?merchantMaintenancePlanId=${merchantMaintenancePlanId}`,
    );

    return data;
  }

  async createMaintenancePackage(merchantMaintenancePlanId, data) {
    const response = await this.api.post(
      `/merchant-maintenance-packages?merchantMaintenancePlanId=${merchantMaintenancePlanId}`,
      data,
    );
    return response;
  }

  async updateMaintenancePackage(merchantMaintenancePackageId, data) {
    const response = await this.api.put(
      `/merchant-maintenance-packages/${merchantMaintenancePackageId}`,
      data,
    );
    return response;
  }

  async getMaintenanceUnlinkedProducts(query) {
    const data = await this.api.get(
      "/merchant-maintenance-plans/unlinked-products",
      { params: query },
    );

    return data;
  }

  async getMaintenanceLinkedProducts(id, query) {
    const data = await this.api.get(
      `/merchant-maintenance-plans/${id}/linked-products`,
      { params: query },
    );

    return data;
  }

  async unlinkMaintenanceProducts(documentId, data) {
    const response = await this.api.put(
      `/merchant-maintenance-plans/${documentId}/unlink-products`,
      data,
    );

    return response;
  }

  async unlinkMaintenanceProductsFilter(documentId, data) {
    const response = await this.api.put(
      `/merchant-maintenance-plans/${documentId}/unlink-products-filter`,
      data,
    );

    return response;
  }

  async linkMaintenanceProducts(documentId, data) {
    const response = await this.api.put(
      `/merchant-maintenance-plans/${documentId}/link-products`,
      data,
    );

    return response;
  }

  async linkMaintenanceProductsFilter(documentId, data) {
    const response = await this.api.put(
      `/merchant-maintenance-plans/${documentId}/link-products-filter`,
      data,
    );

    return response;
  }

  async deleteMaintenancePlan(id) {
    const response = await this.api.delete(`/merchant-maintenance-plans/${id}`);
    return response;
  }

  async updateMaintenancePlan(id, data, formData) {
    const response = await this.api.put(
      `/merchant-maintenance-plans/${id}`,
      formData,
      {
        params: {
          title: data.title,
          description: data.description,
          expiredTime: data.expiredTime,
          activated: data.activated,
        },
      },
    );
    return response;
  }

  async deleteMaintenancePackage(id) {
    const response = await this.api.delete(
      `/merchant-maintenance-packages/${id}`,
    );
    return response;
  }
}

export default new AdditionaPackagesService();
