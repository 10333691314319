import userService from "@/services/userService";

import { create } from "zustand";

const useUserStore = create((set) => ({
  loading: false,
  merchant: {},
  user: {},
  getData: async () => {
    set({ loading: true });
    try {
      const [merchantData, userData] = await Promise.all([
        userService.getMerchantData(),
        userService.getMerchantUsersData(),
      ]);
      set({
        loading: false,
        merchant: merchantData,
        user: userData,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      set({ loading: false });
    }
  },
}));

export default useUserStore;
