import { Tag } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TrashIcon from "../../../../assets/icons/trash.svg?react";

import ProductService from "@/services/productService";

import useDocumentsStore from "@/stores/useDocumentsStore";

const FilterTags = () => {
  const { id } = useParams();

  const {
    documentsProducts,
    getDocumentUnlinkedProducts,
    setUnlinkedProductsFilter,
  } = useDocumentsStore();

  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [brand, setBrand] = useState("");

  const getMainCategory = async () => {
    const { data } = await ProductService.getMainCategories();
    const mainCategory = data.find(
      (mainCategory) =>
        mainCategory.id ==
        documentsProducts?.unlinkedProducts?.filter?.mainCategoryId,
    );
    setMainCategory(mainCategory);
  };

  const getCategory = async () => {
    const { data } = await ProductService.getCategories(
      documentsProducts?.unlinkedProducts?.filter?.mainCategoryId,
    );
    const category = data.find(
      (category) =>
        category.id == documentsProducts?.unlinkedProducts?.filter?.categoryId,
    );
    setCategory(category);
  };

  const getSubCategory = async () => {
    const { data } = await ProductService.getSubCategories(
      documentsProducts?.unlinkedProducts?.filter?.categoryId,
    );
    const subCategory = data.find(
      (subCategory) =>
        subCategory.id ==
        documentsProducts?.unlinkedProducts?.filter?.subCategoryId,
    );
    setSubCategory(subCategory);
  };

  const getBrand = async () => {
    const { data } = await ProductService.getSubCategoryBrands(
      documentsProducts?.unlinkedProducts?.filter?.subCategoryId,
    );
    const brand = data.find(
      (brand) =>
        brand.id ==
        documentsProducts?.unlinkedProducts?.filter?.subCategoryBrandId,
    );
    setBrand(brand);
  };

  useEffect(() => {
    documentsProducts?.unlinkedProducts?.filter?.mainCategoryId &&
      getMainCategory();
    documentsProducts?.unlinkedProducts?.filter?.categoryId && getCategory();
    documentsProducts?.unlinkedProducts?.filter?.subCategoryId &&
      getSubCategory();
    documentsProducts?.unlinkedProducts?.filter?.subCategoryBrandId &&
      getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsProducts?.unlinkedProducts?.filter]);

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {documentsProducts?.unlinkedProducts?.filter?.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {documentsProducts?.unlinkedProducts?.filter?.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setUnlinkedProductsFilter({
                ...documentsProducts?.unlinkedProducts?.filter,
                name: "",
              });
              getDocumentUnlinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {documentsProducts?.unlinkedProducts?.filter?.mainCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {mainCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setUnlinkedProductsFilter({
                ...documentsProducts?.unlinkedProducts?.filter,
                mainCategoryId: "",
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getDocumentUnlinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {documentsProducts?.unlinkedProducts?.filter?.categoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {category.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setUnlinkedProductsFilter({
                ...documentsProducts?.unlinkedProducts?.filter,
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getDocumentUnlinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {documentsProducts?.unlinkedProducts?.filter?.subCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {subCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setUnlinkedProductsFilter({
                ...documentsProducts?.unlinkedProducts?.filter,
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getDocumentUnlinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {documentsProducts?.unlinkedProducts?.filter?.subCategoryBrandId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {brand.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setUnlinkedProductsFilter({
                ...documentsProducts?.unlinkedProducts?.filter,
                subCategoryBrandId: "",
              });
              getDocumentUnlinkedProducts(id);
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
