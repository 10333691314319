/* eslint-disable react/prop-types */
import { useToast } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreditCard from "./creditCard";

import paymentService from "@/services/paymentService";

import EmptyTable from "@/components/shared/EmptyTable";

const CardsContainer = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [creditCards, setCreditCards] = useState([]);

  useEffect(() => {
    setLoading(true);
    paymentService
      .getStoredCards()
      .then((data) => {
        setCreditCards(data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardDelete = (id) => {
    paymentService.deleteStoredCard(id).then(() => {
      paymentService.getStoredCards().then((data) => {
        setCreditCards(data);
      });
      toast({
        title: t("payment.myCards.deleteCardDialog.toastMsg"),
        variant: "success",
      });
    });
  };

  if (loading) {
    return (
      <div className="flex min-h-[150px] w-full items-center justify-center">
        {t("payment.myCards.loading")}
      </div>
    );
  }

  return (
    <>
      {creditCards.length ? (
        <div className="flex flex-col gap-6 px-6 py-8">
          <span className="text-sm font-medium text-[#677389]">
            {t("payment.myCards.title")}
          </span>
          <div className="grid grid-cols-2 gap-8">
            {creditCards.map((item, index) => (
              <CreditCard key={index} onCardDelete={onCardDelete} item={item} />
            ))}
          </div>
        </div>
      ) : (
        <EmptyTable
          title={t("payment.myCards.emptyTable.noCardTitle")}
          desc={t("payment.myCards.emptyTable.noCardSubtitle")}
        />
      )}
    </>
  );
};

export default CardsContainer;
