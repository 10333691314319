/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AttentionIcon from "../../assets/icons/attention.svg?react";
import FilterIcon from "../../assets/icons/filter.svg?react";

import SellerService from "@/services/sellerService";

import useSellerStore from "@/stores/useSellerStore";

import FilterSheet from "@/components/sellers/waiting/filterSheet";
import FilterTags from "@/components/sellers/waiting/filterTags";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";
import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const WaitingSellers = () => {
  const { t } = useTranslation();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const { toast } = useToast();

  const {
    loading,
    getSellersWaiting,
    sellersWaiting,
    sellersWaitingFilters,
    setSellersWaitingFilters,
  } = useSellerStore();

  useEffect(() => {
    getSellersWaiting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    setSellersWaitingFilters({ ...sellersWaitingFilters, size, page: 1 });
    getSellersWaiting();
  };

  const updateWaitingSeller = (id, body) => {
    SellerService.updateWaitingSeller(id, body)
      .then(() => {
        getSellersWaiting();
        toast({
          title:
            body.status === "APPROVED"
              ? t("sellers.dialog.approveToaster")
              : body.status === "REJECTED" && t("sellers.dialog.rejectToaster"),
          variant:
            body.status === "APPROVED"
              ? "success"
              : body.status === "REJECTED" && "error",
        });
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const onPageChange = (page) => {
    setSellersWaitingFilters({
      ...sellersWaitingFilters,
      page,
    });
    getSellersWaiting();
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/sellers">Satıcılar</BreadcrumbItem>
        <BreadcrumbItem active>Bekleyen Satıcılar</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto mt-4 bg-white">
        <TableHeaderWrapper className="flex items-center justify-end gap-5">
          <FilterTags />
          <Button
            onClick={() => setOpenFilterSheet(true)}
            variant="secondaryGray"
            className="min-w-[150px] font-medium"
          >
            <FilterIcon />
            <span>{t("common.addFilter")}</span>
          </Button>
        </TableHeaderWrapper>
        {loading ? (
          <Table>
            <TableSkeleton colSpan={4} />
          </Table>
        ) : sellersWaiting?.content?.length ? (
          <Table>
            <TableHeader>
              <TableHead>{t("sellers.title")}</TableHead>
              <TableHead>{t("sellers.phone")}</TableHead>
              <TableHead>{t("sellers.email")}</TableHead>
              <TableHead>{t("sellers.actions")}</TableHead>
            </TableHeader>
            <TableBody>
              {sellersWaiting?.content?.map((seller, index) => (
                <TableRow key={index}>
                  <TableCell>{seller.title}</TableCell>
                  <TableCell>{seller.phone}</TableCell>
                  <TableCell>{seller.email}</TableCell>
                  <TableCell>
                    <span className="flex gap-4">
                      <ConfirmDialog
                        onClick={() =>
                          updateWaitingSeller(seller.id, { status: "APPROVED" })
                        }
                      />
                      <RejectDialog
                        onClick={() =>
                          updateWaitingSeller(seller.id, { status: "REJECTED" })
                        }
                      />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyTable
            title={t("sellers.emptyTableNoData")}
            desc={t("sellers.emptyTableNoDataDesc")}
          />
        )}
      </div>
      <Pagination
        totalPages={sellersWaiting.totalPages}
        currentPage={sellersWaitingFilters.page}
        pageSize={sellersWaiting.pageSize}
        onPageChange={onPageChange}
      />
      <div className="mt-4 flex items-center justify-center">
        <PageSizeSelect
          onPageSizeChange={onPageSizeChange}
          value={sellersWaitingFilters.size}
        />
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </main>
  );
};

export default WaitingSellers;

const ConfirmDialog = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <span className="cursor-pointer text-sm font-medium text-[#0049E6]">
          {t("sellers.confirm")}
        </span>
      </AlertDialogTrigger>
      <AlertDialogContent className="w-[400px]">
        <div className="flex flex-col items-center justify-center">
          <AttentionIcon />
          <span className="mb-1 mt-4 text-center text-lg font-semibold text-[#111729]">
            {t("sellers.dialog.confirmTitle")}
          </span>
          <span className="text-center text-base font-medium text-[#677389]">
            {t("sellers.dialog.confirmTitle")}
          </span>
        </div>
        <AlertDialogFooter className="mt-0 flex items-center justify-between">
          <AlertDialogCancel className="flex-1">
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={onClick} className="flex-1">
            {t("common.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const RejectDialog = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <span className="cursor-pointer text-sm font-medium">
          {t("sellers.reject")}
        </span>
      </AlertDialogTrigger>
      <AlertDialogContent className="w-[400px]">
        <div className="flex flex-col items-center justify-center">
          <AttentionIcon />
          <span className="mb-1 mt-4 text-center text-lg font-semibold text-[#111729]">
            {t("sellers.dialog.rejectTitle")}
          </span>
          <span className="text-center text-base font-medium text-[#677389]">
            {t("sellers.dialog.rejectDesc")}
          </span>
        </div>
        <AlertDialogFooter className="mt-0 flex items-center justify-between">
          <AlertDialogCancel className="flex-1">
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onClick}
            className="flex-1 bg-[#D92D20] hover:bg-[#ab2319]"
          >
            {t("common.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
