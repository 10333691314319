import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Form,
  ScrollArea,
  Tag,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import productService from "@/services/productService";

import AddNewDocumentForm from "@/components/products/edit/addNewDocumentForm";
import ExistingDocumentForm from "@/components/products/edit/existingDocumentForm";

import * as z from "zod";

import { cn } from "@/lib/utils";

const AddNewDocument = ({ children, type, reFetchDocuments }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(null);
  const { id: productId } = useParams();
  const { toast } = useToast();

  const createSchema = () => {
    if (model === "new") {
      return z.object({
        documentName: z.string().min(1, { message: "Belge adı zorunludur" }),
        documentFile: z.instanceof(File, {
          message: "Belge dosyası zorunludur",
        }),
      });
    } else if (model === "existing") {
      return z.object({
        selectedDocument: z
          .string()
          .min(1, { message: "Belge seçimi zorunludur" }),
      });
    }
    return z.object({}); // Varsayılan boş şema
  };

  const form = useForm({
    resolver: zodResolver(createSchema()),
    defaultValues: {
      selectedDocument: "",
      documentName: "",
      documentFile: new File([], ""),
    },
  });

  const { control, handleSubmit, formState, setValue, trigger } = form;
  const { isValid, isSubmitting } = formState;

  useEffect(() => {
    form.reset({
      ...form.defaultValues,
    });
  }, [model]);

  const onSubmit = async (data) => {
    try {
      if (model === "new") {
        const formData = new FormData();
        formData.append("file", data.documentFile);

        await productService.uploadDocument(
          productId,
          {
            name: data.documentName,
            type: type,
          },
          formData,
        );
      } else if (model === "existing") {
        await productService.selectDocument(productId, data.selectedDocument);
      }
      toast({
        variant: "success",
        description: t("settingsPage.documentAdded"),
      });
      reFetchDocuments();
    } catch (error) {
      console.log(error);
      toast({
        title: error.response?.data?.errors[0]?.message,
        variant: "error",
      });
    } finally {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="p-2">{children}</DialogTrigger>
      <DialogContent className="max-w-fit bg-[#F9FAFB] pb-0">
        <DialogTitle>{t("settingsPage.addDocument")}</DialogTitle>
        <ScrollArea className="border-b">
          <div className="flex flex-col items-center gap-4 py-10 pt-0">
            <div className="flex w-full">
              <Tag size="sm">
                {type === "WARRANTY_DOCUMENT"
                  ? t("productsPage.warrantyDocument")
                  : t("productsPage.userManual")}
              </Tag>
            </div>
            <DocumentButtons model={model} setModel={setModel} />
            <Form {...form}>
              <form className="mt-4 flex w-full flex-col gap-4 p-1">
                {model === "new" && <AddNewDocumentForm control={control} />}
                {model === "existing" && (
                  <ExistingDocumentForm
                    type={type}
                    trigger={trigger}
                    control={control}
                    setValue={setValue}
                  />
                )}
              </form>
            </Form>
          </div>
        </ScrollArea>
        <div className="flex w-full justify-center gap-4 pb-5">
          <DialogClose asChild>
            <Button variant="secondaryGray" size="full">
              {t("common.cancel")}
            </Button>
          </DialogClose>
          <Button
            disabled={!isValid || isSubmitting || !model}
            size="full"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.save")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewDocument;

const DocumentButtons = ({ model, setModel }) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col justify-between gap-2 lg:flex-row lg:gap-6">
      <button
        onClick={() => {
          setModel("existing");
        }}
        className={cn(
          "flex w-full items-center gap-2 rounded-lg border border-[#CDD5E0] bg-white p-4 font-medium text-[#364153] lg:whitespace-nowrap lg:p-6",
          model === "existing" && "border-[#447AED]",
        )}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 18.5L14.5 17M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2ZM15.5 14.5C15.5 16.433 13.933 18 12 18C10.067 18 8.5 16.433 8.5 14.5C8.5 12.567 10.067 11 12 11C13.933 11 15.5 12.567 15.5 14.5Z"
            stroke="#667085"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        {t("productsPage.existingDocument")}
      </button>
      <button
        onClick={() => {
          setModel("new");
        }}
        className={cn(
          "flex w-full items-center gap-2 rounded-lg border border-[#CDD5E0] bg-white p-4 font-medium text-[#364153] lg:whitespace-nowrap lg:p-6",
          model === "new" && "border-[#447AED]",
        )}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M12 18V12M9 15H15M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
            stroke="#667085"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {t("productsPage.createNewDocument")}
      </button>
    </div>
  );
};
