import { Tag } from "@egaranti/components";

import { useEffect, useState } from "react";

import TrashIcon from "../../../../../../assets/icons/trash.svg?react";

import ProductService from "@/services/productService";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

const FilterTags = () => {
  const {
    maintenanceUnlinkedFilters,
    setMaintenanceUnlinkedFilters,
    getMaintenanceUnlinkedProducts,
  } = useAdditionalPackagesStore();

  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [brand, setBrand] = useState("");

  const getMainCategory = async () => {
    const { data } = await ProductService.getMainCategories();
    const mainCategory = data.find(
      (mainCategory) =>
        mainCategory.id == maintenanceUnlinkedFilters.mainCategoryId,
    );
    setMainCategory(mainCategory);
  };

  const getCategory = async () => {
    const { data } = await ProductService.getCategories(
      maintenanceUnlinkedFilters.mainCategoryId,
    );
    const category = data.find(
      (category) => category.id == maintenanceUnlinkedFilters.categoryId,
    );
    setCategory(category);
  };

  const getSubCategory = async () => {
    const { data } = await ProductService.getSubCategories(
      maintenanceUnlinkedFilters.categoryId,
    );
    const subCategory = data.find(
      (subCategory) =>
        subCategory.id == maintenanceUnlinkedFilters.subCategoryId,
    );
    setSubCategory(subCategory);
  };

  const getBrand = async () => {
    const { data } = await ProductService.getSubCategoryBrands(
      maintenanceUnlinkedFilters.subCategoryId,
    );
    const brand = data.find(
      (brand) => brand.id == maintenanceUnlinkedFilters.subCategoryBrandId,
    );
    setBrand(brand);
  };

  useEffect(() => {
    maintenanceUnlinkedFilters.mainCategoryId && getMainCategory();
    maintenanceUnlinkedFilters.categoryId && getCategory();
    maintenanceUnlinkedFilters.subCategoryId && getSubCategory();
    maintenanceUnlinkedFilters.subCategoryBrandId && getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceUnlinkedFilters]);

  // categoryId: "4";
  // mainCategoryId: "1";
  // name: "qwe";
  // page: 1;
  // size : localStorage.getItem("pageSize") || 10;
  // subCategoryBrandId: 723;
  // subCategoryId: "18";
  // title: "";

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {maintenanceUnlinkedFilters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {maintenanceUnlinkedFilters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceUnlinkedFilters({
                ...maintenanceUnlinkedFilters,
                name: "",
              });
              getMaintenanceUnlinkedProducts();
            }}
          />
        </Tag>
      )}
      {maintenanceUnlinkedFilters.mainCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {mainCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceUnlinkedFilters({
                ...maintenanceUnlinkedFilters,
                mainCategoryId: "",
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getMaintenanceUnlinkedProducts();
            }}
          />
        </Tag>
      )}
      {maintenanceUnlinkedFilters.categoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {category.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceUnlinkedFilters({
                ...maintenanceUnlinkedFilters,
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getMaintenanceUnlinkedProducts();
            }}
          />
        </Tag>
      )}
      {maintenanceUnlinkedFilters.subCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {subCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceUnlinkedFilters({
                ...maintenanceUnlinkedFilters,
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getMaintenanceUnlinkedProducts();
            }}
          />
        </Tag>
      )}
      {maintenanceUnlinkedFilters.subCategoryBrandId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {brand.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceUnlinkedFilters({
                ...maintenanceUnlinkedFilters,
                subCategoryBrandId: "",
              });
              getMaintenanceUnlinkedProducts();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
