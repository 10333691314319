import statsService from "@/services/statsService";

import { create } from "zustand";

const useInboxStore = create((set) => ({
  loading: false,
  list: [],
  unReadCount: 0,
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    interval: 1,
  },

  // Function to fetch the inbox list
  getInboxList: async () => {
    set({ loading: true });
    const { filters } = useInboxStore.getState();
    try {
      const response = await statsService.getInboxList(filters);
      set({
        list: response.data.content,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching inbox list:", error);
      set({ loading: false });
    }
  },

  getUnreadInboxCount: async () => {
    try {
      const response = await statsService.getUnreadInboxCount();
      set({ unReadCount: response.data });
    } catch (error) {
      console.error("Error fetching unread inbox count:", error);
    }
  },

  markAsRead: async (id) => {
    try {
      await statsService.markAsRead(id);
      useInboxStore.getState().getUnreadInboxCount();
      useInboxStore.getState().getInboxList();
    } catch (error) {
      console.error("Error marking inbox as read:", error);
    }
  },

  setFilters: (newFilters) => {
    set((state) => ({
      filters: { ...state.filters, ...newFilters },
    }));
    useInboxStore.getState().getInboxList(); // Refetch the list with updated filters
  },
}));

export default useInboxStore;
