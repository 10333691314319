/* eslint-disable react/prop-types */
import {
  Button,
  Label,
  RadioGroup,
  RadioGroupItem,
  useToast,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

//import { zodResolver } from "@hookform/resolvers/zod";
import SuccessPage from "./successPage";

import addressService from "@/services/addressService";
import paymentService from "@/services/paymentService";

import AddAddressDialog from "@/components/settings/payment/addAddressDialog";
import CheckoutTable from "@/components/settings/payment/checkoutTable";
import CreditCardForm from "@/components/settings/payment/creditCardForm";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import AmericanExpressIcon from "@/assets/icons/americanExpress.svg?react";
import BluePlusIcon from "@/assets/icons/blue-plus.svg?react";
import DinersClubIcon from "@/assets/icons/dinersClub.svg?react";
import MasterCardIcon from "@/assets/icons/masterCard.svg?react";
import TroyIcon from "@/assets/icons/troy.svg?react";
import VisaIcon from "@/assets/icons/visa.svg?react";

import { cn } from "@/lib/utils";

export const Checkout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const location = useLocation();
  const selectedPackage = location.state?.selectedPackage;
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("selectCard"); // form, selectCard
  const [storedCards, setStoredCards] = useState([]);
  const [address, setAddress] = useState([]);
  const [onOpenAddressDialog, setOpenAddressDialog] = useState(false);
  const [htmlContent, setHtmlContent] = useState(null);

  const form = useForm({
    defaultValues: {
      cardHolderName: "",
      cardNumber: "4256690000000001",
      expireDate: "11/25",
      cardAlias: "asdasd",
      addressId: "1",
      storeCardAfterSuccessPayment: false,
      selectedStoredCard: null,
    },
  });

  const onSubmit = (data) => {
    function formatName(name) {
      const normalizedName = name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      let formattedName = normalizedName.toUpperCase();

      formattedName = formattedName.replace(/[.]/g, "");

      return formattedName;
    }
    const payload = {
      currency: "TRY",
      isThreeDS: true,
      addressId: data.addressId,
      card: {
        cardUserKey: data.selectedStoredCard?.cardUserKey,
        cardToken: data.selectedStoredCard?.cardToken,
        cardAlias: formatName(data.cardHolderName),
        cardHolderName: formatName(data.cardHolderName),
        cardNumber: data.cardNumber.replace(/\s/g, ""),
        expireYear: data.expireDate.split("/")[1],
        expireMonth: data.expireDate.split("/")[0],
        cvc: data.cvc,
        storeCardAfterSuccessPayment: data.storeCardAfterSuccessPayment,
      },
      elements: [
        {
          name: "package-" + selectedPackage.id + "-quantity",
          price: selectedPackage.priceUnit * selectedPackage.quantity,
          quantity: selectedPackage.quantity,
        },
      ],
    };

    paymentService
      .postCheckout(payload)
      .then((res) => {
        if (res.data.htmlContent) {
          setHtmlContent(window.document.write(atob(res.data.htmlContent)));
        } else {
          setSearchParams({
            status: "success",
            selectedPackagePrice:
              selectedPackage.priceUnit * selectedPackage.quantity,
          });
        }
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const getAllAddresses = () =>
    addressService.getAddresses().then((res) => setAddress(res.data));

  useEffect(() => {
    setTimeout(() => getAllAddresses(), 1000);
    paymentService.getStoredCards().then((res) => {
      setActiveTab(res.length > 0 ? "selectCard" : "form");
      setStoredCards(res);
    });
  }, [onOpenAddressDialog]);

  const onChangeTab = () => {
    setActiveTab(activeTab === "form" ? "selectCard" : "form");

    form.setValue("selectedStoredCard", null);
    form.setValue("cardHolderName", "");
    form.setValue("cardNumber", "");
    form.setValue("expireDate", "");
    form.setValue("cvc", "");
  };

  if (searchParams.get("status") === "success") {
    return <SuccessPage selectedPackage={selectedPackage} />;
  }

  if (!selectedPackage) {
    toast({
      title: t("payment.checkout.toastErr"),
      variant: "error",
    });
    setTimeout(() => {
      navigate("/settings/payment");
    }, 1500);
  }

  return (
    <div className="flex w-full flex-col overflow-hidden bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem link="/settings/payment">
          {t("payment.checkout.breadcrumbs.text1")}
        </BreadcrumbItem>
        <BreadcrumbItem active link="/settings/payment">
          {t("payment.checkout.breadcrumbs.text2")}
        </BreadcrumbItem>
      </Breadcrumb>
      <main className="flex min-h-screen flex-col gap-6 py-16">
        <div className="formBox mx-auto flex max-w-3xl flex-col gap-8">
          {selectedPackage.id !== "monthly" && (
            <CheckoutTable selectedPackage={selectedPackage} />
          )}
          <div className="mb-2 flex w-full flex-col gap-12">
            <div className="flex w-full flex-col gap-6">
              <h2 className="text-xl font-semibold text-[#364153]">
                {t("payment.checkout.addressTitle")}
              </h2>
              <div className="flex flex-col">
                <Label>{t("payment.checkout.addressSubtitle")}</Label>
                <RadioGroup
                  onValueChange={(e) => form.setValue("addressId", e)}
                  value={form.watch("addressId")}
                  className="mt-4 rounded-lg shadow"
                >
                  {address.map((item) => (
                    <div
                      key={item.id}
                      className="flex items-center space-x-3 border-b border-b-[#F2F5F9] px-4"
                    >
                      <RadioGroupItem value={item.id} id={item.id} />
                      <Label
                        className="w-full cursor-pointer py-3 text-sm font-medium text-[#344054]"
                        htmlFor={item.id}
                      >
                        {item.title}
                      </Label>
                    </div>
                  ))}
                  <button
                    onClick={() => setOpenAddressDialog(true)}
                    className="flex w-full items-center gap-2 rounded-lg rounded-t-none px-4 py-3 text-sm font-medium text-[#0049E6]"
                  >
                    <BluePlusIcon />
                    {t("payment.checkout.addAddressBtn")}
                  </button>
                </RadioGroup>
              </div>
            </div>
            <div className="flex w-full items-center justify-between">
              <h2 className="text-xl font-semibold text-[#364153]">
                {t("payment.checkout.paymentOptionsTitle")}
              </h2>
              {storedCards.length > 0 && (
                <button
                  onClick={onChangeTab}
                  className="font-medium text-[#364153] underline hover:text-gray-500 hover:decoration-gray-500"
                >
                  {activeTab === "form"
                    ? t("payment.checkout.payWithSavedCard")
                    : t("payment.checkout.payWithDiffrentCard")}
                </button>
              )}
            </div>
            <p className="text-sm font-medium text-[#677389]">
              {t("payment.checkout.paymentOptionsSubtitle")}
            </p>
          </div>
          {activeTab === "form" ? (
            <CreditCardForm
              form={form}
              onSubmit={onSubmit}
              actionText="Satın Al"
            />
          ) : (
            <SelectStoredCardContainer
              storedCards={storedCards}
              setStoredCards={setStoredCards}
              form={form}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </main>
      <AddAddressDialog
        open={onOpenAddressDialog}
        onOpenChange={setOpenAddressDialog}
      />
    </div>
  );
};

const SelectStoredCardContainer = ({ storedCards, form, onSubmit }) => {
  const { t } = useTranslation();

  const onSelected = (item) => {
    form.setValue("selectedStoredCard", item);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-6">
        {storedCards.map((item) => (
          <CreditCard
            selectedStoredCard={form.watch("selectedStoredCard")}
            onSelected={onSelected}
            key={item.id}
            item={item}
          />
        ))}
      </div>
      <Button
        disabled={!form.watch("selectedStoredCard")}
        className="mt-4"
        onClick={() => form.handleSubmit(onSubmit)()}
      >
        {t("payment.checkout.buyBtn")}
      </Button>
    </>
  );
};

const CreditCard = ({ item, onSelected, selectedStoredCard }) => {
  const icon = {
    MASTER_CARD: <MasterCardIcon className="h-8 w-8" />,
    VISA: <VisaIcon className="h-8 w-8" />,
    AMEX: <AmericanExpressIcon className="h-8 w-8" />,
    TROY: <TroyIcon className="h-8 w-8" />,
    DINERS: <DinersClubIcon className="h-8 w-8" />,
  };

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={() => onSelected(item)}
      className={cn(
        "flex w-full flex-col items-center justify-center gap-10 rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] p-4 shadow-md",
        selectedStoredCard?.id === item.id && "border-blue-500",
      )}
      aria-label="Kartı Seç"
      aria-pressed={selectedStoredCard?.id === item.id}
    >
      <div className="flex w-full justify-between">
        <span className="text-lg font-medium text-[#0040C9]">
          {item.cardAlias}
        </span>
        {icon[item.cardAssociation]}
      </div>
      <div className="flex w-full justify-between">
        <span className="mt-2 text-sm font-medium leading-6 text-[#111729]">
          **** **** **** {item.lastFourDigits}
        </span>
        <></>
      </div>
    </div>
  );
};
