/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const SuccessPage = ({ selectedPackage }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedPackagePrice = searchParams.get("selectedPackagePrice");

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center gap-[30px] bg-gray-50">
      <div className="mx-auto flex max-w-lg flex-col items-center gap-2">
        <h1 className="text-2xl font-semibold text-[#111729]">
          {t("payment.statusPage.title")}
        </h1>
        <p className="text-center font-medium text-[#7D7D7D]">
          {t("payment.statusPage.subtitle")}
        </p>
      </div>
      <div className="formBox mx-auto flex max-w-lg flex-col items-center gap-5 bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="121"
          fill="none"
          className="mb-5"
          viewBox="0 0 120 121"
        >
          <rect
            width="120"
            height="120"
            y="0.275"
            fill="#D1FADF"
            rx="60"
          ></rect>
          <path
            fill="#12B76A"
            fillRule="evenodd"
            d="M85.482 37.225l-35.8 34.55-9.5-10.15c-1.75-1.65-4.5-1.75-6.5-.35-1.95 1.45-2.5 4-1.3 6.05l11.25 18.3c1.1 1.7 3 2.75 5.15 2.75 2.05 0 4-1.05 5.1-2.75 1.8-2.35 36.15-43.3 36.15-43.3 4.5-4.6-.95-8.65-4.55-5.15v.05z"
            clipRule="evenodd"
          ></path>
        </svg>
        <h2 className="font-semibold text-[#020246]">
          {t("payment.statusPage.cardTitle")}
        </h2>
        <p className="text-center text-[#677389]">
          {t("payment.statusPage.cardDesc")}
        </p>
        <hr />
        <h3 className="w-full text-left font-medium text-black">
          {t("payment.statusPage.summary")}
        </h3>
        <div className="flex w-full items-center justify-between gap-5 rounded-lg border bg-[#F9FAFC] p-10">
          <span className="font-medium text-[#364153]">
            {t("payment.statusPage.package")}
          </span>
          <span className="text-lg font-semibold text-[#364153]">
            {selectedPackagePrice} ₺
          </span>
        </div>
        <Button className="w-full" asChild>
          <Link to="/settings/payment">{t("payment.statusPage.btnText")}</Link>
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
