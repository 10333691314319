import { Tag } from "@egaranti/components";

import TrashIcon from "../../../assets/icons/trash.svg?react";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

const FilterTags = () => {
  const { filters, getExtraWarrantyPlans, setFilters } =
    useAdditionalPackagesStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.title && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.title}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({
                ...filters,
                title: "",
              });
              getExtraWarrantyPlans();
            }}
          />
        </Tag>
      )}
      {(filters.activated === "true" || filters.activated === "false") && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.activated === "true"
            ? "activated"
            : filters.activated === "false" && "Not activated"}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({
                ...filters,
                activated: null,
              });
              getExtraWarrantyPlans();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
