import { Tag } from "@egaranti/components";

import TrashIcon from "../../assets/icons/trash.svg?react";

import useCorporateCustomerStore from "@/stores/useCorporateCustomersStore";

const FilterTags = () => {
  const { filters, setFilters, getCorporateCustomers } =
    useCorporateCustomerStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, name: "" });
              getCorporateCustomers();
            }}
          />
        </Tag>
      )}
      {filters.email && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.email}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, email: "" });
              getCorporateCustomers();
            }}
          />
        </Tag>
      )}
      {filters.vkn && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.vkn}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, vkn: "" });
              getCorporateCustomers();
            }}
          />
        </Tag>
      )}
      {filters.phone && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.phone}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, phone: "" });
              getCorporateCustomers();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
