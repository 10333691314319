import { useTranslation } from "react-i18next";

import ChatIcon from "@/assets/icons/chat.svg?react";
import CreditCardIcon from "@/assets/icons/creditCard.svg?react";
import RecycleIcon from "@/assets/icons/recycle.svg?react";

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto mb-[90px] mt-16 flex w-[800px] items-center justify-between py-6">
      <div className="flex flex-col items-center justify-center gap-4">
        <CreditCardIcon />
        <span className="text-sm font-medium text-[#364153]">
          {t("payment.testimonials.text1")}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center gap-4">
        <RecycleIcon />
        <span className="text-sm font-medium text-[#364153]">
          {t("payment.testimonials.text2")}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center gap-4">
        <ChatIcon />
        <span className="text-sm font-medium text-[#364153]">
          {t("payment.testimonials.text3")}
        </span>
      </div>
    </div>
  );
};

export default Testimonials;
