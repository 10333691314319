import setupClaimsService from "@/services/setupClaimsService";

import { create } from "zustand";

const useSetupClaimsStore = create((set) => ({
  loading: false,
  claims: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    totalPages: 0,
    individualCustomerProductName: "",
    individualCustomerFirstName: "",
    individualCustomerLastName: "",
    serviceClaimStatus: "",
    claimNo: null,
  },
  getClaims: async () => {
    set({ loading: true });
    const data = await setupClaimsService.getClaims(
      useSetupClaimsStore.getState().filters,
    );
    set({
      claims: data.content,
      loading: false,
      filters: {
        ...useSetupClaimsStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useSetupClaimsStore;
