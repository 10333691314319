import axios from "@/lib/axios";

class StatsService {
  constructor() {
    this.api = axios;
  }
  async getCredits() {
    const response = this.api.get("/merchants/credits");

    return response;
  }

  async getGeneralStats() {
    const endpoints = [
      "/dashboard/merchant-products/count",
      "/merchants/credits", // delete this
      "/dashboard/frequently-merchant-products",
      "/dashboard/customers/count",
      "/dashboard/claim/count",
      "/merchants/credits",
      "/dashboard/individual-customer-products/integration/count",
    ];

    const requests = endpoints.map((endpoint) => this.api.get(endpoint));

    const responses = await Promise.all(requests);

    return responses.map((response) => response.data);
  }

  async getSendedWarranties(data) {
    const response = this.api.get(
      `/dashboard/merchant-products/count-by-specific-time?interval=${data.interval}&temporalUnitEnum=${data.temporalUnitEnum}`,
    );

    return response;
  }

  async getUsageRate(interval) {
    const response = this.api.get(
      `/dashboard/merchant-products/usage-rate?interval=${interval}`,
    );

    return response;
  }

  async getUnSuccessWarranties(data) {
    const response = this.api.get(`/merchant-inboxes`, {
      params: { ...data, type: "WARRANTY_ERROR" },
    });

    return response;
  }
  async getInboxList(data) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    const response = this.api.get(`/merchant-inboxes`, {
      params: data,
    });

    return response;
  }

  async getUnreadInboxCount() {
    const response = this.api.get(`/merchant-inboxes/unread`);

    return response;
  }

  async markAsRead(id) {
    // body: [id]
    const response = this.api.put(`/merchant-inboxes/read`, []);

    return response;
  }

  async exportWarranties(data) {
    const response = this.api.get(
      `/dashboard/individual-customer-products/export/count-by-specific-time?interval=${data.interval}&temporalUnitEnum=${data.temporalUnitEnum}`,
      {
        responseType: "blob",
      },
    );

    return response;
  }
}

export default new StatsService();
