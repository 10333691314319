/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import individualCustomersService from "@/services/individualCustomersService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";
import EmptyTable from "@/components/shared/EmptyTable";

import TrashIcon from "@/assets/icons/trash.svg?react";

const CustomerEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    individualCustomersService.getIndividualCustomer(id).then((res) => {
      setData(res);
    });

    individualCustomersService
      .getIndividualCustomerProducts(id, page)
      .then((res) => {
        setProducts(res.content);
        setPage(res.page);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDelete = (productId) => {
    individualCustomersService
      .deleteIndividualCustomerProduct(id, productId)
      .then(() => {
        toast({
          title: "Ürün silindi",
          variant: "success",
        });
      })
      .then(() => {
        individualCustomersService
          .getIndividualCustomerProducts(id, page)
          .then((res) => {
            setProducts(res.content);
            setPage(res.page);
          });
      });
  };

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getIndividuaCustomers();
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/customers/individual">
          {t("clientsPage.customerIndividual")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("clientsPage.editCustomer")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("clientsPage.customerInfo")}
          </h2>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.firstName")}</Label>
            <Input value={data?.firstName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.lastName")}</Label>
            <Input value={data?.lastName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.email")}</Label>
            <Input value={data?.email} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.phone")}</Label>
            <Input value={data?.phone} disabled />
          </div>
        </div>
        <div className="formBox gap-4">
          <h2 className="mb-5 text-lg font-medium">
            {t("clientsPage.products")}
          </h2>
          {products.length ? (
            <Table>
              <TableHeader className="bg-gray-50">
                <TableHead>{t("clientsPage.productName")}</TableHead>
                <TableHead>{t("clientsPage.brand")}</TableHead>
                <TableHead>{t("clientsPage.warrantyStart")}</TableHead>
                <TableHead>{t("clientsPage.warrantyEnd")}</TableHead>
                <TableHead>{t("clientsPage.delete")}</TableHead>
              </TableHeader>
              <TableBody>
                {products?.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.brand}</TableCell>
                    <TableCell>{product.warrantyStartDate}</TableCell>
                    <TableCell>{product.warrantyExpireDate}</TableCell>
                    <TableCell>
                      <DeleteProductDialog
                        on={() => onClickDelete(product.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("clientsPage.clientCouldNotFound")}
              desc={t("clientsPage.clientCouldNotFoundDesc")}
            />
          )}
          {page > 1 && (
            <Pagination
              totalPages={products?.totalPages}
              currentPage={products?.page}
              pageSize={products?.size}
              onPageChange={(page) => onPageChange(page)}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default CustomerEditPage;

const DeleteProductDialog = ({ on }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Button
          variant="secondaryGray"
          className="border-none text-red-500 shadow-none"
        >
          <TrashIcon className="bg-white text-red-500" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("clientsPage.deleteWarning")}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={on}>
            {t("common.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
