import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FilterSheet from "./filterSheet";
import FilterTags from "./filterTags";

import ServiceActivationService from "@/services/serviceActivationService";

import useActivationsStore from "@/stores/useServiceActivationStore";

import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const AddProduct = () => {
  const { t } = useTranslation();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { id } = useParams();
  const { toast } = useToast();

  const {
    loading,
    getUnlinkedProducts,
    unlinkedProducts,
    setUnlinkedProductsFilter,
  } = useActivationsStore();

  useEffect(() => {
    getUnlinkedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setUnlinkedProductsFilter({ ...unlinkedProducts.filter, page });
    getUnlinkedProducts();
  };

  const onPageSizeChange = (size) => {
    setUnlinkedProductsFilter({ ...unlinkedProducts.filter, size, page: 1 });
    getUnlinkedProducts();
  };

  const onAddProducts = () => {
    ServiceActivationService.linkProducts({
      merchantProductIds: selectedProducts,
    }).then(() => {
      toast({
        title: t("settingsPage.productsAddedSuccesfully"),
        variant: "success",
      });
      setTimeout(() => {
        setSelectedProducts([]);
        getUnlinkedProducts();
      }, 1000);
    });
  };

  const onSelectAll = () => {
    unlinkedProducts?.data.length === selectedProducts.length
      ? setSelectedProducts([])
      : setSelectedProducts(
          unlinkedProducts?.data.map((product) => product.id),
        );
  };

  return (
    <div>
      <>
        <TableHeaderWrapper className="flex items-center justify-between gap-5">
          <FilterTags />
          <Button
            onClick={() => setOpenFilterSheet(true)}
            variant="secondaryGray"
            className="min-w-[150px] font-medium"
          >
            {t("common.addFilter")}
          </Button>
        </TableHeaderWrapper>
        {loading ? (
          <Table>
            <TableSkeleton colSpan={3} />
          </Table>
        ) : unlinkedProducts?.data.length ? (
          <Table className="border border-[#EAECF0] bg-white shadow-sm">
            <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
              <TableRow>
                <TableHead>
                  <Checkbox
                    checked={
                      unlinkedProducts?.data.length === selectedProducts.length
                    }
                    onClick={onSelectAll}
                  />
                </TableHead>
                <TableHead>{t("settingsPage.productName")}</TableHead>
                <TableHead>{t("settingsPage.brand")}</TableHead>
                <TableHead>{t("settingsPage.source")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {unlinkedProducts?.data.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedProducts.includes(product.id)}
                      onClick={() => {
                        if (selectedProducts.includes(product.id)) {
                          setSelectedProducts(
                            selectedProducts.filter((id) => id !== product.id),
                          );
                        } else {
                          setSelectedProducts([
                            ...selectedProducts,
                            product.id,
                          ]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.brand}</TableCell>
                  <TableCell>{product.source}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyTable
            title={t("common.emptyTableNoData")}
            desc={t("common.emptyTableNoDataDesc")}
          />
        )}
        <div className="flex flex-col items-center">
          {unlinkedProducts.filter.totalPages > 1 && (
            <Pagination
              totalPages={unlinkedProducts.filter.totalPages}
              currentPage={unlinkedProducts.filter.page}
              pageSize={unlinkedProducts.filter.size}
              onPageChange={(page) => onPageChange(page)}
            />
          )}
          <PageSizeSelect
            onPageSizeChange={onPageSizeChange}
            value={unlinkedProducts.filter.size}
          />
        </div>
        <div className="mt-5 flex justify-end">
          <Button
            disabled={selectedProducts.length === 0}
            onClick={onAddProducts}
          >
            {t("settingsPage.addProducts")}
          </Button>
        </div>
        <FilterSheet
          id={id}
          open={openFilterSheet}
          onOpenChange={setOpenFilterSheet}
        />
      </>
    </div>
  );
};

export default AddProduct;
