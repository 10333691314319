/* eslint-disable react/prop-types */
import { useState } from "react";

import DeleteCardDialog from "./deleteCardDialog";

import AmericanExpressIcon from "@/assets/icons/americanExpress.svg?react";
import DinersClubIcon from "@/assets/icons/dinersClub.svg?react";
import MasterCardIcon from "@/assets/icons/masterCard.svg?react";
import TrashIcon from "@/assets/icons/trashMini.svg?react";
import TroyIcon from "@/assets/icons/troy.svg?react";
import VisaIcon from "@/assets/icons/visa.svg?react";

const CreditCard = ({ item, onCardDelete }) => {
  const [openDeleteCardDialog, setOpenDeleteCardDialog] = useState(false);

  const icon = {
    MASTER_CARD: <MasterCardIcon className="h-8 w-8" />,
    VISA: <VisaIcon className="h-8 w-8" />,
    AMEX: <AmericanExpressIcon className="h-8 w-8" />,
    TROY: <TroyIcon className="h-8 w-8" />,
    DINERS: <DinersClubIcon className="h-8 w-8" />,
  };

  return (
    <div className="flex w-full flex-col items-center justify-center gap-10 rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] p-4 shadow-md">
      <div className="flex w-full justify-between">
        <span className="text-lg font-medium text-[#0040C9]">
          {item.cardAlias}
        </span>
        {icon[item.cardAssociation]}
      </div>
      <div className="flex w-full justify-between">
        <span className="mt-2 text-sm font-medium leading-6 text-[#111729]">
          **** **** **** {item.lastFourDigits}
        </span>
        <button
          onClick={() => setOpenDeleteCardDialog(true)}
          aria-label="Kartı sil"
        >
          <TrashIcon className="h-5 w-5" />
        </button>
      </div>
      <DeleteCardDialog
        onOpenChange={setOpenDeleteCardDialog}
        open={openDeleteCardDialog}
        onSubmit={() => onCardDelete(item.id)}
      />
    </div>
  );
};

export default CreditCard;
