import { AlertDialogDescription } from "@egaranti/components";
import { AlertDialogTitle } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@egaranti/components";

// İlgili kütüphaneyi import edin

const ConfirmationDialog = ({
  title,
  description,
  cancelText,
  confirmText,
  onConfirm,
  children,
  icon, // İkon bileşenini de dışarıdan alabiliriz
}) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent className="max-w-md">
        <div className="flex w-full items-center justify-center">{icon}</div>
        <AlertDialogTitle className="text-center text-xl font-medium text-[#111729]">
          {title}
        </AlertDialogTitle>
        <div className="flex flex-col items-center justify-center">
          <AlertDialogDescription className="text-md font-medium text-[#677389]">
            {description}
          </AlertDialogDescription>
        </div>
        <AlertDialogFooter className="mt-0 flex items-center justify-between">
          <AlertDialogCancel className="flex-1">{cancelText}</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm} className="flex-1">
            {confirmText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationDialog;

// usage
{
  /* <ConfirmationDialog
  triggerText={t("sellers.confirm")}
  title={t("sellers.dialog.confirmTitle")}
  description={t("sellers.dialog.confirmDescription")}
  cancelText={t("common.cancel")}
  confirmText={t("common.confirm")}
  onConfirm={handleDelete}
  IconComponent={AttentionIcon}
/> */
}
