import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import authService from "@/services/authService";

import * as z from "zod";

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formStep, setFormStep] = useState(1);

  const hash = searchParams.get("hash");

  useEffect(() => {
    if (hash) setFormStep(3);
  }, [hash]);

  const passwordSchema = z.object({
    password: z
      .string()
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      }),
    confirmPassword: z
      .string()
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      }),
  });

  const emailSchema = z.object({
    email: z
      .string()
      .email({
        message: t("forgotPasswordForm.validateMsg.email"),
      })
      .max(50, {
        message: t("forgotPasswordForm.validateMsg.emailMax"),
      }),
  });

  const passwordForm = useForm({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const emailForm = useForm({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmitStep1 = () => {
    authService
      .forgotPassword(emailForm.getValues())
      .then(() => setFormStep(2))
      .catch((err) => {
        toast({
          title: t("forgotPasswordForm.formMsg5"),
          variant: "error",
        });
      });
  };

  const onSubmitStep3 = () => {
    const bodyObj = { ...passwordForm.getValues(), hash };

    authService
      .resetPassword(bodyObj)
      .then((res) => {
        toast({
          title: t("forgotPasswordForm.formMsg4"),
          variant: "success",
        });
        navigate("/login");
      })
      .catch((err) => {
        toast({
          title: t("forgotPasswordForm.formMsg5"),
          variant: "error",
        });
      });
  };

  return (
    <div className="md:shadow-custom flex min-w-[400px] flex-col gap-8 rounded-lg bg-transparent p-6 md:bg-white">
      {formStep === 1 && (
        <>
          <div className="flex flex-col gap-3">
            <h1 className="formTitle">{t("forgotPasswordForm.title")}</h1>
            <p className="formDescription">
              {t("forgotPasswordForm.description")}
            </p>
          </div>
          <Form {...emailForm}>
            <form
              onSubmit={emailForm.handleSubmit(onSubmitStep1)}
              className="space-y-5"
            >
              <FormField
                control={emailForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>Email</Label>
                    <FormControl>
                      <Input
                        placeholder={t("forgotPasswordForm.emailPlaceholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col gap-4">
                <Button type="submit">
                  {t("forgotPasswordForm.continue")}
                </Button>
              </div>
            </form>
          </Form>
          <span className="formDescription mx-auto">
            {t("forgotPasswordForm.goBackMsg")}{" "}
            <Link to="/login" className="text-[#0049E6]">
              {t("forgotPasswordForm.login")}
            </Link>
          </span>
        </>
      )}
      {formStep === 2 && (
        <div className="flex w-[400px] flex-col items-center justify-center gap-4 p-4">
          <h2 className="text-2xl font-semibold text-[#101828]">
            {t("forgotPasswordForm.formMsg2")}
          </h2>
          <p className="text-md text-center text-[#677389]">
            <span className="text-[#364153]">
              {emailForm.getValues().email}
            </span>{" "}
            {t("forgotPasswordForm.formMsg3")}
          </p>
        </div>
      )}
      {formStep === 3 && (
        <>
          <div className="flex flex-col gap-3">
            <h1 className="formTitle">{t("forgotPasswordForm.title")}</h1>
            <p className="formDescription">
              {t("forgotPasswordForm.enterNewPassword")}
            </p>
          </div>
          <Form {...passwordForm}>
            <form
              onSubmit={passwordForm.handleSubmit(onSubmitStep3)}
              className="space-y-5"
            >
              <FormField
                control={passwordForm.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder={t("forgotPasswordForm.newPassword")}
                        autoComplete="on"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={passwordForm.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={t("forgotPasswordForm.newPasswordConfirm")}
                        type="password"
                        autoComplete="on"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col gap-4">
                <Button type="submit">
                  {t("forgotPasswordForm.resetPassword")}
                </Button>
              </div>
            </form>
          </Form>
        </>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
