/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import applicationsService from "@/services/applicationsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ApplicationInstallPage = () => {
  const { t } = useTranslation();
  const [isInstalled, setIsInstalled] = React.useState(false);
  const app = useLocation().state;

  React.useEffect(() => {
    applicationsService.checkInstalled().then((res) => {
      setIsInstalled(res.data[app.slug]);
    });
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/applications">
          {t("settingsPage.applications")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.applicationDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <Header
          isInstalled={isInstalled}
          setIsInstalled={setIsInstalled}
          app={app}
        />
        <p className="formBox leading-7 text-[#4A5567]">{app.description}</p>
      </div>
    </main>
  );
};

export default ApplicationInstallPage;

const Header = ({ app, isInstalled, setIsInstalled }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const onDeleteApp = () => {
    applicationsService.uninstallApplication(app.slug).then((res) => {
      setIsInstalled(false);
      toast({
        variant: "success",
        title: t("common.success"),
        description: "Uygulama başarıyla kaldırıldı",
      });
    });
  };

  return (
    <div className="formBox flex items-center justify-between gap-4">
      <div className="flex items-center gap-4">
        <div className="flex h-20 w-20 items-center justify-center">
          <img src={app.logoUrl} alt={app.name} className="h-auto w-auto" />
        </div>
        <h2 className="text-lg font-medium text-[#111729]">{app.name}</h2>
      </div>
      <p className="text-sm text-[#4A5567]">{app.subTitle}</p>
      {app?.redirectUrl ? (
        <Button asChild>
          <a href={app.redirectUrl} target="_blank" rel="noreferrer">
            {t("settingsPage.openApplication")}
          </a>
        </Button>
      ) : (
        <>
          {isInstalled ? (
            <div className="flex gap-3">
              <button
                onClick={onDeleteApp}
                className="rounded-md bg-red-600 px-4 py-2 text-sm text-[#F9FAFC] transition-colors duration-200 hover:bg-[#E53E3E]"
              >
                {t("settingsPage.uninstallApplication")}
              </button>
              {/* temporary edit from business */}
              {app.slug === "trendyol" && (
                <Button asChild>
                  <Link state={app} to="/applications/trendyol/edit">
                    {t("settingsPage.editApplication")}
                  </Link>
                </Button>
              )}
            </div>
          ) : (
            <Button asChild>
              <Link state={app} to={`/applications/${app.slug}/edit`}>
                {t("settingsPage.editApplication")}
              </Link>
            </Button>
          )}
        </>
      )}
    </div>
  );
};
