import { cn } from "@/lib/utils";

const GeneralSummaryCard = ({ children }) => {
  return (
    <div className="grid w-full grid-cols-2 divide-x rounded-lg border border-[#EAECF0] bg-white px-4 py-6 text-center shadow-sm">
      {children}
    </div>
  );
};

GeneralSummaryCard.LeftSide = ({ children, ...props }) => {
  return (
    <div
      className={cn("flex flex-col items-center justify-center gap-3")}
      {...props}
    >
      {children}
    </div>
  );
};
GeneralSummaryCard.RightSide = ({ children, ...props }) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-3 text-center",
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default GeneralSummaryCard;
