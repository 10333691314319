import axios from "@/lib/axios";

class ApplicationsService {
  constructor() {
    this.api = axios;
  }

  async checkInstalled() {
    const data = await this.api.get("/integrations");

    return data;
  }

  async getApplications() {
    const { data } = await this.api.get("/merchant-integrations");

    return data;
  }

  async getApplication(application) {
    const { data } = await this.api.get(
      `/merchant-${application}-integrations`,
    );

    return data;
  }

  async installApplication(application, data) {
    const { data: response } = await this.api.post(
      `/merchant-${application}-integrations`,
      data,
    );

    return response;
  }

  async updateApplication(application, data) {
    const { data: response } = await this.api.put(
      `/merchant-${application}-integrations`,
      data,
    );

    return response;
  }

  async uninstallApplication(application, data) {
    const { data: response } = await this.api.delete(
      `/merchant-${application}-integrations`,
      data,
    );

    return response;
  }
}

export default new ApplicationsService();
