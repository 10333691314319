import { Button, useToast } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PricingSlider from "./pricingSlider";

import paymentService from "@/services/paymentService";

import { cn } from "@/lib/utils";

const PlansAndFees = ({ merchantsPlan, fetchMerchantsPlan }) => {
  const { t } = useTranslation();
  const [pageState, setPageState] = useState("welcome"); // welcome, fees

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 py-10">
      <h1 className="text-2xl font-semibold leading-8 text-[#111729]">
        {t("payment.plansAndFees.title")}
      </h1>
      <h4 className="mb-7 text-lg font-medium text-[#677389]">
        {t("payment.plansAndFees.subtitle")}
      </h4>
      <div className="mb-3 flex h-[45px] w-[648px] max-w-[95vw] items-center justify-center rounded-xl bg-[#F2F5F9]">
        <div
          onClick={() => setPageState("welcome")}
          className={cn(
            "flex h-[33px] w-full max-w-[317px] cursor-pointer items-center justify-center rounded-lg transition-all",
            pageState === "welcome" && "bg-white",
          )}
        >
          <span
            className={cn(
              "text-base font-semibold text-[#677389] transition-all",
              pageState === "welcome" && "text-[#111729]",
            )}
          >
            {t("payment.plansAndFees.tabs.title1")}
          </span>
        </div>
        <div
          onClick={() => setPageState("fees")}
          className={cn(
            "flex h-[33px] w-full max-w-[317px] cursor-pointer items-center justify-center rounded-lg transition-all",
            pageState === "fees" && "bg-white",
          )}
        >
          <span
            className={cn(
              "text-base font-semibold text-[#677389] transition-all",
              pageState === "fees" && "text-[#111729]",
            )}
          >
            {t("payment.plansAndFees.tabs.title2")}
          </span>
        </div>
      </div>
      <div className="w-full px-5 md:px-0">
        {pageState === "welcome" ? (
          <WelcomeState
            merchantsPlan={merchantsPlan}
            fetchMerchantsPlan={fetchMerchantsPlan}
          />
        ) : (
          <PricingSlider />
        )}
      </div>
    </div>
  );
};

const WelcomeState = ({ merchantsPlan, fetchMerchantsPlan }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    paymentService
      .getStoredCardsForSubscription()
      .then((data) => {})
      .catch(() => {
        setDisabled(true);
      });
  }, []);

  const onClickStartPayAsGo = () => {
    paymentService
      .startPayAsGo()
      .then((res) => {
        toast({
          title: "Kullandıkça Öde Paketiniz başarıyla tanımlandı.",
          description:
            "Kart bilgilerinizi değiştirmek veya paketi iptal etmek isterseniz ödeme ayarlarım sayfasından değişiklikleri yapabilir ya da bize ulaşabilirsiniz.",
          variant: "success",
        });
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      })
      .finally(() => {
        fetchMerchantsPlan();
      });
  };

  return (
    <div>
      <div className="mx-auto flex w-max items-center justify-center gap-2 text-2xl font-bold text-[#111729] md:text-3xl">
        <span>{t("payment.plansAndFees.card1.title1")}</span>
        <span className="text-lg font-medium text-[#677389]">
          {t("payment.plansAndFees.card1.title2")}
        </span>
      </div>
      <div className="mb-6 mt-2 text-center text-sm font-medium text-[#677389]">
        {t("payment.plansAndFees.card1.subtitle")}
      </div>
      <div className="mx-auto mt-8 flex w-full max-w-[394px] flex-col rounded-lg border bg-white p-4 px-8 shadow-lg">
        <h2 className="text-2xl font-semibold text-[#364153]">
          {t("payment.plansAndFees.card1.cardTitle")}
        </h2>
        <p className="my-4 text-base font-medium text-[#364153]">
          {t("payment.plansAndFees.card1.cardSubtitle")}
        </p>
        <div className="mb-5 w-full border" />
        <ul className="flex flex-col gap-4 font-medium text-[#364153]">
          <li className="flex items-center gap-3">
            <CheckmarkIcon />
            {t("payment.plansAndFees.card1.listItem1")}
          </li>
          <li className="flex items-center gap-3">
            <CheckmarkIcon />
            {t("payment.plansAndFees.card1.listItem2")}
          </li>
          <li className="flex items-center gap-3">
            <CheckmarkIcon />
            {t("payment.plansAndFees.card1.listItem3")}
          </li>
        </ul>

        <Button
          className="mt-4"
          disabled={
            disabled || merchantsPlan?.merchantPaymentPlan === "PAY_AS_GO"
          }
          onClick={onClickStartPayAsGo}
        >
          {merchantsPlan?.merchantPaymentPlan === "PAY_AS_GO"
            ? t("payment.plansAndFees.card1.btnText")
            : t("payment.plansAndFees.card1.btnText2")}
        </Button>
        {disabled && (
          <span className="mt-4 text-center text-xs text-gray-600">
            ({t("payment.plansAndFees.card1.btnDisabledText")})
            <Link
              to={
                "/settings/payment/add-credit-card?redirect=/settings/payment&method=PAY_AS_GO"
              }
              className="!text-md ml-1 font-medium text-blue-700"
            >
              {t("payment.plansAndFees.card1.btnDisabledLink")}
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};

const CheckmarkIcon = () => {
  return (
    <svg
      className="w-6 flex-shrink-0"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="24" height="24" rx="12" fill="#AAC2F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.598 7.38967L10.438 14.2997L8.53797 12.2697C8.18797 11.9397 7.63797 11.9197 7.23797 12.1997C6.84797 12.4897 6.73797 12.9997 6.97797 13.4097L9.22797 17.0697C9.44797 17.4097 9.82797 17.6197 10.258 17.6197C10.668 17.6197 11.058 17.4097 11.278 17.0697C11.638 16.5997 18.508 8.40967 18.508 8.40967C19.408 7.48967 18.318 6.67967 17.598 7.37967V7.38967Z"
        fill="#0049E6"
      />
    </svg>
  );
};

export default PlansAndFees;
