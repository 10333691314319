import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import serviceClaimsService from "@/services/serviceClaimsService";

import Messages from "@/components/claims/ServiceClaims/messages";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ClaimEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();

  const [data, setData] = useState({});

  const getData = () => {
    serviceClaimsService.getClaim(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccept = () => {
    serviceClaimsService
      .updateClaim(id, {
        status: "APPROVED",
      })
      .then(() => {
        toast({
          title: t("claimsPage.requestAccepted"),
          variant: "success",
        });
        getData();
      })
      .catch(() => {
        toast({
          title: t("claimsPage.requestCouldNotBeAccepted"),
          variant: "error",
        });
      });
  };

  const onReject = () => {
    serviceClaimsService
      .updateClaim(id, {
        status: "REJECTED",
      })
      .then(() => {
        toast({
          title: t("claimsPage.requestRejected"),
          variant: "success",
        });
        getData();
      })
      .catch(() => {
        toast({
          title: t("claimsPage.requestCouldNotBeRejected"),
          variant: "error",
        });
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/service">
          {t("claimsPage.serviceRequests")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("claimsPage.claimDetails")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("claimsPage.serviceClaim")}
          </h2>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.claimNumber")}</Label>
            <Input value={data?.id} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.customerName")}</Label>
            <Input
              value={
                data?.individualCustomerFirstName +
                " " +
                data?.individualCustomerLastName
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.productName")}</Label>
            <Input value={data?.individualCustomerProductName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.phone")}</Label>
            <Input value={data?.phone} disabled />
          </div>
          <div className="col-span-2 flex flex-col gap-4">
            <Label className="col-span-2">
              {t("claimsPage.productProblem")}
            </Label>
            <Textarea value={data?.description} disabled />
          </div>
        </div>

        {data?.status !== "REJECTED" && data?.status !== "APPROVED" ? (
          <div className="flex w-full justify-end gap-5">
            <Button onClick={onReject} variant="secondaryGray">
              {t("claimsPage.reject")}
            </Button>
            <Button onClick={onAccept}>{t("claimsPage.accept")}</Button>
          </div>
        ) : (
          <div className="flex w-full justify-between">
            <Tag variant={data?.status === "REJECTED" ? "red" : "green"}>
              {data?.status === "REJECTED"
                ? t("claimsPage.rejected")
                : t("claimsPage.approved")}
            </Tag>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button disabled>{t("claimsPage.accept")}</Button>
                </TooltipTrigger>
                <TooltipContent>
                  {data?.status === "REJECTED"
                    ? t("claimsPage.rejected")
                    : t("claimsPage.approved")}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
      <Messages status={data?.status} claimId={id} />
    </main>
  );
};

export default ClaimEditPage;
