/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import useDocumentsStore from "@/stores/useDocumentsStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { filters, getDocuments, setFilters } = useDocumentsStore();
  const [filtersInSheet, setFiltersInSheet] = useState({
    name: "",
  });

  const onSaveFilter = () => {
    onOpenChange(false);
    setFilters({
      ...filters,
      ...filtersInSheet,
      page: 1,
    });
    getDocuments();
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("settingsPage.documentName")}</Label>
          <Input
            value={filtersInSheet.name}
            onChange={(e) =>
              setFiltersInSheet({
                ...filtersInSheet,
                name: e.target.value,
              })
            }
          />
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
