import axios from "@/lib/axios";

class UserService {
  constructor() {
    this.api = axios;
  }

  async getMerchantData() {
    const { data } = await this.api.get("/merchants");

    return data;
  }

  async getMerchantUsersData() {
    const { data } = await this.api.get("/merchant-users/info");

    return data;
  }

  async updateUser(data) {
    const { response } = await this.api.put("/merchant-users", data);

    return response;
  }
}

export default new UserService();
