import axios from "@/lib/axios";

class EmployeesService {
  constructor() {
    this.api = axios;
  }

  async getMerchantUsers() {
    const { data } = await this.api.get("/merchant-users");

    return data;
  }

  async addMerchantUser(bodyObj) {
    const { data } = await this.api.post("/merchant-users/add", bodyObj);

    return data;
  }

  async getMerchantUserById(merchantUserId) {
    const { data } = await this.api.get(`/merchant-users/${merchantUserId}`);

    return data;
  }

  async updateMerchantUser(args) {
    const { bodyObj1, bodyObj2 } = args;

    const request1 = this.api.put("/merchant-users", bodyObj1);
    const request2 = this.api.put("/merchant-users/roles", bodyObj2);

    const data = await Promise.all([request1, request2]);

    return data;
  }
}

export default new EmployeesService();
