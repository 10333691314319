import i18n from "../i18n";

import axios from "axios";

const api = axios.create({
  baseURL: import.meta.env.VITE_BACKEND,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Accept-Language": i18n.language,
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
