import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useInboxStore from "@/stores/useInboxStore";

import EmptyTable from "@/components/shared/EmptyTable";

const intervalSettings = {
  1: { interval: 7, temporalUnitEnum: "DAY" },
  2: { interval: 1, temporalUnitEnum: "MONTH" },
  3: { interval: 3, temporalUnitEnum: "MONTH" },
  4: { interval: 6, temporalUnitEnum: "MONTH" },
  5: { interval: 1, temporalUnitEnum: "YEAR" },
};

const NotificationPage = () => {
  const { t } = useTranslation();

  const { list: data, setFilters, loading, markAsRead } = useInboxStore();
  const [selectedDate, setSelectedDate] = useState(1);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    const { interval, temporalUnitEnum } = intervalSettings[selectedDate] || {
      interval: 7,
      temporalUnitEnum: "DAY",
    };

    setFilters({
      page: 1,
      size: 100,
      interval,
      temporalUnitEnum,
    });
    markAsRead();
  }, [setFilters, selectedDate]);

  const groupedNotifications = useMemo(() => {
    const now = new Date();
    const startOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    );
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay(),
    );
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    if (!Array.isArray(data)) {
      return {
        today: [],
        thisWeek: [],
        thisMonth: [],
        thisYear: [],
        older: [],
      };
    }

    return data.reduce(
      (acc, notification) => {
        // Manually parse the createdAt date string
        const [day, month, year, time] = notification.createdAt.split(/[-\s:]/);
        const notificationDate = new Date(
          year,
          month - 1, // Month in JavaScript Date is zero-based
          day,
          ...time.split(":").map(Number),
        );

        const normalizedNotificationDate = new Date(
          notificationDate.getFullYear(),
          notificationDate.getMonth(),
          notificationDate.getDate(),
        );

        if (
          normalizedNotificationDate >= startOfDay &&
          notificationDate < now
        ) {
          acc.today.push(notification);
        } else if (normalizedNotificationDate >= startOfWeek) {
          acc.thisWeek.push(notification);
        } else if (normalizedNotificationDate >= startOfMonth) {
          acc.thisMonth.push(notification);
        } else if (normalizedNotificationDate >= startOfYear) {
          acc.thisYear.push(notification);
        } else {
          acc.older.push(notification);
        }

        return acc;
      },
      { today: [], thisWeek: [], thisMonth: [], thisYear: [], older: [] },
    );
  }, [data]);

  const TimeSection = ({ title, notifications }) => {
    if (notifications.length === 0) return null;
    return (
      <div>
        <h2 className="mb-4 text-lg font-semibold">{title}</h2>
        <div className="space-y-4">
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              {...notification}
              date={notification.createdAt}
            />
          ))}
        </div>
      </div>
    );
  };

  const dataTypes = [
    null,
    "WARRANTY_ERROR",
    "PRODUCT_IMPORT",
    "PRODUCT_UNLINK",
    "PRODUCT_LINK",
    "PRODUCT_EXPORT",
    "DEFAULT_WARRANTY_DOCUMENT_CREATE",
    "INTEGRATION_CREATE",
    "INTEGRATION_PRODUCT_IMPORT",
    "WARRANTY_DOCUMENT_IS_MISSING",
    "USER_MANUEL_DOCUMENT_IS_MISSING",
  ];

  if (loading) return <Skeleton />;

  return (
    <main className="flex h-max min-h-full flex-col gap-12 bg-[#F9FAFC] p-4">
      {/* Header */}
      <div className="mb-6 flex items-center justify-end rounded-lg bg-white p-6">
        <div className="flex w-full max-w-sm gap-4">
          <Select
            onValueChange={(value) => {
              setSelectedType(value);
              setFilters({ type: value });
            }}
            value={selectedType}
          >
            <SelectTrigger>
              <SelectValue placeholder="Bildirim Türü" />
            </SelectTrigger>
            <SelectContent>
              {dataTypes.map((type) => (
                <SelectItem key={type} value={type}>
                  {t(`notificationTypes.${type}`)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            onValueChange={(value) => {
              setSelectedDate(value);

              // Get the interval and temporal unit from the settings
              const { interval, temporalUnitEnum } = intervalSettings[
                value
              ] || {
                interval: 7,
                temporalUnitEnum: "DAY",
              };

              // Update the filters in the store
              setFilters({ interval, temporalUnitEnum });
            }}
            value={selectedDate.toString()}
          >
            <SelectTrigger>
              <SelectValue placeholder={t("overviewPage.selectDate")} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={"1"}>{t("date.lastWeek")}</SelectItem>
              <SelectItem value={"2"}>{t("date.lastMonth")}</SelectItem>
              <SelectItem value={"3"}>{t("date.last3Months")}</SelectItem>
              <SelectItem value={"4"}>{t("date.last6Months")}</SelectItem>
              <SelectItem value={"5"}>{t("date.lastYear")}</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="space-y-6">
        {!data.length && (
          <EmptyTable
            title={t("inboxEmptyTable.title")}
            desc={t("inboxEmptyTable.desc")}
          />
        )}
        <TimeSection title="Bugün" notifications={groupedNotifications.today} />
        <TimeSection
          title={t("inboxPage.thisWeek")}
          notifications={groupedNotifications.thisWeek}
        />
        <TimeSection
          title={t("inboxPage.thisMonth")}
          notifications={groupedNotifications.thisMonth}
        />

        <TimeSection
          title={t("inboxPage.thisYear")}
          notifications={groupedNotifications.older}
        />
        <TimeSection
          title={t("inboxPage.older")}
          notifications={groupedNotifications.older}
        />
      </div>
    </main>
  );
};

const NotificationItem = ({ type, message, date, redirect, read }) => {
  const { t } = useTranslation();
  const placehoderIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.3333V10M10 6.66667H10.0083M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
        stroke="#002573"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const iconMap = {
    WARRANTY_ERROR: placehoderIcon,
    PRODUCT_IMPORT: placehoderIcon,
    PRODUCT_UNLINK: placehoderIcon,
    PRODUCT_LINK: placehoderIcon,
    PRODUCT_EXPORT: placehoderIcon,
    DEFAULT_WARRANTY_DOCUMENT_CREATE: placehoderIcon,
    INTEGRATION_CREATE: placehoderIcon,
    INTEGRATION_PRODUCT_IMPORT: placehoderIcon,
    default: placehoderIcon,
  };

  const customMessages = {
    WARRANTY_ERROR: t("inboxCustomMessages.warranty_error", {
      productName: JSON.parse(message)?.productName,
      productCode: JSON.parse(message)?.productCode,
      externalOrderId: JSON.parse(message)?.externalOrderId,
      externalLineId: JSON.parse(message)?.externalLineId,
    }),
    PRODUCT_IMPORT: t("inboxCustomMessages.product_import"),
    PRODUCT_UNLINK: t("inboxCustomMessages.product_unlink", {
      name: JSON.parse(message)?.name,
      count: JSON.parse(message)?.count,
    }),
    PRODUCT_LINK: t("inboxCustomMessages.product_link", {
      count: JSON.parse(message)?.count,
    }),
    PRODUCT_EXPORT: t("inboxCustomMessages.product_export", {
      count: JSON.parse(message)?.count,
    }),
    DEFAULT_WARRANTY_DOCUMENT_CREATE: t(
      "inboxCustomMessages.default_warranty_document_create",
      {
        count: JSON.parse(message)?.count,
      },
    ),
    INTEGRATION_CREATE: t("inboxCustomMessages.integration_create", {
      key: JSON.parse(message)?.key,
    }),
    INTEGRATION_PRODUCT_IMPORT: t(
      "inboxCustomMessages.integration_product_import",
      {
        key: JSON.parse(message)?.key,
      },
    ),
    WARRANTY_DOCUMENT_IS_MISSING: t(
      "inboxCustomMessages.warranty_document_is_missing",
      {
        count: JSON.parse(message)?.count,
      },
    ),
    USER_MANUEL_DOCUMENT_IS_MISSING: t(
      "inboxCustomMessages.user_manuel_document_is_missing",
      {
        count: JSON.parse(message)?.count,
      },
    ),
    default: message,
  };

  return (
    <div className="relative grid grid-cols-[auto_1fr_auto] items-center gap-6 rounded-lg bg-white p-6">
      {/* Icon Section */}

      <div className="flex items-center gap-3">
        {!read && <div className="h-2 w-2 rounded-full bg-[#0049E6]"></div>}
        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-blue-100">
          {iconMap[type] || iconMap["default"]}
        </div>
      </div>
      <div className="text-sm text-[#111729]">
        {customMessages[type]}
        {redirect &&
          (redirect.startsWith("http") || redirect.startsWith("https") ? (
            <a
              href={redirect}
              className="ml-1 text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("inboxPage.seeNow")}
            </a>
          ) : (
            <Link to={redirect} className="ml-1 text-blue-600">
              {t("inboxPage.seeNow")}
            </Link>
          ))}
      </div>
      {/* Personnel and Date Section */}
      <div className="flex items-center gap-12">
        <div className="text-right text-sm font-medium text-[#111729]">
          <div>{date}</div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;

const Skeleton = () => {
  return (
    <main
      aria-busy="true"
      aria-live="polite"
      className="flex h-max min-h-full flex-col gap-12 bg-[#F9FAFC] p-4"
    >
      {/* Header */}
      <div className="mb-6 flex items-center justify-between rounded-lg bg-white p-6">
        <div className="flex w-full max-w-sm gap-4">
          <div className="h-10 w-40 rounded-lg bg-gray-200"></div>
          <div className="h-10 w-40 rounded-lg bg-gray-200"></div>
        </div>
      </div>
      <div className="space-y-6">
        <div className="flex animate-pulse flex-col gap-4">
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
          <div className="w-w-full h-12 rounded-lg bg-gray-100"></div>
        </div>
      </div>
    </main>
  );
};
