import React from "react";

import AuthHeader from "@/components/auth/authHeader";
import LoginForm from "@/components/auth/loginForm";

const Login = () => {
  return (
    <div className="bg-[#F9FAFC]">
      <AuthHeader />
      <main
        className="flex w-full items-center justify-center"
        style={{ minHeight: "calc(100vh - 7rem)" }}
      >
        <LoginForm />
      </main>
    </div>
  );
};

export default Login;
