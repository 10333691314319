import axios from "@/lib/axios";

class ServiceServiceActivationService {
  constructor() {
    this.api = axios;
  }

  async getClaimActivations() {
    const { data } = await this.api.get("/merchant-service-claim-activations");

    return data;
  }

  async getLinkedProducts(filters) {
    const { data } = await this.api.get(
      "/merchant-service-claim-activations/linked-products",
      { params: filters },
    );

    return data;
  }

  async getUnlinkedProducts(filters) {
    const { data } = await this.api.get(
      "/merchant-service-claim-activations/unlinked-products",
      { params: filters },
    );

    return data;
  }

  async linkProducts(data) {
    const response = await this.api.put(
      "/merchant-service-claim-activations/link-products",
      data,
    );

    return response;
  }

  async unlinkProducts(data) {
    const response = await this.api.put(
      "/merchant-service-claim-activations/unlink-products",
      data,
    );

    return response;
  }

  async deleteActivation(id) {
    const response = await this.api.delete(
      `/merchant-service-claim-activations/${id}`,
    );

    return response;
  }

  async createActivation(data) {
    const response = await this.api.post(
      "/merchant-service-claim-activations",
      data,
    );

    return response;
  }

  async updateActivation(id, data) {
    const response = await this.api.put(
      `/merchant-service-claim-activations/${id}`,
      data,
    );

    return response;
  }

  async getClaimOptions() {
    const { data } = await this.api.get("/merchant-service-claim-options");

    return data;
  }

  async deleteClaimOption(id) {
    const response = await this.api.delete(
      `/merchant-service-claim-options/${id}`,
    );

    return response;
  }

  async createClaimOption(data) {
    const response = await this.api.post("/merchant-service-claim-options", {
      option: data,
    });

    return response;
  }
}

export default new ServiceServiceActivationService();
