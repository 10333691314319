import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { DatePicker } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import addressService from "@/services/addressService";
import serviceRecordsService from "@/services/serviceRecordsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";
import InfoTooltip from "@/components/shared/infoTooltip";
import SelectProduct from "@/components/shared/selectProduct";

import { format } from "date-fns";
import * as z from "zod";

const ClaimCreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);

  const schema = z.object({
    firstName: z.string({ message: t("claimsPage.firstNameNonEmpty") }),
    lastName: z.string({ message: t("claimsPage.lastNameNonEmpty") }),
    phone: z.string({ message: t("claimsPage.phoneNonEmpty") }),
    countryCode: z.string({ message: t("claimsPage.countryNonEmpty") }),
    merchantProductId: z.number({ message: t("claimsPage.productIdNonEmpty") }),
    reason: z.string({ message: t("claimsPage.reasonNonEmpty") }),
    purchaseDate: z.coerce.date({
      message: t("claimsPage.purchaseDateNonEmpty"),
    }),
    state: z.string({ message: t("claimsPage.statusNonEmpty") }),
    roadKm: z.string({ message: t("claimsPage.roadKmNonEmpty") }),
    note: z.string({ message: t("claimsPage.noteNonEmpty") }),
    sparePart: z.string({ message: t("claimsPage.sparePartNonEmpty") }),
    processDate: z.coerce.date({
      message: t("claimsPage.processDateNonEmpty"),
    }),
    cityId: z.string({ message: t("claimsPage.cityNonEmpty") }),
    districtId: z.string({ message: t("claimsPage.distructNonEmpty") }),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: "TR",
      merchantProductId: null,
      reason: "FAULT",
      purchaseDate: "",
      state: "GUARANTEED",
      roadKm: 0,
      note: "",
      sparePart: "",
      processDate: "",
      cityId: 0,
      districtId: 0,
    },
  });

  useEffect(() => {
    addressService.getCities().then((res) => {
      setCities(res);
    });
  }, []);

  const onSelectCity = (id) => {
    addressService.getDistricts(id).then((res) => {
      setDistricts(res);
    });
  };

  const onSubmit = (data) => {
    setLoading(true);

    data.purchaseDate = format(new Date(data.purchaseDate), "yyyy-MM-dd");
    data.processDate = format(new Date(data.processDate), "yyyy-MM-dd");

    serviceRecordsService
      .createServiceRecord(data)
      .then(() => {
        toast({
          title: t("claimsPage.serviceRecordCreatedSuccessfully"),
          variant: "success",
        });
        navigate("/claims/service-record");
      })
      .catch((err) => {
        toast({
          title: err?.response?.data?.errors[0]?.message,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/service-record">
          {t("claimsPage.serviceRecords")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("claimsPage.serviceRecordDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("claimsPage.serviceRecordDetails")}
          </h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="col-span-2 grid grid-cols-2 gap-4"
            >
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.firstName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.lastName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-4">
                <FormField
                  control={form.control}
                  name="countryCode"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.country")}</Label>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Ülke" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="TR">+90</SelectItem>
                          <SelectItem value="US">+1</SelectItem>
                          <SelectItem value="UZ">+998</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("claimsPage.phone")}</Label>
                      <FormControl>
                        <Input type="tel" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="cityId"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.province")}</Label>
                    <FormControl>
                      <Select
                        onValueChange={(e) => {
                          onSelectCity(e);
                          field.onChange(e);
                        }}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {cities.map((city, index) => (
                            <SelectItem key={index} value={city.id.toString()}>
                              {city.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="districtId"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.state")}</Label>
                    <FormControl>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {districts.map((district, index) => (
                            <SelectItem
                              key={index}
                              value={district.id.toString()}
                            >
                              {district.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="merchantProductId"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <Label>{t("products.productName")}</Label>
                    <FormControl>
                      <SelectProduct
                        value={field.value}
                        setValue={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="reason"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.reason")}</Label>
                    <FormControl>
                      <Select onValueChange={field.onChange}>
                        <SelectTrigger>
                          <SelectValue placeholder={t("claimsPage.fault")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="FAULT">
                            {t("claimsPage.fault")}
                          </SelectItem>
                          <SelectItem value="INSTALLATION">
                            {t("claimsPage.setup")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="roadKm"
                render={({ field }) => (
                  <FormItem>
                    <Label>
                      {t("claimsPage.kilometer")}{" "}
                      <InfoTooltip>
                        {t("claimsPage.kilometerTooltip")}
                      </InfoTooltip>
                    </Label>

                    <FormControl>
                      <Input type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="purchaseDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <Label>{t("claimsPage.purchaseDate")}</Label>
                    <FormControl>
                      <DatePicker
                        selectedDate={field.value}
                        onDateChange={field.onChange}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="processDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <Label>{t("claimsPage.processDate")}</Label>
                    <FormControl>
                      <DatePicker
                        selectedDate={field.value}
                        onDateChange={field.onChange}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.status")}</Label>
                    <FormControl>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="GUARANTEED">
                            {t("claimsPage.guaranteed")}
                          </SelectItem>
                          <SelectItem value="NOT_GUARANTEED">
                            {t("claimsPage.notGuaranteed")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sparePart"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.sparePart")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="note"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.note")}</Label>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <div className="flex w-full justify-end">
          <Button disabled={loading} onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default ClaimCreatePage;
