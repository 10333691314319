import { useState } from "react";

import CreateDefaultDocumentModal from "./createDefaultDocumentModal";

const DefaultDocumentCard = () => {
  const [relatedProductCount, setRelatedProductCount] = useState(0);
  return (
    <CreateDefaultDocumentModal setRelatedProductCount={setRelatedProductCount}>
      <div
        role="button"
        tabIndex="0"
        className="flex flex-col rounded-lg border border-[#EAECF0] bg-gradient-to-r from-[#131F3D] to-[#88AAF3] p-2 pt-4"
      >
        <h4 className="pb-3 text-center text-xs text-[#F2F5F9]">
          Belgesi eksik olan tüm ürünlerinize eklenecektir ✨
        </h4>
        <div className="flex flex-col items-start rounded-md bg-[#F9FAFC] p-7">
          <svg
            width="30"
            height="36"
            viewBox="0 0 30 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3332 1.78247V8.66671C18.3332 9.60013 18.3332 10.0668 18.5148 10.4234C18.6746 10.737 18.9296 10.9919 19.2432 11.1517C19.5997 11.3334 20.0664 11.3334 20.9998 11.3334H27.8841M18.3332 26.3333H8.33317M21.6665 19.6666H8.33317M28.3332 14.647V26.6666C28.3332 29.4668 28.3332 30.867 27.7882 31.9365C27.3088 32.8773 26.5439 33.6423 25.6031 34.1216C24.5336 34.6666 23.1334 34.6666 20.3332 34.6666H9.6665C6.86624 34.6666 5.46611 34.6666 4.39655 34.1216C3.45574 33.6423 2.69084 32.8773 2.21147 31.9365C1.6665 30.867 1.6665 29.4668 1.6665 26.6666V9.33325C1.6665 6.53299 1.6665 5.13286 2.21147 4.0633C2.69084 3.12249 3.45574 2.35759 4.39655 1.87822C5.46611 1.33325 6.86624 1.33325 9.6665 1.33325H15.0195C16.2424 1.33325 16.8539 1.33325 17.4293 1.4714C17.9395 1.59389 18.4272 1.79591 18.8746 2.07005C19.3792 2.37926 19.8116 2.81164 20.6763 3.6764L25.99 8.99011C26.8548 9.85486 27.2872 10.2872 27.5964 10.7918C27.8705 11.2392 28.0725 11.7269 28.195 12.2371C28.3332 12.8125 28.3332 13.424 28.3332 14.647Z"
              stroke="#111729"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div className="mt-4 flex items-center justify-between">
            <span className="text-lg font-medium text-[#111729]">
              Varsayılan Belge
            </span>
          </div>
          <span className="text-sm text-[#4A5567]">
            <b className="mr-1 text-[#111729]">{relatedProductCount}</b> ürün
            bağlı
          </span>
          <div className="flex items-center justify-between gap-2 pt-10"></div>
        </div>
      </div>
    </CreateDefaultDocumentModal>
  );
};

export default DefaultDocumentCard;
