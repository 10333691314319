import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import SellerService from "@/services/sellerService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const ViewSeller = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const schema = z.object({
    title: z.string().min(2, "En az 2 karakter olmalıdır"),
    phone: z.string().min(2, "En az 2 karakter olmalıdır"),
    email: z.string().email(),
    status: z.string().min(2, "En az 2 karakter olmalıdır"),
    countryCode: z.string().min(2, "En az 2 karakter olmalıdır"),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      title: "",
      phone: "",
      email: "",
      status: "",
      countryCode: "",
    },
  });

  const removeLast10Chars = (str) => {
    if (str.length > 10) {
      return str.substring(0, str.length - 10);
    }
  };

  useEffect(() => {
    SellerService.getSeller(id).then((res) => {
      form.setValue(
        "countryCode",
        removeLast10Chars(res.phone) === "+90"
          ? "TR"
          : removeLast10Chars(res.phone) === "+1" && "US",
      );
      form.setValue("title", res.title);
      form.setValue("email", res.email);
      form.setValue("status", res.status);
      form.setValue("countryCode", "TR");
      form.setValue("phoneNumber", res.phone.slice(-10));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    console.log("onSubmit invoked, data =>", data);
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/sellers">Satıcılar</BreadcrumbItem>
        <BreadcrumbItem active>Satıcı</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox grid grid-cols-1 gap-4 md:grid-cols-2">
              <h2 className="col-span-1 mb-5 text-lg font-medium md:col-span-2">
                Satıcı Bilgileri
              </h2>
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <Label>Title</Label>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-4">
                <div className="flex w-full max-w-[110px] flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="countryCode"
                    render={({ field }) => (
                      <FormItem>
                        <Label>Ülke Kodu</Label>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger disabled>
                              <SelectValue />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {[
                              { value: "TR", label: "+90" },
                              { value: "US", label: "+1" },
                            ].map((countryCode, index) => (
                              <SelectItem key={index} value={countryCode.value}>
                                {countryCode.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex w-full flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <Label>Telefon Numarası</Label>
                        <FormControl>
                          <Input disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>Email</Label>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <FormItem>
                    <Label>Status</Label>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger disabled>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {[
                          { id: "1", name: "APPROVED" },
                          { id: "2", name: "REJECTED" },
                        ].map((category, index) => (
                          <SelectItem key={index} value={category.name}>
                            {category.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="col-span-1 flex w-full items-center justify-end gap-2 md:col-span-2">
                <Button
                  variant="secondaryGray"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/sellers");
                  }}
                >
                  Geri
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </main>
  );
};

export default ViewSeller;
