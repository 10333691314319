import React from "react";

import { cn } from "@/lib/utils";

const TableHeaderWrapper = ({ children, className, ...props }) => {
  return (
    <div
      className={cn(
        "flex w-full items-center justify-end rounded-t-lg border-b bg-white px-6 py-5",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default TableHeaderWrapper;
