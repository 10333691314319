import IndividualCustomersService from "@/services/individualCustomersService";

import { create } from "zustand";

const useIndividualCustomerStore = create((set) => ({
  customers: [],
  loading: false,
  products: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  getIndividuaCustomers: async () => {
    set({ loading: true });
    const data = await IndividualCustomersService.getIndividualCustomers(
      useIndividualCustomerStore.getState().filters,
    );

    set({
      customers: data.content,
      loading: false,
      filters: {
        ...useIndividualCustomerStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useIndividualCustomerStore;
