import ProductService from "@/services/productService";

import { create } from "zustand";

const useProductsStore = create((set) => ({
  loading: false,
  products: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    direction: "ASC",
    totalPages: 0,
    mainCategoryId: null,
    categoryId: null,
    subCategoryId: null,
    subCategoryBrandId: null,
    name: "",
  },
  getProducts: async () => {
    set({ loading: true });
    const data = await ProductService.getProducts(
      useProductsStore.getState().filters,
    );
    set({
      products: data.content,
      loading: false,
      filters: {
        ...useProductsStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useProductsStore;
