import EmployeesService from "@/services/employeesService";

import { create } from "zustand";

const useEmployeesStore = create((set) => ({
  loading: false,
  employees: [],

  getEmployees: async () => {
    set({ loading: true });
    const data = await EmployeesService.getMerchantUsers();
    set({
      employees: data,
      loading: false,
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useEmployeesStore;
