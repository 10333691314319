import axios from "@/lib/axios";

class AddressService {
  constructor() {
    this.api = axios;
  }

  async getCities() {
    const { data } = await this.api.get("/addresses/cities");
    return data;
  }

  async getDistricts(id) {
    const { data } = await this.api.get(`/addresses/cities/${id}/districts`);
    return data;
  }
  async getAddresses() {
    const response = await this.api.get("/merchant-addresses");
    return response;
  }

  async postAddress(data) {
    const response = await this.api.post("/merchant-addresses", data);
    return response;
  }

  async deleteAddress(id) {
    const response = await this.api.delete(`/merchant-addresses/${id}`);
    return response;
  }

  async addAddress(data) {
    const response = await this.api.post("/addresses", data);
    return response;
  }
}

export default new AddressService();
