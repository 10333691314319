/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from "@egaranti/components";

import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useTranslation } from "react-i18next";

import IParaLogo from "@/assets/icons/ipara-logo.svg?react";

import { useHookFormMask } from "use-mask-input";

const CreditCardForm = ({
  form,
  onSubmit,
  actionText = "Kartı Kaydet",
  isCvcRequired = true,
}) => {
  const { t } = useTranslation();
  const handleFocus = (e) => {
    form.setValue("focus", e.target.name);
  };

  const registerWithMask = useHookFormMask(form.register);

  return (
    <div className="grid grid-cols-2 gap-16">
      <div className="">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <h2 className="text-lg font-medium text-[#364153]">
              {t("payment.addCreditCard.formTitle")}
            </h2>
            <FormField
              control={form.control}
              name="cardHolderName"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("payment.addCreditCard.label1")}</Label>
                  <FormControl>
                    <Input onFocus={handleFocus} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="cardNumber"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("payment.addCreditCard.label2")}</Label>
                  <FormControl>
                    <Input
                      type="tel"
                      onFocus={handleFocus}
                      {...field}
                      {...registerWithMask("cardNumber", {
                        mask: "9999 9999 9999 9999",
                        placeholder: "1234 5678 1234 5678",
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center gap-4">
              <FormField
                control={form.control}
                name="expireDate"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("payment.addCreditCard.label3")}</Label>
                    <FormControl>
                      <Input
                        type="tel"
                        onFocus={handleFocus}
                        {...field}
                        {...registerWithMask("expireDate", {
                          mask: "99/99",
                          placeholder: "MM/YY",
                        })}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isCvcRequired && (
                <FormField
                  control={form.control}
                  name="cvc"
                  render={({ field }) => (
                    <FormItem>
                      <Label>CVC</Label>
                      <FormControl>
                        <Input
                          onFocus={handleFocus}
                          {...field}
                          {...registerWithMask("cvc", {
                            mask: "999",
                            placeholder: "CVC",
                          })}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </form>
        </Form>
        <div className="my-4 flex items-center gap-2">
          <IParaLogo />
          <span className="text-sm font-medium text-[#677389]">
            {t("payment.addCreditCard.payWith")}
          </span>
        </div>
        <p className="mb-4 text-sm text-[#677389]">
          {t("payment.addCreditCard.desc1")}
        </p>
        <p className="mb-6 text-xs font-medium text-[#677389]">
          {t("payment.addCreditCard.desc2")}
        </p>
        <Label className="mb-8 flex items-start gap-2 text-sm text-[#364153]">
          <Checkbox
            checked={form.watch("storeCardAfterSuccessPayment")}
            onClick={() => {
              form.setValue(
                "storeCardAfterSuccessPayment",
                !form.watch("storeCardAfterSuccessPayment"),
              );
            }}
          />
          {t("payment.addCreditCard.checkboxText")}
        </Label>
        <Button onClick={form.handleSubmit(onSubmit)}>{actionText}</Button>
      </div>
      <div>
        <Cards
          placeholders={{ name: "AD SOYAD" }}
          cvc={form.watch("cvc") || ""}
          expiry={form.watch("expireDate")}
          focused={form.watch("focus")}
          name={form.watch("cardHolderName")}
          number={form.watch("cardNumber")}
        />
      </div>
    </div>
  );
};

export default CreditCardForm;
