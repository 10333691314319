import SellerService from "@/services/sellerService";

import { create } from "zustand";

const useSellerStore = create((set) => ({
  sellers: [],
  loading: false,
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
    phone: "",
    email: "",
  },
  sellersWaiting: [],
  sellersWaitingFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
    phone: "",
    email: "",
  },
  getSellers: async () => {
    set({ loading: true });
    const data = await SellerService.getSellers(
      useSellerStore.getState().filters,
    );
    set({ sellers: data, loading: false });
  },
  getSellersWaiting: async () => {
    set({ loading: true });
    const data = await SellerService.getSellersWaiting(
      useSellerStore.getState().sellersWaitingFilters,
    );
    set({ sellersWaiting: data, loading: false });
  },
  setFilters(filters) {
    set({ filters });
  },
  setSellersWaitingFilters(filters) {
    set({ sellersWaitingFilters: filters });
  },
}));

export default useSellerStore;
