/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useServiceClaimsStore from "@/stores/useServiceClaimsStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { filters, getClaims, setFilters } = useServiceClaimsStore();

  const [filtersInSheet, setFiltersInSheet] = useState({
    individualCustomerProductName: "",
    individualCustomerFirstName: "",
    individualCustomerLastName: "",
    serviceClaimStatus: "",
    claimNo: null,
  });

  useEffect(() => {
    setFiltersInSheet({
      individualCustomerProductName: filters.individualCustomerProductName,
      individualCustomerFirstName: filters.individualCustomerFirstName,
      individualCustomerLastName: filters.individualCustomerLastName,
      serviceClaimStatus: filters.serviceClaimStatus,
      claimNo: filters.claimNo,
    });
  }, [filters]);

  const onSaveFilter = () => {
    onOpenChange(false);
    setFilters({
      ...filters,
      ...filtersInSheet,
      page: 1,
    });
    getClaims();
  };

  const handleFieldChange = (fieldName, value) => {
    setFiltersInSheet({
      ...filtersInSheet,
      [fieldName]: value,
    });
  };

  const OPTIONS = [
    {
      id: 1,
      value: "WAITING_MERCHANT",
      name: t("claimsPage.waitingMerchant"),
    },
    {
      id: 2,
      value: "WAITING_CUSTOMER",
      name: t("claimsPage.waitingCustomer"),
    },
    {
      id: 3,
      value: "REJECTED",
      name: t("claimsPage.rejected"),
    },
    {
      id: 4,
      value: "ACCEPTED",
      name: t("claimsPage.approved"),
    },
  ];

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("claimsPage.requestNumber")}</Label>
          <Input
            type="number"
            value={filtersInSheet.claimNo}
            onChange={(e) => handleFieldChange("claimNo", e.target.value)}
          />
          <Label>{t("claimsPage.productName")}</Label>
          <Input
            value={filtersInSheet.individualCustomerProductName}
            onChange={(e) =>
              handleFieldChange("individualCustomerProductName", e.target.value)
            }
          />
          <Label>{t("common.firstName")}</Label>
          <Input
            value={filtersInSheet.individualCustomerFirstName}
            onChange={(e) =>
              handleFieldChange("individualCustomerFirstName", e.target.value)
            }
          />
          <Label>{t("common.lastName")}</Label>
          <Input
            value={filtersInSheet.individualCustomerLastName}
            onChange={(e) =>
              handleFieldChange("individualCustomerLastName", e.target.value)
            }
          />
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.claimStatus")}</Label>
            <Select
              value={filtersInSheet.serviceClaimStatus}
              onValueChange={(value) =>
                handleFieldChange("serviceClaimStatus", value)
              }
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {OPTIONS.map((option) => (
                  <SelectItem key={option.id} value={option.value}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
