/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductService from "@/services/productService";

import useActivationsStore from "@/stores/useServiceActivationStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { getUnlinkedProducts, setUnlinkedProductsFilter, unlinkedProducts } =
    useActivationsStore();

  const [filtersInSheet, setFiltersInSheet] = useState({
    name: "",
    mainCategoryId: "",
    categoryId: "",
    subCategoryId: "",
    subCategoryBrandId: "",
  });

  const onSaveFilter = () => {
    onOpenChange(false);
    setUnlinkedProductsFilter({
      ...unlinkedProducts.filter,
      ...filtersInSheet,
      page: 1,
    });
    getUnlinkedProducts();
  };

  const [mainCategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const getMainCategories = async () => {
    const { data } = await ProductService.getMainCategories();
    setMainCategories(data);
  };

  const getCategories = async (e) => {
    const { data } = await ProductService.getCategories(e);
    setCategories(data);
  };

  const getSubCategories = async (e) => {
    const { data } = await ProductService.getSubCategories(e);
    setSubCategories(data);
  };

  const getBrands = async (e) => {
    const { data } = await ProductService.getSubCategoryBrands(e);
    setBrands(data);
  };

  useEffect(() => {
    getMainCategories();
  }, []);

  const handleMainCategoryChange = (e) => {
    setFiltersInSheet((prevFilters) => ({
      ...prevFilters,
      mainCategoryId: e,
      categoryId: "",
      subCategoryId: "",
      subCategoryBrandId: "",
    }));

    getCategories(e);
  };

  const handleCategoryChange = (e) => {
    setFiltersInSheet((prevFilters) => ({
      ...prevFilters,
      categoryId: e,
      subCategoryId: "",
      subCategoryBrandId: "",
    }));

    getSubCategories(e);
  };

  const handleSubCategoryChange = (e) => {
    setFiltersInSheet((prevFilters) => ({
      ...prevFilters,
      subCategoryId: e,
      subCategoryBrandId: "",
    }));

    getBrands(e);
  };

  const handleBrandChange = (e) => {
    setFiltersInSheet({ ...filtersInSheet, subCategoryBrandId: e });
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.filter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Input
            placeholder={t("settingsPage.productName")}
            value={filtersInSheet.name}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, name: e.target.value })
            }
          />
          <div className="flex flex-col gap-4">
            <Label>{t("settingsPage.mainCategory")}</Label>
            <Select
              onValueChange={(value) => {
                handleMainCategoryChange(value);
              }}
              value={filtersInSheet.mainCategoryId}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {mainCategories.map((category, index) => (
                  <SelectItem key={index} value={category.id.toString()}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("settingsPage.category")}</Label>
            <Select
              onValueChange={(value) => handleCategoryChange(value)}
              value={filtersInSheet.categoryId}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {categories.map((category, index) => (
                  <SelectItem key={index} value={category.id.toString()}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("settingsPage.subCategory")}</Label>
            <Select
              onValueChange={(value) => handleSubCategoryChange(value)}
              value={filtersInSheet.subCategoryId}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {subCategories.map((category, index) => (
                  <SelectItem key={index} value={category.id.toString()}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("settingsPage.brand")}</Label>
            <Select
              onValueChange={(value) => handleBrandChange(value)}
              value={filtersInSheet.subCategoryBrandId}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {brands.map((brand, index) => (
                  <SelectItem key={index} value={brand.id}>
                    {brand.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
