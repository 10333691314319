import { Tag } from "@egaranti/components";

import TrashIcon from "../../../assets/icons/trash.svg?react";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

const FilterTags = () => {
  const { maintenanceFilters, getMaintenancePlans, setMaintenanceFilters } =
    useAdditionalPackagesStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {maintenanceFilters.title && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {maintenanceFilters.title}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceFilters({
                ...maintenanceFilters,
                title: "",
              });
              getMaintenancePlans();
            }}
          />
        </Tag>
      )}
      {(maintenanceFilters.activated === "true" ||
        maintenanceFilters.activated === "false") && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {maintenanceFilters.activated === "true"
            ? "activated"
            : maintenanceFilters.activated === "false" && "Not activated"}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceFilters({
                ...maintenanceFilters,
                activated: null,
              });
              getMaintenancePlans();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
