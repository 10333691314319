import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { RadioGroup, RadioGroupItem } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import documentsService from "@/services/documentsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const DocumentCreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(3, t("common.validateMsg.requiredField")),
        type: z.string().min(3, t("common.validateMsg.requiredField")),
        file: z.any().refine((file) => file instanceof File, {
          message: t("common.validateMsg.requiredField"),
        }),
      }),
    ),
    defaultValues: {
      name: "",
      type: "",
      file: null,
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", data.file);

    documentsService
      .createDocument(data, formData)
      .then((res) => {
        toast({
          title: t("settingsPage.documentCreatedSuccesfully"),
          variant: "success",
        });
        form.reset();

        navigate(`/settings/documents/${res.data.id}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/documents">
          {t("settingsPage.documents")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("settingsPage.addDocument")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <h2 className="mb-5 font-medium">{t("settingsPage.documentInfo")}</h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid w-full grid-cols-2 gap-4"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("settingsPage.documentName")}</Label>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("settingsPage.document")}</Label>
                    <FormControl>
                      <Input
                        accept=".jpg, .jpeg, .png, .pdf"
                        type="file"
                        onChange={(e) =>
                          field.onChange(
                            e.target.files ? e.target.files[0] : null,
                          )
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("settingsPage.documentType")}</Label>
                    <FormControl>
                      <RadioGroup
                        className="flex py-5"
                        onValueChange={(value) => {
                          form.setValue("type", value);
                        }}
                        value={form.watch("type")}
                        {...field}
                      >
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="USER_MANUAL_DOCUMENT"
                            id="USER_MANUAL_DOCUMENT"
                          />
                          <Label htmlFor="USER_MANUAL_DOCUMENT">
                            {t("settingsPage.userManual")}
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="WARRANTY_DOCUMENT"
                            id="WARRANTY_DOCUMENT"
                          />
                          <Label htmlFor="WARRANTY_DOCUMENT">
                            {t("settingsPage.warrantyDocument")}
                          </Label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <div className="flex w-full justify-end">
          <Button disabled={loading} onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default DocumentCreatePage;
