import axios from "@/lib/axios";

class IndividualCustomerService {
  constructor() {
    this.api = axios;
  }

  async getIndividualCustomers(query) {
    const { data } = await this.api.get("/individual-customers", {
      params: query,
    });

    return data;
  }

  async getIndividualCustomer(id) {
    const { data } = await this.api.get(`/individual-customers/${id}`);

    return data;
  }

  async getIndividualCustomerProducts(id, page) {
    const { data } = await this.api.get(
      `/individual-customers/${id}/products`,
      {
        page: page,
        size: localStorage.getItem("pageSize") || 10,
      },
    );
    return data;
  }

  async deleteIndividualCustomerProduct(id, productId) {
    const { data } = await this.api.delete(
      `/individual-customers/${id}/products/${productId}`,
    );

    return data;
  }

  async remindIndividualCustomer(id, body) {
    const { data } = await this.api.post(
      `/individual-customers/${id}/remind`,
      body,
    );

    return data;
  }
}

export default new IndividualCustomerService();
