import { Button } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ClaimsIcon from "../../../assets/icons/claimsIcon.svg?react";

import useServiceRecordsStore from "@/stores/useServiceRecordsStore";

import AccordionHero from "@/components/claims/AccordionHero";
import FilterSheet from "@/components/claims/ServiceRecords/filterSheet";
import FilterTags from "@/components/claims/ServiceRecords/filterTags";
import EmptyTable from "@/components/shared/EmptyTable";
import InfoTooltip from "@/components/shared/infoTooltip";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import AddIcon from "@/assets/icons/add.svg?react";
import FilterIcon from "@/assets/icons/filter.svg?react";

const ServiceRecords = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const { claims, loading, filters, getClaims, setFilters } =
    useServiceRecordsStore();

  useEffect(() => {
    getClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getClaims();
  };

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    setFilters({ ...filters, size, page: 1 });
    getClaims();
  };

  const onClickItem = (e, id) => {
    e.stopPropagation();
    navigate(`/claims/service-record/${id}`);
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="mb-6 min-h-[78px] w-full">
        <AccordionHero
          logo={<ClaimsIcon className="mx-auto" />}
          triggerText={t("claimsPage.hero.triggerText")}
          heading1={t("claimsPage.hero.heading1")}
          title2={t("claimsPage.hero.title2")}
          title3={t("claimsPage.hero.title3")}
          title4={t("claimsPage.hero.title4")}
          title5={t("claimsPage.hero.title5")}
          title6={t("claimsPage.hero.title6")}
          desc1={t("claimsPage.hero.desc1")}
          desc2={t("claimsPage.hero.desc2")}
          desc3={t("claimsPage.hero.desc3")}
          desc4={t("claimsPage.hero.desc4")}
          desc5={t("claimsPage.hero.desc5")}
          desc6={t("claimsPage.hero.desc6")}
        />
      </div>
      <div className="flex items-center justify-end pb-4">
        <Button onClick={() => navigate("/claims/service-record/create")}>
          <AddIcon />
          {t("claimsPage.addServiceRecord")}
        </Button>
      </div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
              className="min-w-[150px] font-medium"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={4} />
            </Table>
          ) : claims.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("claimsPage.customerName")}</TableHead>
                <TableHead>{t("claimsPage.productName")}</TableHead>
                <TableHead>{t("claimsPage.creationDate")}</TableHead>
                <TableHead className="flex items-center gap-2">
                  <span>{t("claimsPage.requestNumber")}</span>
                  <InfoTooltip>{t("claimsPage.tooltipText")}</InfoTooltip>
                </TableHead>
              </TableHeader>
              <TableBody>
                {claims?.map((claim) => (
                  <TableRow
                    role="button"
                    onClick={(e) => onClickItem(e, claim.id)}
                    key={claim.id}
                  >
                    <TableCell>
                      {claim.individualCustomerFirstName}{" "}
                      {claim.individualCustomerLastName}
                    </TableCell>
                    <TableCell>{claim.individualCustomerProductName}</TableCell>
                    <TableCell>
                      <span className="flex flex-col items-start justify-center">
                        <span className="text-sm">
                          {claim.createdAt?.split(" ")[0]}
                        </span>
                        <span className="text-xs text-[#677389]">
                          {claim.createdAt?.split(" ")[1]}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell>{claim.id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("claimsPage.dataCouldNotFound")}
              desc={t("claimsPage.dataCouldNotFoundDesc")}
            />
          )}
          <div className="flex flex-col items-center">
            {filters.totalPages > 1 && (
              <Pagination
                totalPages={filters.totalPages}
                currentPage={filters.page}
                pageSize={filters.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            {!loading && (
              <PageSizeSelect
                onPageSizeChange={onPageSizeChange}
                value={filters.size}
              />
            )}
          </div>
        </div>
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </main>
  );
};

export default ServiceRecords;
