import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import applicationsService from "@/services/applicationsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const ApplicationEditPage = () => {
  const { t } = useTranslation();
  const app = useLocation().state;

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/applications">
          {t("settingsPage.applications")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.applicationDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-12">
        <div className="flex h-20 w-20 items-center justify-center">
          <img src={app.logoUrl} alt={app.name} className="h-auto w-auto" />
        </div>
        <ApplicationForm app={app} />
      </div>
    </main>
  );
};

export default ApplicationEditPage;

const ApplicationForm = ({ app }) => {
  const { t } = useTranslation();
  const [installed, setInstalled] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const schema = z.object({
    senderId: z.string(),
    apiKey: z.string().optional(),
    baseUrl: z.string().optional(),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      singularWarrantySmsContent:
        "Sevgili {{name}}, {{merchant}} markasından satın aldığınız {{product}} ürünün garanti belgesi {{brand}} - egaranti iş birliğiyle artık dijitalde.",
    },
  });

  const onDeleteApp = (e) => {
    e.preventDefault();
    applicationsService
      .uninstallApplication(app.slug)
      .then((res) => {
        navigate("/applications");
        toast({
          variant: "success",
          title: t("common.success"),
        });
      })
      .catch((err) => {
        toast({
          variant: "error",
          title: "Hata",
          description: err.response.data.errors[0].message,
        });
      });
  };

  const onSubmit = (data) => {
    const parsedData = {
      ...data,
      singularWarrantySmsContent: `${form.watch("singularWarrantySmsContent")} {{link}}`,
    };

    if (installed) {
      applicationsService
        .updateApplication(app.slug, parsedData)
        .then((res) => {
          toast({
            variant: "success",
            title: t("common.success"),
            description: "Uygulama başarıyla güncellendi",
          });
        })
        .catch((err) => {
          toast({
            variant: "error",
            title: "Hata",
            description: err.response.data.errors[0].message,
          });
        });
    } else {
      applicationsService
        .installApplication(app.slug, parsedData)
        .then((res) => {
          toast({
            variant: "success",
            title: t("common.success"),
            description: "Uygulama başarıyla kuruldu",
          });
        })
        .catch((err) => {
          toast({
            variant: "error",
            title: "Hata",
            description: err.response.data.errors[0].message,
          });
        });
    }
  };

  const getAppDetails = () => {
    applicationsService.getApplication(app.slug).then((res) => {
      form.reset(res);
      form.setValue(
        "singularWarrantySmsContent",
        res.singularWarrantySmsContent.replace(" {{link}}", ""),
      );
    });

    applicationsService.checkInstalled().then((res) => {
      setInstalled(res.data.infobip);
    });
  };

  useEffect(() => {
    getAppDetails();
  }, []);

  const tagExamples = {
    "{{name}}": "John Doe",
    "{{brand}}": "Acme Corp",
    "{{product}}": "Acme Phone 2000",
    "{{merchant}}": "Acme Store",
  };

  function highlightBraces(text) {
    return text.split(/({{[^{}]*}})/).map((part, i) => {
      if (part.startsWith("{{") && part.endsWith("}}")) {
        const tag = part.slice(2, -2); // remove the braces
        if (["merchant", "name", "product", "brand"].includes(tag)) {
          return (
            <span key={i} className="text-blue-500">
              {tagExamples[part] || part}
            </span>
          );
        }
      }
      return part;
    });
  }
  return (
    <div className="flex flex-col items-center justify-between gap-4">
      <Form {...form}>
        <form className="formBox flex max-w-5xl flex-col gap-4">
          <div className="flex flex-col gap-4 rounded-lg border p-5">
            <h2 className="col-span-2 text-lg font-medium">Ayarlar</h2>
            <FormField
              name="senderId"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <Label>senderId</Label>
                  <FormControl>
                    <Input disabled={installed} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {!installed && (
              <>
                <FormField
                  name="apiKey"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <Label>apiKey</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="baseUrl"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <Label>baseUrl</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
          </div>
          <div className="flex flex-col gap-4 rounded-lg border p-5">
            <h2 className="col-span-2 text-lg font-medium">Mesaj İçerikleri</h2>
            <p className="text-sm font-medium text-[#364153]">
              Aşağıdaki örnek SMS üzerinden{" "}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="cursor-pointer underline">
                      değişkenleri
                    </span>
                  </TooltipTrigger>
                  <TooltipContent>
                    "{`{{name}}`}, {`{{merchant}}`}, {`{{product}}`},{" "}
                    {`{{brand}}`}"
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>{" "}
              silebilir ya da değiştirebilir, metninizi düzenleyebilirsiniz.
            </p>
            <FormField
              name="singularWarrantySmsContent"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <Label>SMS İçeriği</Label>
                  <FormControl>
                    <Textarea rows={6} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Label className="mt-4">SMS Önizleme</Label>
            <div className="rounded-md bg-[#F2F5F9] p-4 font-medium leading-8 text-[#364153]">
              {highlightBraces(form.watch("singularWarrantySmsContent"))}{" "}
              <span className="text-blue-500">
                https://egaranti.com/ornek-link
              </span>
            </div>
          </div>
          <footer className="mt-4 flex w-full items-center justify-end gap-4">
            {installed && (
              <Button
                className="bg-red-600 text-[#F9FAFC] hover:bg-red-700"
                onClick={onDeleteApp}
              >
                {t("settingsPage.uninstallApplication")}
              </Button>
            )}
            <Button onClick={form.handleSubmit(onSubmit)}>
              {t("common.save")}
            </Button>
          </footer>
        </form>
      </Form>
    </div>
  );
};
