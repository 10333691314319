import {
  Label,
  RadioGroup,
  RadioGroupItem,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import addressService from "@/services/addressService";
import paymentService from "@/services/paymentService";

import Footer from "@/components/layout/mainFooter";
import AddAddressDialog from "@/components/settings/payment/addAddressDialog";
import CreditCardForm from "@/components/settings/payment/creditCardForm";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import BluePlusIcon from "@/assets/icons/blue-plus.svg?react";

const AddCreditCard = () => {
  const { t } = useTranslation();

  const [address, setAddress] = useState(null);
  const [onOpenAddressDialog, setOpenAddressDialog] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get("redirect");
  const method = searchParams.get("method");

  const { toast } = useToast();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      cardHolderName: "",
      cardNumber: "",
      expireDate: "",
      cardAlias: "",
      addressId: "",
      storeCardAfterSuccessPayment: false,
    },
  });

  useEffect(() => {
    addressService.getAddresses().then((res) => setAddress(res.data));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      addressService.getAddresses().then((res) => setAddress(res.data));
    }, 1500);
  }, [onOpenAddressDialog]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      expireMonth: data.expireDate.split("/")[0],
      expireYear: data.expireDate.split("/")[1],
      cardAlias: data.cardHolderName,
      cardNumber: data.cardNumber.replace(/\s/g, ""),
      cardUsagePurpose: method ? method : "PAY_AS_GO",
      addressId: data.addressId,
    };

    paymentService
      .addStoredCard(payload)
      .then(() => {
        toast({
          title: t("payment.addCreditCard.toastSuccess"),
          variant: "success",
        });
        navigate(redirect ? redirect : "/settings/payment");
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex w-full flex-col overflow-hidden bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem link="/settings/payment">
          {t("payment.addCreditCard.breadcrumbs.text1")}
        </BreadcrumbItem>
        <BreadcrumbItem active link="/settings/payment">
          {t("payment.addCreditCard.breadcrumbs.text2")}
        </BreadcrumbItem>
      </Breadcrumb>
      <main className="flex min-h-screen flex-col gap-6 py-16">
        {method !== "PAY_AS_GO" && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="formBox mx-auto flex w-full max-w-[826px] justify-center">
                  <h2 className="flex items-center gap-2 text-2xl font-medium text-[#677389]">
                    <span>{t("payment.addCreditCard.title")}</span>
                    <GiftIcon />
                  </h2>
                </div>
              </TooltipTrigger>
              <TooltipContent className="max-w-[300px] bg-[#111729] text-center text-sm text-white">
                {t("payment.addCreditCard.tooltipText")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <h3 className="mx-auto mt-10 max-w-lg text-center text-2xl font-medium text-[#364153]">
          {t("payment.addCreditCard.subtitle")}
        </h3>

        <div className="formBox mx-auto w-full max-w-[826px]">
          <div className="flex w-full flex-col gap-6">
            <h2 className="text-xl font-semibold text-[#364153]">
              {t("payment.checkout.addressTitle")}{" "}
              <p className="text-sm font-normal text-gray-500">
                {t("payment.checkout.addressDescription")}
              </p>
            </h2>

            <div className="flex flex-col">
              <Label>{t("payment.checkout.addressSubtitle")}</Label>
              <RadioGroup
                onValueChange={(e) => form.setValue("addressId", e)}
                value={form.watch("addressId")}
                className="mt-4 rounded-lg shadow"
              >
                {address?.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center space-x-3 border-b border-b-[#F2F5F9] px-4"
                  >
                    <RadioGroupItem value={item.id} id={item.id} />
                    <Label
                      className="w-full cursor-pointer py-3 text-sm font-medium text-[#344054]"
                      htmlFor={item.id}
                    >
                      {item.title}
                    </Label>
                  </div>
                ))}
                <button
                  onClick={() => setOpenAddressDialog(true)}
                  className="flex w-full items-center gap-2 rounded-lg rounded-t-none px-4 py-3 text-sm font-medium text-[#0049E6]"
                >
                  <BluePlusIcon />
                  {t("payment.checkout.addAddressBtn")}
                </button>
              </RadioGroup>
            </div>
          </div>
        </div>

        {address?.length > 0 || form.watch("addressId") ? (
          <div className="formBox mx-auto w-full max-w-[826px]">
            <CreditCardForm
              form={form}
              onSubmit={onSubmit}
              isCvcRequired={true}
              isStoreCardAfterSuccessPaymentRequired={false}
            />
          </div>
        ) : null}
      </main>
      <AddAddressDialog
        open={onOpenAddressDialog}
        onOpenChange={setOpenAddressDialog}
      />
      <Footer />
    </div>
  );
};

export default AddCreditCard;

const GiftIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9987 7.00016V25.6668M13.9987 7.00016H9.8737C9.26591 7.00016 8.68302 6.75433 8.25325 6.31675C7.82347 5.87916 7.58203 5.28567 7.58203 4.66683C7.58203 4.04799 7.82347 3.4545 8.25325 3.01691C8.68302 2.57933 9.26591 2.3335 9.8737 2.3335C13.082 2.3335 13.9987 7.00016 13.9987 7.00016ZM13.9987 7.00016H18.1237C18.7315 7.00016 19.3144 6.75433 19.7442 6.31675C20.1739 5.87916 20.4154 5.28567 20.4154 4.66683C20.4154 4.04799 20.1739 3.4545 19.7442 3.01691C19.3144 2.57933 18.7315 2.3335 18.1237 2.3335C14.9154 2.3335 13.9987 7.00016 13.9987 7.00016ZM23.332 12.8335V21.9335C23.332 23.2403 23.332 23.8937 23.0777 24.3928C22.854 24.8319 22.4971 25.1888 22.058 25.4125C21.5589 25.6668 20.9055 25.6668 19.5987 25.6668L8.3987 25.6668C7.09191 25.6668 6.43851 25.6668 5.93939 25.4125C5.50034 25.1888 5.14339 24.8319 4.91968 24.3928C4.66536 23.8937 4.66536 23.2403 4.66536 21.9335V12.8335M2.33203 8.86683L2.33203 10.9668C2.33203 11.6202 2.33203 11.9469 2.45919 12.1965C2.57104 12.416 2.74952 12.5945 2.96904 12.7063C3.21861 12.8335 3.5453 12.8335 4.1987 12.8335L23.7987 12.8335C24.4521 12.8335 24.7788 12.8335 25.0284 12.7063C25.2479 12.5945 25.4264 12.416 25.5382 12.1965C25.6654 11.9469 25.6654 11.6202 25.6654 10.9668V8.86683C25.6654 8.21344 25.6654 7.88674 25.5382 7.63717C25.4264 7.41765 25.2479 7.23918 25.0284 7.12732C24.7788 7.00016 24.4521 7.00016 23.7987 7.00016L4.1987 7.00016C3.5453 7.00016 3.21861 7.00016 2.96904 7.12732C2.74952 7.23917 2.57104 7.41765 2.45919 7.63717C2.33203 7.88674 2.33203 8.21344 2.33203 8.86683Z"
        stroke="#4A5567"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
