import axios from "@/lib/axios";

class ProductService {
  constructor() {
    this.api = axios;
  }
  async getProducts(query) {
    const { data } = await this.api.get("/merchant-products", {
      params: query,
    });

    return data;
  }
  async getProduct(id) {
    const { data } = await this.api.get(`/merchant-products/${id}`);

    return data;
  }

  // get documents by product id
  async getDocuments(id) {
    const { data } = await this.api.get(`/merchant-products/${id}/documents`);

    return data?.merchantProductDocuments;
  }

  // upload document for product by product id
  async uploadDocument(id, payload, formData) {
    const { data } = await this.api.put(
      `/merchant-products/${id}/upload-document`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          name: payload.name,
          type: payload.type,
        },
      },
    );

    return data;
  }

  // select document for product by product id
  async selectDocument(id, documentId) {
    const { data } = await this.api.put(
      `/merchant-products/${id}/add-document`,
      null,
      {
        params: {
          documentId,
        },
      },
    );

    return data;
  }

  async removeDocument(id) {
    const { data } = await this.api.put(
      `/merchant-products/${id}/unlinked-user-manual-document`,
    );

    return data;
  }

  async getDocumentList(query) {
    const { data } = await this.api.get(`/merchant-product-documents/name`, {
      params: {
        ...query,
      },
    });

    return data;
  }

  async exportProducts(query) {
    const res = await this.api.get("/merchant-products/csv", {
      params: query,
    });

    return res;
  }

  async importProducts(subCategoryBrandId, file) {
    const res = await this.api.post(
      `/merchant-products/csv?subCategoryBrandId=${subCategoryBrandId}`,
      file,
    );

    return res;
  }

  async updateProduct(product) {
    const data = await this.api.put(
      `/merchant-products/${product.id}`,
      product,
    );

    return data;
  }

  async deleteProductImage(id) {
    const data = await this.api.delete(
      `/merchant-products/image?merchantProductId=${id}`,
    );

    return data;
  }

  async postProductImage(id, image) {
    const data = await this.api.post(
      `/merchant-products/image?merchantProductId=${id}`,
      image,
    );

    return data;
  }

  async getMainCategories() {
    const data = await this.api.get(`/main-categories`, {});

    return data;
  }

  async getCategories(id) {
    const data = await this.api.get(`/categories`, {
      params: { mainCategoryId: id },
    });

    return data;
  }

  async getSubCategories(id) {
    const data = await this.api.get(`/sub-categories`, {
      params: { categoryId: id },
    });

    return data;
  }

  async getSubCategoryBrands(id) {
    const data = await this.api.get(`/sub-category-brands`, {
      params: { subCategoryId: id },
    });

    return data;
  }

  async createProduct(product) {
    const data = await this.api.post(`/merchant-products`, product);

    return data;
  }

  async sendWarrantyIndividual(product) {
    const data = await this.api.post(`/individual-customers/warranty`, product);

    return data;
  }

  async sendWarrantyCorporate(product) {
    const data = await this.api.post(`/corporate-customers/warranty`, product);

    return data;
  }
}

export default new ProductService();
