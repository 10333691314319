/* eslint-disable react/prop-types */
import ExclamationMarkIcon from "../../assets/icons/exclamationMark.svg?react";

import { cn } from "@/lib/utils";

const EmptyTable = ({ title, desc, actionContent, className, ...props }) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-4 p-5 py-8",
        className,
      )}
      {...props}
    >
      <ExclamationMarkIcon />
      <div className="text-base font-semibold text-[#111729]">{title}</div>
      <div className="text-center text-base text-[#677389]">{desc}</div>
      {actionContent && <div className="mt-4">{actionContent}</div>}
    </div>
  );
};

export default EmptyTable;
