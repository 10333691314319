import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

/* eslint-disable react/prop-types */
const DeleteCardDialog = ({ onOpenChange, open, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent className="max-w-[388px] gap-0 px-4">
        <div className="flex w-full flex-col items-center justify-center">
          <ThrashIcon />
          <span className="mb-1 mt-8 text-center text-xl font-semibold text-[#111729]">
            {t("payment.myCards.deleteCardDialog.title")}
          </span>
          <span className="text-center text-base font-medium text-[#677389]">
            {t("payment.myCards.deleteCardDialog.subtitle")}
          </span>
        </div>
        <AlertDialogFooter className="m-0 mt-6">
          <AlertDialogCancel className="w-full">
            {t("payment.myCards.deleteCardDialog.btnTextCancel")}
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button className="w-full" onClick={() => onSubmit()}>
              {t("payment.myCards.deleteCardDialog.btnTextConfirm")}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const ThrashIcon = () => {
  return (
    <svg
      width="72"
      height="73"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4.5" width="64" height="64" rx="32" fill="#FEF3F2" />
      <rect
        x="4"
        y="4.5"
        width="64"
        height="64"
        rx="32"
        stroke="#FEE4E2"
        strokeWidth="8"
      />
      <path
        d="M32 24.5H40M24 28.5H48M45.3333 28.5L44.3983 42.5257C44.258 44.63 44.1879 45.6822 43.7334 46.48C43.3332 47.1824 42.7297 47.747 42.0022 48.0996C41.176 48.5 40.1215 48.5 38.0125 48.5H33.9875C31.8785 48.5 30.824 48.5 29.9978 48.0996C29.2703 47.747 28.6668 47.1824 28.2666 46.48C27.8121 45.6822 27.742 44.63 27.6017 42.5257L26.6667 28.5"
        stroke="#F04438"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteCardDialog;
