import React from "react";

const MainFooter = () => {
  return (
    <footer className="flex items-center justify-between border-t bg-[#F9FAFC] px-5 py-4">
      <span className="text-sm font-medium text-[#677389]">
        ©2024 <span className="font-semibold">egaranti</span>
      </span>
    </footer>
  );
};

export default MainFooter;
