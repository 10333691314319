import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import sellerService from "@/services/sellerService";

import SelectMerchant from "@/components/sellers/onboard/selectMerchant";

import { OTPInput } from "input-otp";
import * as z from "zod";

import { cn } from "@/lib/utils";

const OnboardSeller = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { toast } = useToast();
  const [openVerificationDialog, setOpenVerificationDialog] =
    React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [remainingTime, setRemainingTime] = React.useState(180); // remaining time for otp verification dialog start with 180 seconds and decrease every second
  const [smsId, setSmsId] = React.useState("");
  const form = useForm({
    defaultValues: {
      title: "",
      email: "",
      merchantCode: "",
      phone: "",
      countryCode: "TR",
    },
    resolver: zodResolver(
      z.object({
        title: z.string().min(1, t("seller.onboardSeller.form.title.error")),
        email: z.string().email(t("seller.onboardSeller.form.email.error")),
        merchantCode: z
          .string()
          .min(1, t("seller.onboardSeller.form.merchantCode.error")),
        phone: z.string().min(1, t("seller.onboardSeller.form.phone.error")),
        countryCode: z
          .string()
          .min(1, t("seller.onboardSeller.form.countryCode.error")),
      }),
    ),
  });

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      partnerCode: searchParams.get("partnerCode"),
      conversationId: searchParams.get("conversationId"),
    };
    sellerService
      .postSeller(payload)
      .then((res) => {
        setRemainingTime(180);
        setOtp("");
        setOpenVerificationDialog(true);
        setSmsId(res.id);
      })
      .catch((err) => {
        toast({
          title: err.response.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const onVeriftOtp = () => {
    sellerService
      .verifySeller(smsId, otp)
      .then(() => {
        toast({
          title: t("sellers.onboardSeller.verifySuccess"),
          status: "success",
        });
      })
      .catch((err) => {
        console.log(err.response.data?.errors[0]?.message);
        toast({
          title: err.response.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC] p-5">
      <div className="formBox mx-auto my-40 mb-5 max-w-5xl">
        <h2 className="mb-4 text-lg font-medium text-[#111729]">
          {t("sellers.onboardSeller.title")}
        </h2>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="grid w-full grid-cols-2 gap-4"
          >
            <FormField
              control={form.control}
              name="merchantCode"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-2">
                  <Label>
                    {t("sellers.onboardSeller.form.merchantCode.label")}
                  </Label>
                  <FormControl>
                    <SelectMerchant
                      value={field.value}
                      setValue={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("sellers.onboardSeller.form.title.label")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("common.email")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-2">
              <FormField
                control={form.control}
                name="countryCode"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.country")}</Label>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t("common.corporate")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="TR">+90</SelectItem>
                        <SelectItem value="US">+1</SelectItem>
                        <SelectItem value="UZ">+998</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("common.phone")}</Label>
                    <FormControl>
                      <Input type="tel" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
      <div className="mx-auto flex w-full max-w-5xl items-center justify-between">
        <span className="text-sm font-medium text-[#364153]">
          {t("sellers.onboardSeller.form.info")}
        </span>
        <Button
          className="mt-4"
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          {t("common.save")}
        </Button>
      </div>
      <AlertDialog
        open={openVerificationDialog}
        onOpenChange={setOpenVerificationDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-md font-medium">
              {t("sellers.onboardSeller.dialog.title")}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <OTPInput
            value={otp}
            onChange={(otp) => setOtp(otp)}
            maxLength={6}
            containerClassName="flex justify-center gap-3 mt-5"
            render={({ slots }) => (
              <div className="flex gap-3">
                {slots.slice(0, 6).map((slot, idx) => (
                  <Slot key={idx} {...slot} />
                ))}
              </div>
            )}
          />
          {/* remaining time start with 180 seconds */}
          <div className="mt-5 flex justify-center">
            <span className="text-sm font-medium text-[#364153]">
              {t("sellers.onboardSeller.dialog.remainingTime")}
            </span>
            <span className="ml-1 text-sm font-medium text-blue-600">
              {Math.floor(remainingTime / 60)}:
              {remainingTime % 60 < 10
                ? `0${remainingTime % 60}`
                : remainingTime % 60}
            </span>
          </div>

          <AlertDialogFooter>
            <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
            <AlertDialogAction
              onClick={onVeriftOtp}
              disabled={otp.length !== 6}
            >
              {t("common.confirm")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </main>
  );
};

export default OnboardSeller;

const Slot = (props) => {
  return (
    <div
      className={cn(
        "flex h-10 w-10 items-center justify-center rounded-md border border-[#D3DBE9] font-medium text-[#364153]",
        props.isActive && "border-[#0049E6] text-[#111729]",
      )}
      {...props}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  );
};
