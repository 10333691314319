import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Switch } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import { Tabs } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { FileLink } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import additionaPackagesService from "@/services/additionaPackagesService";

import PacketOptions from "@/components/additionalPackages/maintenance/edit/packetOptions";
import ProductList from "@/components/additionalPackages/maintenance/edit/productList";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const EditMaintenance = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(1);

  const form = useForm({
    resolver: zodResolver(
      z.object({
        title: z.string().min(3, t("common.validateMsg.requiredField")),
        description: z.string().min(3, t("common.validateMsg.requiredField")),
        activated: z.boolean(),
        file: z.instanceof(File).optional(),
        expiredTime: z.string().min(1, t("common.validateMsg.requiredField")),
      }),
    ),
  });

  useEffect(() => {
    additionaPackagesService
      .getMaintenancePlan(id)
      .then((res) => {
        form.setValue("title", res.data.title);
        form.setValue("description", res.data.description);
        form.setValue("expiredTime", res.data.expiredTime.toString());
        form.setValue("logoUrl", res.data.logoUrl);
        form.setValue("activated", res.data.activated);
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("multipartFile", data.file);

    additionaPackagesService
      .updateMaintenancePlan(id, data, formData)
      .then(() => {
        toast({
          title: t("additionalServicesPage.planUpdatedSuccessfully"),
          variant: "success",
        });
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/additional-packages/maintenance">
          {t("additionalServicesPage.additionalPackages")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("additionalServicesPage.editMaintenanceRepairPackage")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <h2 className="mb-5 font-medium">
            {t("additionalServicesPage.packetInfo")}
          </h2>
          <Form {...form}>
            <form className="grid w-full grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("additionalServicesPage.title")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {!form.watch("logoUrl") ? (
                <FormField
                  control={form.control}
                  name="file"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("additionalServicesPage.image")}</Label>
                      <FormControl>
                        <Input
                          accept=".jpg, .jpeg, .png, .pdf"
                          type="file"
                          onChange={(e) =>
                            field.onChange(
                              e.target.files ? e.target.files[0] : null,
                            )
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <div className="flex flex-col gap-2">
                  <Label>{t("additionalServicesPage.packetLogo")}</Label>
                  <FileLink
                    onDeleted={() => form.setValue("logoUrl", null)}
                    url={form.watch("logoUrl")}
                  />
                </div>
              )}
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("additionalServicesPage.description")}</Label>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expiredTime"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <Label>{t("additionalServicesPage.validityPeriod")}</Label>
                    <FormControl>
                      <Input type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="activated"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <Label>{t("additionalServicesPage.active")}</Label>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <div className="flex w-full justify-end">
          <Button onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </div>
        <Tabs
          tabs={[
            { id: 1, title: t("additionalServicesPage.packetOptions") },
            { id: 2, title: t("additionalServicesPage.selectedProducts") },
          ]}
          selectedTabId={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
        />
        <div className="container mx-auto flex flex-col items-center justify-center gap-6">
          {activeTab === 1 && <PacketOptions />}
          {activeTab === 2 && <ProductList />}
        </div>
      </div>
    </main>
  );
};

export default EditMaintenance;
