import ProductFromIntegrationsService from "@/services/productFromIntegrationsService";

import { create } from "zustand";

const useProductsFromIntegrationsStore = create((set) => ({
  loading: false,
  products: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    totalPages: 0,
    name: "",
    productCode: "",
    source: "",
    brand: "",
  },
  getProducts: async () => {
    set({ loading: true });
    const data = await ProductFromIntegrationsService.getProducts(
      useProductsFromIntegrationsStore.getState().filters,
    );
    set({
      products: data.content,
      loading: false,
      filters: {
        ...useProductsFromIntegrationsStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
        size: data.size,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useProductsFromIntegrationsStore;
