import { useState } from "react";

import EmptyView from "@/components/sellers/EmptyView";
import TableView from "@/components/sellers/TableView";

const Sellers = () => {
  const infoPageShown = localStorage.getItem("infoPageShown");

  console.log(infoPageShown);

  const [showTableView, setShowTableView] = useState(false);

  return (
    <div className="flex min-h-full w-full flex-col bg-[#F9FAFC]">
      {infoPageShown || showTableView ? (
        <TableView />
      ) : (
        <EmptyView setShowTableView={setShowTableView} />
      )}
    </div>
  );
};

export default Sellers;
