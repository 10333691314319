/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { RadioGroup, RadioGroupItem } from "@egaranti/components";
import { FormDescription } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ProductService from "@/services/productService";

import * as z from "zod";

const SendWarrantySheet = ({ onOpenChange, open }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState("individual");
  const { toast } = useToast();

  const schemaIndividual = z.object({
    merchantProductId: z.string(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email({ message: t("common.validEmail") }),
    countryCode: z.string().min(1),
    phone: z.string().min(1),
  });

  const schemaCorporate = z.object({
    merchantProductId: z.string(),
    companyName: z.string().refine((data) => data.trim() !== "", {
      message: t("common.nonEmpty"),
    }),
    vkn: z.string().refine((data) => data.trim() !== "", {
      message: t("common.nonEmpty"),
    }),
    email: z.string().email({ message: t("common.validEmail") }),
  });

  const form = useForm({
    resolver: zodResolver(
      type === "individual" ? schemaIndividual : schemaCorporate,
    ),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "TR",
      phone: "",
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    data.merchantProductId = searchParams.get("id");

    if (type === "individual") {
      ProductService.sendWarrantyIndividual(data)
        .then(() => {
          onOpenChange(false);
          form.reset();
          toast({
            title: t("productsPage.warrantySuccess"),
            variant: "success",
          });
        })
        .catch((err) => {
          toast({
            title: err.response?.data?.errors[0]?.message,
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (type === "corporate") {
      ProductService.sendWarrantyCorporate(data)
        .then(() => {
          onOpenChange(false);
          form.reset();
          toast({
            title: t("productsPage.warrantySuccess"),
            variant: "success",
          });
        })
        .catch((err) => {
          toast({
            title: err.response?.data?.errors[0]?.message,
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("productsPage.sendWarranty")}</SheetTitle>
        </SheetHeader>
        <RadioGroup
          className="py-5"
          onValueChange={(value) => setType(value)}
          defaultValue={type}
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="individual" id="individual" />
            <Label htmlFor="individual">{t("common.individual")}</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="corporate" id="corporate" />
            <Label htmlFor="corporate">{t("common.corporate")}</Label>
          </div>
        </RadioGroup>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            {type === "individual" && (
              <>
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.firstName")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.lastName")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="countryCode"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("common.country")}</Label>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={t("common.corporate")}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="TR">+90</SelectItem>
                            <SelectItem value="US">+1</SelectItem>
                            <SelectItem value="UZ">+998</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("common.phone")}</Label>
                        <FormControl>
                          <Input type="tel" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.email")}</Label>
                      <FormControl>
                        <Input type="email" {...field} />
                      </FormControl>
                      <FormDescription className="flex items-center gap-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0003 13.3334V10.0001M10.0003 6.66675H10.0087M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
                            stroke="#F79009"
                            stroke-width="1.5625"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        {t("productsPage.warrantyEmailDescription")}
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            {type === "corporate" && (
              <>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.companyName")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="vkn"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.taxIdentificationNumber")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.email")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
          </form>
        </Form>
        <SheetFooter className="mt-4">
          <Button
            disabled={loading}
            onClick={() => {
              onSubmit(form.getValues());
            }}
          >
            {t("common.save")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SendWarrantySheet;
