import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import employeesService from "@/services/employeesService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const CreateEmployee = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [items, setItems] = useState([
    {
      id: 1,
      title: t("settingsPage.products"),
      roles: [],
      desc1: t("settingsPage.productsDesc1"),
      desc2: t("settingsPage.productsDesc2"),
    },
    {
      id: 2,
      title: t("settingsPage.clients"),
      roles: [],
      desc1: t("settingsPage.clientsDesc1"),
      desc2: t("settingsPage.clientsDesc2"),
    },
    {
      id: 3,
      title: t("settingsPage.additionalServices"),
      roles: [],
      desc1: t("settingsPage.additionalServicesDesc1"),
      desc2: t("settingsPage.additionalServicesDesc2"),
    },
    {
      id: 4,
      title: t("settingsPage.claims"),
      roles: [],
      desc1: t("settingsPage.claimsDesc1"),
      desc2: t("settingsPage.claimsDesc2"),
    },
    {
      id: 5,
      title: t("settingsPage.applications"),
      roles: [],
      desc1: t("settingsPage.applicationsDesc1"),
      desc2: t("settingsPage.applicationsDesc2"),
    },
    {
      id: 7,
      title: t("settingsPage.documents"),
      roles: [],
      desc1: t("settingsPage.documentsDesc1"),
      desc2: t("settingsPage.documentsDesc2"),
    },
    {
      id: 6,
      title: t("settingsPage.employees"),
      roles: [],
      desc1: t("settingsPage.employeesDesc1"),
      desc2: t("settingsPage.employeesDesc2"),
    },
  ]);
  const [merchantId, setMerchantId] = useState(null);

  const { toast } = useToast();

  useEffect(() => {
    employeesService.getMerchantUserById(id).then((res) => {
      form.setValue("firstName", res.firstName);
      form.setValue("lastName", res.lastName);
      form.setValue("email", res.email);
      form.setValue("phone", res.phone);
      setMerchantId(res.id);

      setItems([
        {
          id: 1,
          title: t("settingsPage.products"),
          roles: res.roles.PRODUCTS,
          desc1: t("settingsPage.productsDesc1"),
          desc2: t("settingsPage.productsDesc2"),
        },
        {
          id: 2,
          title: t("settingsPage.clients"),
          roles: res.roles.CLIENTS,
          desc1: t("settingsPage.clientsDesc1"),
          desc2: t("settingsPage.clientsDesc2"),
        },
        {
          id: 3,
          title: t("settingsPage.additionalServices"),
          roles: res.roles.ACTIVATIONS,
          desc1: t("settingsPage.additionalServicesDesc1"),
          desc2: t("settingsPage.additionalServicesDesc2"),
        },
        {
          id: 4,
          title: t("settingsPage.claims"),
          roles: res.roles.CLAIMS,
          desc1: t("settingsPage.claimsDesc1"),
          desc2: t("settingsPage.claimsDesc2"),
        },
        {
          id: 5,
          title: t("settingsPage.applications"),
          roles: res.roles.APPS,
          desc1: t("settingsPage.applicationsDesc1"),
          desc2: t("settingsPage.applicationsDesc2"),
        },
        {
          id: 6,
          title: t("settingsPage.documents"),
          roles: res.roles.DOCUMENTS,
          desc1: t("settingsPage.documentsDesc1"),
          desc2: t("settingsPage.documentsDesc2"),
        },
        {
          id: 7,
          title: t("settingsPage.employees"),
          roles: res.roles.PERSONALS,
          desc1: t("settingsPage.employeesDesc1"),
          desc2: t("settingsPage.employeesDesc2"),
        },
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    mode: "onBlur",
    resolver: zodResolver(
      z.object({
        firstName: z.string().nonempty({ message: t("common.nonEmpty") }),
        lastName: z.string().nonempty({ message: t("common.nonEmpty") }),
      }),
    ),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = (data) => {
    const bodyObj1 = {
      merchantUserId: merchantId,
      ...data,
    };

    const bodyObj2 = {
      merchantUserId: merchantId,
      roles: {
        PRODUCTS: items[0].roles,
        CLIENTS: items[1].roles,
        ACTIVATIONS: items[2].roles,
        CLAIMS: items[3].roles,
        APPS: items[4].roles,
        DOCUMENTS: items[5].roles,
        PERSONALS: items[6].roles,
      },
    };

    employeesService
      .updateMerchantUser({ bodyObj1, bodyObj2 })
      .then((res) => {
        if (!Array.isArray(res) || res.some((r) => r.status !== 200)) {
          toast({
            title: t("settingsPage.employeeUpdateError"),
            variant: "error",
          });
        } else {
          toast({
            title: t("settingsPage.employeeUpdatedSuccessfully"),
            variant: "success",
          });
        }
      })
      .catch((err) => {
        toast({
          title:
            err.response?.data?.errors?.[0]?.message ||
            t("settingsPage.employeeUpdateError"),
          variant: "error",
        });
      });
  };

  const allRolesSelected =
    items[0]?.roles?.length === 2 &&
    items[1]?.roles?.length === 2 &&
    items[2]?.roles?.length === 2 &&
    items[3]?.roles?.length === 2 &&
    items[4]?.roles?.length === 2 &&
    items[5]?.roles?.length === 2 &&
    items[6]?.roles?.length === 2;

  const noneIsSelected =
    items[0]?.roles?.length === 0 &&
    items[1]?.roles?.length === 0 &&
    items[2]?.roles?.length === 0 &&
    items[3]?.roles?.length === 0 &&
    items[4]?.roles?.length === 0 &&
    items[5]?.roles?.length === 0 &&
    items[6]?.roles?.length === 0;

  return (
    <main className="flex min-h-screen flex-col gap-5 bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/employees">
          {t("settingsPage.employees")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("settingsPage.editEmployee")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto flex flex-col items-center justify-center gap-6">
        <div className="formBox flex-col gap-4">
          <h2 className="mb-5 font-medium">{t("settingsPage.employeeInfo")}</h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid w-full grid-cols-1 gap-4 md:grid-cols-2"
            >
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.firstName")}</Label>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.lastName")}</Label>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.phone")}</Label>
                    <FormControl>
                      <Input disabled placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.email")}</Label>
                    <FormControl>
                      <Input disabled placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <div className="flex justify-between">
            <h2 className="mb-5 font-medium">
              {t("settingsPage.accessPermissions")}
            </h2>
            <div className="flex items-center gap-3">
              <Checkbox
                id="t"
                checked={allRolesSelected}
                onClick={() => {
                  if (noneIsSelected) {
                    items.map((item) => {
                      item.roles.push("VIEW");
                      item.roles.push("EDIT");
                    });
                  } else {
                    items.map((item) => {
                      item.roles = [];
                    });
                  }
                  setItems([...items]);
                }}
              />
              <label htmlFor="t">{t("settingsPage.allPermissions")}</label>
            </div>
          </div>
          <div className="flex flex-col">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex flex-col gap-5 border-b py-5 last:border-0"
              >
                <h2 className="font-medium text-[#111729]">{item.title}</h2>
                <div className="flex items-center justify-between gap-12">
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id={`${item.id}-g`}
                        checked={item.roles?.includes("VIEW")}
                        onClick={() => {
                          if (item.roles?.includes("VIEW")) {
                            item.roles = item.roles.filter(
                              (role) => role !== "VIEW",
                            );

                            if (item.roles?.includes("EDIT")) {
                              item.roles = item.roles.filter(
                                (role) => role !== "EDIT",
                              );
                            }
                          } else {
                            item.roles.push("VIEW");
                          }
                          setItems([...items]);
                        }}
                      />
                      <Label
                        className="cursor-pointer"
                        htmlFor={`${item.id}-g`}
                      >
                        {t("settingsPage.viewing")}
                      </Label>
                    </div>
                    <p className="text-sm text-gray-500">{item.desc1}</p>
                  </div>
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id={`${item.id}-d`}
                        checked={item.roles?.includes("EDIT")}
                        onClick={() => {
                          if (item.roles?.includes("EDIT")) {
                            item.roles = item.roles.filter(
                              (role) => role !== "EDIT",
                            );
                          } else {
                            item.roles.push("EDIT");

                            if (!item.roles?.includes("VIEW")) {
                              item.roles.push("VIEW");
                            }
                          }
                          setItems([...items]);
                        }}
                      />
                      <Label
                        htmlFor={`${item.id}-d`}
                        className="cursor-pointer"
                      >
                        {t("settingsPage.editing")}
                      </Label>
                    </div>
                    <p className="text-sm text-gray-500">{item.desc2}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button className="mb-20 ml-auto" onClick={form.handleSubmit(onSubmit)}>
          {t("common.save")}
        </Button>
      </div>
    </main>
  );
};

export default CreateEmployee;
