import productActivationService from "@/services/productActivationService";

import { create } from "zustand";

const useProductActivationsStore = create((set) => ({
  documents: [],
  loading: false,
  linkedProducts: {
    data: [],
    filter: {
      page: 1,
      size: localStorage.getItem("pageSize") || 10,
      mainCategoryId: null,
      categoryId: null,
      subCategoryId: null,
      subCategoryBrandId: null,
      name: "",
    },
  },
  unlinkedProducts: {
    data: [],
    filter: {
      page: 1,
      size: localStorage.getItem("pageSize") || 10,
      mainCategoryId: null,
      categoryId: null,
      subCategoryId: null,
      subCategoryBrandId: null,
      name: "",
    },
  },
  getLinkedProducts: async (productRegistrationActivationId) => {
    set({ loading: true });
    const data = await productActivationService.getLinkedProducts(
      productRegistrationActivationId,
      useProductActivationsStore.getState().linkedProducts.filter,
    );

    set({
      linkedProducts: {
        data: data.content,
        filter: {
          ...useProductActivationsStore.getState().linkedProducts.filter,
          totalPages: data.totalPages,
          page: data.page,
        },
      },
      loading: false,
    });
  },
  getUnlinkedProducts: async () => {
    set({ loading: true });
    const data = await productActivationService.getUnlinkedProducts(
      useProductActivationsStore.getState().unlinkedProducts.filter,
    );

    set({
      unlinkedProducts: {
        data: data.content,
        filter: {
          ...useProductActivationsStore.getState().unlinkedProducts.filter,
          totalPages: data.totalPages,
          page: data.page,
        },
      },
      loading: false,
    });
  },
  setLinkedProductsFilter: (filter) => {
    set({
      linkedProducts: {
        ...useProductActivationsStore.getState().linkedProducts,
        filter,
      },
    });
  },
  setUnlinkedProductsFilter: (filter) => {
    set({
      unlinkedProducts: {
        ...useProductActivationsStore.getState().unlinkedProducts,
        filter,
      },
    });
  },
}));

export default useProductActivationsStore;
