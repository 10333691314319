import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import employeesService from "@/services/employeesService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const CreateEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [items, setItems] = useState([
    {
      id: 1,
      title: t("settingsPage.products"),
      roles: [],
      desc1: t("settingsPage.productsDesc1"),
      desc2: t("settingsPage.productsDesc2"),
    },
    {
      id: 2,
      title: t("settingsPage.clients"),
      roles: [],
      desc1: t("settingsPage.clientsDesc1"),
      desc2: t("settingsPage.clientsDesc2"),
    },
    {
      id: 3,
      title: t("settingsPage.additionalServices"),
      roles: [],
      desc1: t("settingsPage.additionalServicesDesc1"),
      desc2: t("settingsPage.additionalServicesDesc2"),
    },
    {
      id: 4,
      title: t("settingsPage.claims"),
      roles: [],
      desc1: t("settingsPage.claimsDesc1"),
      desc2: t("settingsPage.claimsDesc2"),
    },
    {
      id: 5,
      title: t("settingsPage.applications"),
      roles: [],
      desc1: t("settingsPage.applicationsDesc1"),
      desc2: t("settingsPage.applicationsDesc2"),
    },
    {
      id: 6,
      title: t("settingsPage.documents"),
      roles: [],
      desc1: t("settingsPage.documentsDesc1"),
      desc2: t("settingsPage.documentsDesc2"),
    },
    {
      id: 7,
      title: t("settingsPage.employees"),
      roles: [],
      desc1: t("settingsPage.employeesDesc1"),
      desc2: t("settingsPage.employeesDesc2"),
    },
  ]);

  const form = useForm({
    mode: "onBlur",
    resolver: zodResolver(
      z.object({
        firstName: z.string().nonempty({ message: t("common.nonEmpty") }),
        lastName: z.string().nonempty({ message: t("common.nonEmpty") }),
        email: z.string().email({ message: t("common.validEmail") }),
        password: z
          .string()
          .min(8, { message: t("common.validateMsg.passwordMin") })
          .max(50, {
            message: t("common.validateMsg.passwordMax"),
          }),
        passwordConfirm: z
          .string({ message: t("common.nonEmpty") })
          .min(8, { message: t("common.validateMsg.passwordMin") })
          .max(50, {
            message: t("common.validateMsg.passwordMax"),
          })
          .refine((data) => data.password === data.passwordConfirm, {
            message: t("common.passwordsNotMatch"),
            path: ["passwordConfirm"],
          }),
        phone: z
          .string()
          .min(10, { message: t("common.validateMsg.phoneNumber") })
          .max(10, { message: t("common.validateMsg.phoneNumber") }),
        countryCode: z.string().min(1, { message: t("common.nonEmpty") }),
      }),
    ),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phone: "",
      countryCode: "TR",
    },
  });

  const onSubmit = (data) => {
    const bodyObj = {
      ...data,
      roles: {
        PRODUCTS: items[0].roles,
        CLIENTS: items[1].roles,
        CLAIMS: items[2].roles,
        APPS: items[3].roles,
        ACTIVATIONS: items[4].roles,
        DOCUMENTS: items[5].roles,
        PERSONALS: items[6].roles,
      },
    };

    employeesService
      .addMerchantUser(bodyObj)
      .then(() => {
        navigate("/settings/employees");
        toast({
          title: t("settingsPage.employeeAddedSuccessfully"),
          variant: "success",
        });
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const allRolesSelected =
    items[0].roles.length === 2 &&
    items[1].roles.length === 2 &&
    items[2].roles.length === 2 &&
    items[3].roles.length === 2 &&
    items[4].roles.length === 2 &&
    items[5].roles.length === 2 &&
    items[6].roles.length === 2;

  const noneIsSelected =
    items[0].roles.length === 0 &&
    items[1].roles.length === 0 &&
    items[2].roles.length === 0 &&
    items[3].roles.length === 0 &&
    items[4].roles.length === 0 &&
    items[5].roles.length === 0 &&
    items[6].roles.length === 0;

  return (
    <main className="flex min-h-screen flex-col gap-5 bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/employees">
          {t("settingsPage.employees")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("settingsPage.addEmployee")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto flex flex-col items-center justify-center gap-6">
        <div className="formBox flex-col gap-4">
          <h2 className="mb-5 font-medium">{t("settingsPage.employeeInfo")}</h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid w-full grid-cols-1 gap-4 md:grid-cols-2"
            >
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.firstName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.lastName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.email")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.password")}</Label>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="passwordConfirm"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.passwordConfirm")}</Label>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-4">
                <FormField
                  control={form.control}
                  name="countryCode"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("settingsPage.country")}</Label>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="TR">+90</SelectItem>
                          <SelectItem value="UK">+44</SelectItem>
                          <SelectItem value="UZ">+998</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("settingsPage.phoneNumber")}</Label>
                      <FormControl>
                        <Input type="tel" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <div className="flex justify-between">
            <h2 className="mb-5 font-medium">
              {t("settingsPage.accessPermissions")}
            </h2>
            <div className="flex items-center gap-3">
              <Checkbox
                id="t"
                checked={allRolesSelected}
                onClick={() => {
                  if (noneIsSelected) {
                    items.map((item) => {
                      item.roles.push("VIEW");
                      item.roles.push("EDIT");
                    });
                  } else {
                    items.map((item) => {
                      item.roles = [];
                    });
                  }
                  setItems([...items]);
                }}
              />
              <label htmlFor="t">{t("settingsPage.allPermissions")}</label>
            </div>
          </div>
          <div className="flex flex-col">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex flex-col gap-5 border-b py-5 last:border-0"
              >
                <h2 className="font-medium text-[#111729]">{item.title}</h2>
                <div className="flex items-center justify-between gap-12">
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id={`${item.id}-g`}
                        checked={item.roles.includes("VIEW")}
                        onClick={() => {
                          if (item.roles.includes("VIEW")) {
                            item.roles = item.roles.filter(
                              (role) => role !== "VIEW",
                            );

                            if (item.roles.includes("EDIT")) {
                              item.roles = item.roles.filter(
                                (role) => role !== "EDIT",
                              );
                            }
                          } else {
                            item.roles.push("VIEW");
                          }
                          setItems([...items]);
                        }}
                      />
                      <Label
                        className="cursor-pointer"
                        htmlFor={`${item.id}-g`}
                      >
                        {t("settingsPage.viewing")}
                      </Label>
                    </div>
                    <p className="text-sm text-gray-500">{item.desc1}</p>
                  </div>
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id={`${item.id}-d`}
                        checked={item.roles.includes("EDIT")}
                        onClick={() => {
                          if (item.roles.includes("EDIT")) {
                            item.roles = item.roles.filter(
                              (role) => role !== "EDIT",
                            );
                          } else {
                            item.roles.push("EDIT");

                            if (!item.roles.includes("VIEW")) {
                              item.roles.push("VIEW");
                            }
                          }
                          setItems([...items]);
                        }}
                      />
                      <Label
                        htmlFor={`${item.id}-d`}
                        className="cursor-pointer"
                      >
                        {t("settingsPage.editing")}
                      </Label>
                    </div>
                    <p className="text-sm text-gray-500">{item.desc2}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button className="mb-20 ml-auto" onClick={form.handleSubmit(onSubmit)}>
          {t("common.save")}
        </Button>
      </div>
    </main>
  );
};

export default CreateEmployee;
