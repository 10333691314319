import AuthHeader from "@/components/auth/authHeader";
import ForgotPasswordForm from "@/components/auth/forgotPasswordForm";

const ForgotPassword = () => {
  return (
    <div className="bg-[#F9FAFC]">
      <AuthHeader />
      <main
        className="flex w-full items-center justify-center"
        style={{ minHeight: "calc(100vh - 7rem)" }}
      >
        <ForgotPasswordForm />
      </main>
    </div>
  );
};

export default ForgotPassword;
