import axios from "@/lib/axios";

class PaymentService {
  constructor() {
    this.api = axios;
  }

  async getPricingPackages(query) {
    const { data } = await this.api.get("/pricings/packages");

    return data;
  }

  async getStoredCards() {
    const { data } = await this.api.get("/store-cards", {
      params: {
        page: 1,
        size: 10,
      },
    });

    return data.content;
  }
  async getStoredCardsPayAsGo() {
    const { data } = await this.api.get("/store-cards/pay-as-go-card");

    return data;
  }

  async addStoredCard(data) {
    const response = await this.api.post("/store-cards", data);

    return response;
  }

  async deleteStoredCard(id) {
    const response = await this.api.delete(`/store-cards/${id}`);

    return response;
  }

  async getPastPayments() {
    const { data } = await this.api.get("/payments", {
      params: {
        page: 1,
        size: 10,
      },
    });

    return data.content;
  }

  async postCheckout(data) {
    const response = await this.api.post("/payments/3ds/init", data);

    return response;
  }

  async getPayment(paymentId) {
    const { data } = await this.api.get(`/payments/3ds/${paymentId}`);

    return data;
  }

  async startPayAsGo() {
    const response = await this.api.post(
      "/merchants/activate/payment-plan",
      null,
      {
        params: {
          merchantPaymentPlan: "PAY_AS_GO",
        },
      },
    );

    return response;
  }

  async cancelPayAsGo(storeCardId) {
    const response = await this.api.post(
      `/merchants/activate/payment-plan?merchantPaymentPlan=PREPAID`,
    );

    await this.api.delete(`/store-cards/` + storeCardId);

    return response;
  }

  async getStoredCardsForSubscription() {
    const { data } = await this.api.get(
      "/store-cards/by-card-usage-purpose/PAY_AS_GO",
    );

    return data;
  }

  async getMerchantPaymentPlan() {
    const { data } = await this.api.get("/merchants/payment-plan");

    return data;
  }
}

export default new PaymentService();
