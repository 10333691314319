import { Button } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import productActivationService from "@/services/productActivationService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import egarantiLogo from "@/assets/egaranti-logo.png";

const ActivationDetails = () => {
  const [activations, setActivations] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    productActivationService.getProductActivations().then((res) => {
      setActivations(res);
    });
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/activations/">
          {t("settingsPage.activations")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.activationDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="mx-auto my-20 flex max-w-5xl flex-col items-center justify-center gap-6">
        <Header />
        {activations.length > 0 ? (
          <div className="grid grid-cols-3 gap-8">
            {activations.map((activation) => (
              <ActivationCard key={activation.id} data={activation} />
            ))}
            <AddCard />
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </main>
  );
};

export default ActivationDetails;

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="formBox flex items-center justify-between gap-4 py-10">
      <div className="flex w-full items-center justify-between gap-6">
        <img src={egarantiLogo} className="w-20" />
        <h2 className="text-nowrap text-lg font-medium text-[#111729]">
          {t("settingsPage.productRegisterActivation.title")}
        </h2>
        <p className="text-sm text-[#4A5567]">
          {t("settingsPage.productRegisterActivation.desc")}
        </p>
      </div>
    </div>
  );
};

const ActivationCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/settings/activations/product/${data.id}`}
      className="flex w-full flex-col gap-2 rounded-lg border bg-white p-6 hover:border-blue-500"
    >
      <img src={data?.logoUrl} className="w-20" />
      <h3 className="text-lg font-medium text-[#111729]">
        {t("settingsPage.productRegisterActivation.productName")}
      </h3>
      <p className="text-sm text-[#4A5567]">
        <b className="text-[#111729]">{data?.brand}</b>{" "}
        {t("settingsPage.productRegisterActivation.brandDesc")}
      </p>
    </Link>
  );
};

const NoData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col items-center justify-center gap-5">
      {t("settingsPage.noDataProductActivation")}
      <Button onClick={() => navigate("/settings/activations/product/create")}>
        {t("settingsPage.addBrand")}
      </Button>
    </div>
  );
};

const AddCard = () => {
  const { t } = useTranslation();
  return (
    <Link
      to="/settings/activations/product/create"
      className="flex w-full flex-col gap-2 rounded-lg border border-dashed border-[#CDD5E0] bg-[#F2F5F9] p-6"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 13.3333V26.6667M13.3333 20H26.6667M13 35H27C29.8003 35 31.2004 35 32.27 34.455C33.2108 33.9757 33.9757 33.2108 34.455 32.27C35 31.2004 35 29.8003 35 27V13C35 10.1997 35 8.79961 34.455 7.73005C33.9757 6.78924 33.2108 6.02433 32.27 5.54497C31.2004 5 29.8003 5 27 5H13C10.1997 5 8.79961 5 7.73005 5.54497C6.78924 6.02433 6.02433 6.78924 5.54497 7.73005C5 8.79961 5 10.1997 5 13V27C5 29.8003 5 31.2004 5.54497 32.27C6.02433 33.2108 6.78924 33.9757 7.73005 34.455C8.79961 35 10.1997 35 13 35Z"
          stroke="#111729"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h3 className="text-lg font-medium text-[#111729]">
        {t("settingsPage.addBrand")}
      </h3>
      <p className="text-sm text-[#4A5567]">{t("settingsPage.addBrandDesc")}</p>
    </Link>
  );
};
