import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Switch } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { FileLink } from "@egaranti/components";
import { FileArea } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import ProductService from "@/services/productService";

import DocumentLinkCard from "@/components/products/edit/documentLinkCard";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import { t } from "i18next";

const ProductEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const form = useForm({
    defaultValues: async () => ProductService.getProduct(id),
  });

  const onSubmit = (data) => {
    setLoading(true);

    ProductService.updateProduct(data)
      .then(() => {
        toast({
          title: t("productsPage.updateSuccess"),
          variant: "success",
        });
        navigate("/products/products");
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      })
      .finally(() => setLoading(false));
  };

  const fetchDocuments = async () => {
    ProductService.getDocuments(id).then((data) => {
      form.setValue("documents", data);
      setDocuments(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchDocuments();
  }, []);

  const onDeleteProductImage = () => {
    form.setValue("imageUrl", null);
    ProductService.deleteProductImage(id).catch((err) => {
      toast({
        variant: "error",
        description: err.response.data?.errors[0].message,
      });
    });
  };

  const onImageUpload = (id, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    ProductService.postProductImage(id, formData).then((res) => {
      form.setValue("imageUrl", res.data.imageUrl);
      toast({
        title: t("productsPage.imageUploadSuccess"),
        variant: "success",
      });

      setLoading(false);
    });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/products/products">
          {t("productsPage.products")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("productsPage.editProduct")}</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productCategory")}
              </h2>
              <Tag>{form.watch("categoryTree")}</Tag>
            </div>
            <div className="formBox flex flex-col gap-4">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productInfo")}
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.productName")}</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="model"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Model</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="warrantyDuration"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.warrantyDuration")}</Label>
                      <FormControl>
                        <Input
                          min="1"
                          placeholder=""
                          {...field}
                          type="number"
                        />
                      </FormControl>
                      <FormMessage />
                      <FormDescription>
                        {t("productsPage.warrantyDurationDesc")}
                      </FormDescription>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.description")}</Label>
                      <FormControl>
                        <Textarea placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {/* Skeleton product Image */}
              <div className="grid grid-cols-2 gap-4">
                {loading ? (
                  <div className="flex animate-pulse items-center justify-center rounded-lg bg-[#F2F5F9] p-2">
                    Loading...
                  </div>
                ) : (
                  <>
                    {form.watch("imageUrl") ? (
                      <div className="flex flex-col gap-3">
                        <Label>{t("productsPage.productImage")}</Label>
                        <FileLink
                          onDeleted={onDeleteProductImage}
                          url={form.watch("imageUrl")}
                        />
                      </div>
                    ) : (
                      <FileArea
                        title={t("productsPage.productImage")}
                        onFileUpload={(file) => onImageUpload(id, file)}
                      />
                    )}
                  </>
                )}
                <FormField
                  control={form.control}
                  name="setupRequired"
                  render={({ field }) => (
                    <FormItem className="flex flex-col py-4">
                      <Label>{t("productsPage.installationRequired")}</Label>
                      <FormControl>
                        <Switch disabled checked={field.value} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              className="ml-auto"
              disabled={loading || !form.formState.isDirty}
            >
              {t("common.save")}
            </Button>
            <div className="formBox flex flex-col gap-4">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.documentInfo")}
              </h2>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <DocumentsContainer
                  reFetchDocuments={fetchDocuments}
                  documents={documents}
                />
              )}
            </div>
          </div>
        </form>
      </Form>
    </main>
  );
};

export default ProductEditPage;

const DocumentsContainer = ({ documents, reFetchDocuments }) => {
  return (
    <div className="flex flex-col justify-between gap-12 md:flex-row">
      <DocumentLinkCard
        reFetchDocuments={reFetchDocuments}
        title={t("productsPage.warrantyDocument")}
        type="WARRANTY_DOCUMENT"
        document={documents?.find((doc) => doc.type === "WARRANTY_DOCUMENT")}
      />
      <DocumentLinkCard
        reFetchDocuments={reFetchDocuments}
        title={t("productsPage.userManual")}
        type="USER_MANUAL_DOCUMENT"
        document={documents?.find((doc) => doc.type === "USER_MANUAL_DOCUMENT")}
      />
    </div>
  );
};
