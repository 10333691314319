import { Tabs } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import paymentService from "@/services/paymentService";

import MainFooter from "@/components/layout/mainFooter";
import CardsContainer from "@/components/settings/payment/cardsContainer";
import PastPayments from "@/components/settings/payment/pastPayments";
import PaymentHero from "@/components/settings/payment/paymentHero";
import PlansAndFees from "@/components/settings/payment/plansAndFees";
import Testimonials from "@/components/settings/payment/testimonials";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const PaymentSettings = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [merchantsPlan, setMerchantsPlan] = useState(null);

  const fetchMerchantsPlan = () => {
    paymentService
      .getMerchantPaymentPlan()
      .then((data) => {
        setMerchantsPlan(data);
      })
      .catch(() => {
        setMerchantsPlan(null);
      });
  };

  useEffect(() => {
    fetchMerchantsPlan();
  }, []);

  return (
    <div className="bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings">
          {t("payment.breadcrumbs.text1")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("payment.breadcrumbs.text2")}</BreadcrumbItem>
      </Breadcrumb>
      <PaymentHero
        merchantsPlan={merchantsPlan}
        setActiveTab={setActiveTab}
        fetchMerchantsPlan={fetchMerchantsPlan}
      />
      <div className="mx-auto mb-9 mt-16 max-w-[850px] border-b-[1px]">
        <Tabs
          id="payment-tabs"
          tabs={[
            { id: 1, title: t("payment.tabs.title1") },
            { id: 2, title: t("payment.tabs.title2") },
            { id: 3, title: t("payment.tabs.title3") },
          ]}
          selectedTabId={activeTab}
          onTabChange={(id) => setActiveTab(id)}
        />
      </div>
      <div className={`mx-auto max-w-[850px] rounded-lg bg-white`}>
        {activeTab === 1 && (
          <PlansAndFees
            fetchMerchantsPlan={fetchMerchantsPlan}
            merchantsPlan={merchantsPlan}
          />
        )}
        {activeTab === 2 && <CardsContainer />}
        {activeTab === 3 && <PastPayments />}
      </div>
      <Testimonials />
      <MainFooter />
    </div>
  );
};

export default PaymentSettings;
