import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import egarantiLogo from "@/assets/egaranti-mini-logo.png";

const ActivationsPage = () => {
  const { t } = useTranslation();

  const data = [
    {
      name: t("settingsPage.serviceActivation"),
      description: t("settingsPage.serviceActivationDesc"),
      path: "/settings/activations/service",
    },
    {
      name: t("settingsPage.productRegisterActivation.title"),
      description: t("settingsPage.productRegisterActivation.desc"),
      path: "/settings/activations/product",
    },
  ];

  return (
    <main className="min-h-full bg-[#F9FAFC] p-7">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {data.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className="w-full cursor-pointer rounded-lg border border-[#E3E7EF] bg-white p-[24px] transition-all hover:border-[#1E86FF]"
          >
            <div className="flex flex-col items-start justify-start gap-2">
              <img
                src={egarantiLogo}
                alt="egaranti logo"
                className="h-[48px] w-[48px]"
              />
              <span className="font-medium text-[#111729]">{item.name}</span>
              <p className="text-sm text-[#677389]">{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </main>
  );
};

export default ActivationsPage;
