import axios from "@/lib/axios";

class SetupClaimsService {
  constructor() {
    this.api = axios;
  }

  async getClaims(filters) {
    const { data } = await this.api.get(
      "/individual-customer-product-setup-claims/for-merchants",
      { params: filters },
    );

    return data;
  }

  async getClaim(id) {
    const { data } = await this.api.get(
      `/individual-customer-product-setup-claims/${id}/for-merchants`,
    );

    return data;
  }

  async getAvailableDates(id) {
    const { data } = await this.api.get(
      `/individual-customer-product-setup-claims/${id}/available-dates/for-merchants`,
    );

    return data;
  }

  async updateClaim(id, data) {
    const response = await this.api.put(
      `/individual-customer-product-setup-claims/${id}/for-merchants`,
      data,
    );

    return response;
  }
}

export default new SetupClaimsService();
