import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FilterSheet from "./filterSheet";

import ProductActivationService from "@/services/productActivationService";

import useProductActivationsStore from "@/stores/useProductActivationStore";

import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const AddProduct = () => {
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();

  const { getUnlinkedProducts, unlinkedProducts, setUnlinkedProductsFilter } =
    useProductActivationsStore();

  useEffect(() => {
    getUnlinkedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setUnlinkedProductsFilter({ ...unlinkedProducts.filter, page });
    getUnlinkedProducts();
  };

  const onPageSizeChange = (size) => {
    setUnlinkedProductsFilter({ ...unlinkedProducts.filter, size, page: 1 });
    getUnlinkedProducts();
  };

  const onAddProducts = () => {
    ProductActivationService.linkProducts({
      productRegistrationActivationId: id,
      merchantProductIds: selectedProducts,
    }).then(() => {
      setSelectedProducts([]);
      getUnlinkedProducts();
      toast({
        title: "Ürünler başarıyla eklendi",
        variant: "success",
      });
    });
  };

  const onSelectAll = () => {
    unlinkedProducts?.data.length === selectedProducts.length
      ? setSelectedProducts([])
      : setSelectedProducts(
          unlinkedProducts?.data.map((product) => product.id),
        );
  };

  return (
    <div>
      {unlinkedProducts.data.length ? (
        <>
          <TableHeaderWrapper>
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
            >
              {t("productsPage.filter")}
            </Button>
          </TableHeaderWrapper>
          <Table className="border border-[#EAECF0] bg-white shadow-sm">
            <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
              <TableRow>
                <TableHead>
                  <Checkbox
                    checked={
                      unlinkedProducts?.data.length === selectedProducts.length
                    }
                    onClick={onSelectAll}
                  />
                </TableHead>
                <TableHead>{t("productsPage.productName")}</TableHead>
                <TableHead>{t("productsPage.brand")}</TableHead>
                <TableHead>{t("productsPage.source")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {unlinkedProducts?.data.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedProducts.includes(product.id)}
                      onClick={() => {
                        if (selectedProducts.includes(product.id)) {
                          setSelectedProducts(
                            selectedProducts.filter((id) => id !== product.id),
                          );
                        } else {
                          setSelectedProducts([
                            ...selectedProducts,
                            product.id,
                          ]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.brand}</TableCell>
                  <TableCell>{product.source}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex flex-col items-center">
            {unlinkedProducts.filter.totalPages > 1 && (
              <Pagination
                totalPages={unlinkedProducts.filter.totalPages}
                currentPage={unlinkedProducts.filter.page}
                pageSize={unlinkedProducts.filter.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            <PageSizeSelect
              onPageSizeChange={onPageSizeChange}
              value={unlinkedProducts.filter.size}
            />
          </div>
          <div className="mt-5 flex justify-end">
            <Button
              disabled={selectedProducts.length === 0}
              onClick={onAddProducts}
            >
              Ürünleri Ekle
            </Button>
          </div>
          <FilterSheet
            id={id}
            open={openFilterSheet}
            onOpenChange={setOpenFilterSheet}
          />
        </>
      ) : (
        <div className="flex h-64 flex-col items-center justify-center p-5">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#AAC2F7" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#CCDBFA"
              strokeWidth="8"
            />
            <path
              d="M28 32V28M28 24H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
              stroke="#002573"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="mt-4 text-base font-semibold text-[#111729]">
            {t("productsPage.productCouldNotFound")}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProduct;
