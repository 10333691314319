import { Tag } from "@egaranti/components";

import TrashIcon from "../../../assets/icons/trash.svg?react";

import useServiceClaimsStore from "@/stores/useServiceClaimsStore";

const FilterTags = () => {
  const { filters, setFilters, getClaims } = useServiceClaimsStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.claimNo && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.claimNo}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, claimNo: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.individualCustomerFirstName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.individualCustomerFirstName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, individualCustomerFirstName: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.individualCustomerLastName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.individualCustomerLastName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, individualCustomerLastName: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.individualCustomerProductName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.individualCustomerProductName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, individualCustomerProductName: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.serviceClaimStatus && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.serviceClaimStatus}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, serviceClaimStatus: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
