/* eslint-disable react/prop-types */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import paymentService from "@/services/paymentService";

import EmptyTable from "@/components/shared/EmptyTable";

const PastPayments = () => {
  const { t } = useTranslation();
  const [pastPayments, setPastPayments] = useState([]);

  useEffect(() => {
    paymentService.getPastPayments().then((data) => setPastPayments(data));
  }, []);

  return (
    <>
      {pastPayments.length > 0 ? (
        <>
          <Table>
            <TableHeader>
              <TableHead>{t("payment.pastPayments.table.amount")}</TableHead>
              <TableHead>{t("payment.pastPayments.table.date")}</TableHead>
              <TableHead>{t("payment.pastPayments.table.status")}</TableHead>
            </TableHeader>
            <TableBody>
              {pastPayments?.map((pastPayment) => (
                <TableRow key={pastPayment.id}>
                  <TableCell>
                    <span className="text-sm font-medium leading-5 text-[#111729]">
                      {pastPayment.price}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-sm font-medium leading-5 text-[#111729]">
                      {pastPayment.createdAt}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Tag
                      size="sm"
                      variant={
                        pastPayment.paymentResultStatus === "SUCCESS"
                          ? "green"
                          : "red"
                      }
                      className="flex w-max items-center justify-center"
                    >
                      {/* TODO - add more cases to logic */}
                      {pastPayment.paymentResultStatus === "SUCCESS"
                        ? t("payment.pastPayments.table.successful")
                        : t("payment.pastPayments.table.failed")}
                    </Tag>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <>
          <EmptyTable
            title={t("payment.pastPayments.emptyTable.noPaymentTitle")}
            description={t("payment.pastPayments.emptyTable.noPaymentSubtitle")}
          />
        </>
      )}
    </>
  );
};

const DownloadIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#CCDBFA" />
      <path
        d="M27.5 22.5V23.5C27.5 24.9001 27.5 25.6002 27.2275 26.135C26.9878 26.6054 26.6054 26.9878 26.135 27.2275C25.6002 27.5 24.9001 27.5 23.5 27.5H16.5C15.0999 27.5 14.3998 27.5 13.865 27.2275C13.3946 26.9878 13.0122 26.6054 12.7725 26.135C12.5 25.6002 12.5 24.9001 12.5 23.5V22.5M24.1667 18.3333L20 22.5M20 22.5L15.8333 18.3333M20 22.5V12.5"
        stroke="#0049E6"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PastPayments;
