import { Button } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FilterIcon from "../../assets/icons/filter.svg?react";

import useProductsStoreFromIntegrations from "@/stores/useProductsFromIntegrationsStore";

import FilterSheet from "@/components/productsFromIntegrations/filterSheet";
import FilterTags from "@/components/productsFromIntegrations/filterTags";
import EmptyTable from "@/components/shared/EmptyTable";
import InfoTooltip from "@/components/shared/infoTooltip";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  const { products, loading, filters, getProducts, setFilters } =
    useProductsStoreFromIntegrations();

  useEffect(() => {
    getProducts();
  }, []);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getProducts();
  };

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    setFilters({ ...filters, size, page: 1 });
    getProducts();
  };

  const onClickItem = (e, id) => {
    e.stopPropagation();
    navigate(`/products/integrations/${id}`);
  };
  console.log(filters.size);
  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end pb-4"></div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
              className="min-w-[150px] font-medium"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={4} />
            </Table>
          ) : products.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("productsPage.productName")}</TableHead>
                <TableHead>{t("productsPage.brand")}</TableHead>
                <TableHead className="flex items-center gap-2">
                  <span>{t("productsPage.productCode")}</span>
                  <InfoTooltip>{t("productsPage.tooltipText")}</InfoTooltip>
                </TableHead>
                <TableHead>{t("productsPage.source")}</TableHead>
              </TableHeader>
              {loading && <TableSkeleton colSpan={4} />}
              <TableBody>
                {products?.map((product) => (
                  <TableRow
                    role="button"
                    onClick={(e) => onClickItem(e, product.id)}
                    key={product.id}
                  >
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      <span className="text-sm text-[#677389]">
                        {product.brand}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm text-[#677389]">
                        {product.productCode}
                      </span>
                    </TableCell>
                    <TableCell>{product.source}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("productsPage.productCouldNotFound")}
              desc={t("productsPage.productCouldNotFoundDesc")}
            />
          )}
          <div className="flex flex-col items-center">
            {filters.totalPages > 1 && (
              <Pagination
                totalPages={filters.totalPages}
                currentPage={filters.page}
                pageSize={filters.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            <PageSizeSelect
              value={filters.size}
              onPageSizeChange={onPageSizeChange}
            />
          </div>
        </div>
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </main>
  );
};

export default Products;
