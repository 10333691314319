/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";
import { RadioGroup, RadioGroupItem } from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { maintenanceFilters, getMaintenancePlans, setMaintenanceFilters } =
    useAdditionalPackagesStore();

  const [filtersInSheet, setFiltersInSheet] = useState({
    title: "",
    activated: null,
  });

  const onSaveFilter = () => {
    onOpenChange(false);
    setMaintenanceFilters({
      ...maintenanceFilters,
      ...filtersInSheet,
      page: 1,
    });
    getMaintenancePlans();
  };

  const handleFieldChange = (fieldName, value) => {
    setFiltersInSheet({
      ...filtersInSheet,
      [fieldName]: value,
    });
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("additionalServicesPage.title")}</Label>
          <Input
            value={filtersInSheet.title}
            onChange={(e) => handleFieldChange("title", e.target.value)}
          />
          <Label>{t("additionalServicesPage.status")}</Label>
          <RadioGroup
            onValueChange={(e) => handleFieldChange("activated", e)}
            value={filtersInSheet.activated}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="true" id="true" />
              <Label htmlFor="true">{t("additionalServicesPage.active")}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="false" id="false" />
              <Label htmlFor="false">
                {t("additionalServicesPage.passive")}
              </Label>
            </div>
          </RadioGroup>
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
