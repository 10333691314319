import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Switch } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";
import { RadioGroup, RadioGroupItem } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ProductActivationService from "@/services/productActivationService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import BigPreview from "@/assets/activations/bigPreview.png";
import SmallPreview from "@/assets/activations/smallPreview.png";
import PlusIcon from "@/assets/icons/plusCircle.svg?react";
import TrashIcon from "@/assets/icons/trash.svg?react";

import * as z from "zod";

const ActivationCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  const form = useForm({
    resolver: zodResolver(
      z.object({
        brand: z.string().min(1),
        subdomain: z.string().min(1),
        imageType: z.string().min(1),
        warrantyFileOption: z.boolean(),
        billingFileOption: z.boolean(),
        serviceFileOption: z.boolean(),
        file: z.any(),
      }),
    ),
    defaultValues: {
      brand: "",
      subdomain: "",
      imageType: "", // "BIG" | "SMALL"
      warrantyFileOption: false,
      billingFileOption: false,
      serviceFileOption: false,
      file: null,
    },
  });

  const [isSerialNumber, setIsSerialNumber] = useState(false);
  const [isShowQuestion, setIsShowQuestion] = useState(false);

  const onSubmit = () => {
    // bug with react-hook-form it doesn't update the value when component is conditionally rendered so we need to use useWatch hook to get the value of the field and update the state
    const data = form.getValues();
    const formData = new FormData();
    formData.append("file", data.file);

    ProductActivationService.createActivation(data, formData)
      .then((res) => {
        toast({
          title: t("common.success"),
          variant: "success",
        });
        navigate(`/settings/activations/product/${res.id}`);
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/activations">
          {t("settingsPage.activations")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.productRegisterActivation.title")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <form>
            <Form {...form}>
              <div className="mb-5 flex flex-col gap-5 rounded-lg border border-gray-200 p-5">
                <h2 className="mb-5 font-medium">{t("titles.settings")}</h2>
                <div className="flex justify-between gap-5">
                  <FormField
                    control={form.control}
                    name="brand"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("settingsPage.brand")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="subdomain"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>URL</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                        <FormDescription>
                          {form.watch("subdomain")}.egaranti.com
                        </FormDescription>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="file"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>Logo</Label>
                        <FormControl>
                          <Input
                            accept=".jpg, .jpeg, .png, .pdf"
                            type="file"
                            onChange={(e) =>
                              field.onChange(
                                e.target.files ? e.target.files[0] : null,
                              )
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-5 rounded-lg border border-gray-200 p-5">
                <h2 className="font-medium">
                  {t("settingsPage.productRegisterActivation.section2.title")}
                </h2>
                <p className="text-sm text-[#667085]">
                  {t("settingsPage.productRegisterActivation.section2.desc")}
                </p>
                <RadioGroup
                  onValueChange={(e) => {
                    form.setValue("imageType", e);
                  }}
                  value={form.watch("imageType")}
                >
                  <FormField
                    control={form.control}
                    name="imageType"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="flex items-center space-x-2">
                            <FormControl>
                              <RadioGroupItem value="BIG" id="big" />
                            </FormControl>
                            <Label htmlFor="big">
                              {t(
                                "settingsPage.productRegisterActivation.section2.big",
                              )}
                            </Label>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger
                                  asChild
                                  className="hidden cursor-pointer text-blue-700 underline hover:text-blue-800 md:block"
                                >
                                  <span>
                                    {t(
                                      "settingsPage.productRegisterActivation.section2.preview",
                                    )}
                                  </span>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <img
                                    className="max-w-sm"
                                    src={BigPreview}
                                    alt="preview"
                                  />
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex items-center space-x-2">
                    <FormControl>
                      <RadioGroupItem value="SMALL" id="small" />
                    </FormControl>
                    <Label htmlFor="small">
                      {t(
                        "settingsPage.productRegisterActivation.section2.small",
                      )}
                    </Label>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger
                          asChild
                          className="hidden cursor-pointer text-blue-700 underline hover:text-blue-800 md:block"
                        >
                          <span>
                            {t(
                              "settingsPage.productRegisterActivation.section2.preview",
                            )}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <img
                            className="max-w-sm"
                            src={SmallPreview}
                            alt="preview"
                          />
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </RadioGroup>
              </div>
              <div className="mb-5 flex flex-col gap-5 rounded-lg border border-gray-200 p-5">
                <h2 className="font-medium">
                  {t("settingsPage.productRegisterActivation.section3.title")}
                </h2>
                <p className="text-sm text-[#667085]">
                  {t("settingsPage.productRegisterActivation.section3.desc")}
                </p>
                <div className="grid grid-cols-3 gap-8">
                  <div className="flex justify-between rounded-lg border p-6">
                    <FormField
                      control={form.control}
                      name="warrantyFileOption"
                      render={({ field }) => (
                        <FormItem className="flex w-full items-center justify-between">
                          <Label>
                            {t(
                              "settingsPage.productRegisterActivation.section3.warranty",
                            )}
                          </Label>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex justify-between rounded-lg border p-6">
                    <FormField
                      control={form.control}
                      name="billingFileOption"
                      render={({ field }) => (
                        <FormItem className="flex w-full items-center justify-between">
                          <Label>
                            {t(
                              "settingsPage.productRegisterActivation.section3.invoice",
                            )}
                          </Label>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex justify-between rounded-lg border p-6">
                    <FormField
                      control={form.control}
                      name="serviceFileOption"
                      render={({ field }) => (
                        <FormItem className="flex w-full items-center justify-between">
                          <Label>
                            {t(
                              "settingsPage.productRegisterActivation.section3.service",
                            )}
                          </Label>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-5 flex flex-col items-baseline gap-5 rounded-lg border border-gray-200 p-5">
                <h2 className="font-medium">
                  {t("settingsPage.productRegisterActivation.section4.title")}
                </h2>
                <p className="text-sm text-[#667085]">
                  {t("settingsPage.productRegisterActivation.section4.desc")}
                </p>
                {isSerialNumber ? (
                  <div className="flex items-end gap-5">
                    <FormField
                      control={form.control}
                      name="serialNumberLength"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <Label>
                            {t(
                              "settingsPage.productRegisterActivation.section4.serialNumberLength",
                            )}
                          </Label>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="serialNumberPrefix"
                      render={({ field }) => (
                        <FormItem className="max-w-[400px]">
                          <Label>
                            {t(
                              "settingsPage.productRegisterActivation.section4.serialNumberPrefix",
                            )}
                          </Label>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <button
                      className="flex shrink-0 items-center gap-1 text-sm font-medium text-red-500"
                      onClick={() => {
                        setIsSerialNumber(false);
                        form.setValue("serialNumberLength", null);
                        form.setValue("serialNumberPrefix", null);
                      }}
                    >
                      <TrashIcon />

                      {t(
                        "settingsPage.productRegisterActivation.section4.delete",
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setIsSerialNumber(true)}
                    className="flex items-center gap-2 text-[#0040C9]"
                  >
                    <PlusIcon />
                    {t("settingsPage.productRegisterActivation.section4.add")}
                  </button>
                )}
              </div>
              <div className="mb-5 flex flex-col items-baseline gap-5 rounded-lg border border-gray-200 p-5">
                <h2 className="font-medium">
                  {t("settingsPage.productRegisterActivation.section5.title")}
                </h2>
                <p className="text-sm text-[#667085]">
                  {t("settingsPage.productRegisterActivation.section5.desc")}
                </p>
                {isShowQuestion ? (
                  <div className="flex w-full items-end gap-5">
                    <FormField
                      control={form.control}
                      name="question"
                      render={({ field }) => (
                        <FormItem className="w-full max-w-[600px]">
                          <Label>
                            {t(
                              "settingsPage.productRegisterActivation.section5.question",
                            )}
                          </Label>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <button
                      className="flex items-center gap-1 text-sm font-medium text-red-500"
                      onClick={(event) => {
                        event.preventDefault();
                        setIsShowQuestion(false);
                        form.setValue("question", null);
                      }}
                    >
                      <TrashIcon />
                      {t(
                        "settingsPage.productRegisterActivation.section5.delete",
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      setIsShowQuestion(true);
                    }}
                    className="flex items-center gap-2 text-[#0040C9]"
                  >
                    <PlusIcon />
                    {t("settingsPage.productRegisterActivation.section5.add")}
                  </button>
                )}
              </div>
            </Form>
          </form>
          <footer className="flex gap-4">
            <Button
              variant="secondaryGray"
              className="mt-5"
              onClick={() => navigate(-1)}
            >
              {t("common.cancel")}
            </Button>
            <Button className="mt-5" onClick={form.handleSubmit(onSubmit)}>
              {t("common.save")}
            </Button>
          </footer>
        </div>
      </div>
    </main>
  );
};

export default ActivationCreate;
