/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";

import { useTranslation } from "react-i18next";

import DealerIcon from "../../assets/icons/dealer.svg?react";
import LogoDivaIcon from "../../assets/icons/logodiva.svg?react";
import PlusIcon from "../../assets/icons/plus1.svg?react";
import RightArrowIcon from "../../assets/icons/rightArrow.svg?react";
import SearchIcon from "../../assets/icons/search.svg?react";
import SendIcon from "../../assets/icons/send.svg?react";

const EmptyView = ({ setShowTableView }) => {
  const { t } = useTranslation();

  return (
    <div className="h-max w-full px-4 py-10">
      <h1 className="mb-6 text-center text-3xl font-semibold text-[#364153]">
        {t("sellers.title1")}
      </h1>
      <DealerIcon className="mx-auto" />
      <p className="mx-auto mt-6 max-w-[580px] text-center text-sm font-medium text-[#364153]">
        {t("sellers.desc1")}
      </p>
      <div className="mx-auto mt-16 flex max-w-[900px] items-center justify-between max-[1300px]:h-[800px] max-[1300px]:flex-col">
        <div className="flex h-[212px] w-[260px] flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-4 py-6 shadow-md">
          <h3 className="text-center text-2xl font-semibold text-[#364153]">
            {t("sellers.title2")}
          </h3>
          <PlusIcon className="min-h-[28px] min-w-[28px]" />
          <p className="text-center text-sm font-medium">
            {t("sellers.desc2")}
          </p>
        </div>
        <RightArrowIcon className="max-[1300px]:rotate-90" />
        <div className="flex h-[212px] w-[260px] flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-4 py-6 shadow-md">
          <h3 className="text-center text-2xl font-semibold text-[#364153]">
            {t("sellers.title3")}
          </h3>
          <SendIcon className="min-h-[28px] min-w-[28px]" />
          <p className="text-center text-sm font-medium">
            {t("sellers.desc3")}
          </p>
        </div>
        <RightArrowIcon className="max-[1300px]:rotate-90" />
        <div className="flex h-[212px] w-[260px] flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-4 py-6 shadow-md">
          <h3 className="text-center text-2xl font-semibold text-[#364153]">
            {t("sellers.title4")}
          </h3>
          <SearchIcon className="min-h-[28px] min-w-[28px]" />
          <p className="text-center text-sm font-medium">
            {t("sellers.desc4")}
          </p>
        </div>
      </div>
      <Button
        className="mx-auto mt-10 block"
        onClick={() => {
          localStorage.setItem("infoPageShown", "true");
          setShowTableView(true);
        }}
      >
        {t("sellers.setupSellership")}
      </Button>
      <div className="mx-auto mt-10 flex h-[250px] w-full max-w-[900px] flex-col gap-4 rounded-md border-gray-200 bg-white p-4 shadow-md">
        <h6 className="text-center text-xl font-semibold">
          {t("sellers.letUsKnowTitle")}
        </h6>
        <LogoDivaIcon className="mx-auto" />
        <p className="mx-auto max-w-[300px] text-center">
          {t("sellers.letUsKnowDesc")}
        </p>
        <Button className="mx-auto w-max" variant="secondaryGray">
          {t("sellers.letUsKnow")}
        </Button>
      </div>
    </div>
  );
};

export default EmptyView;
