import { Tag } from "@egaranti/components";

import TrashIcon from "../../assets/icons/trash.svg?react";

import useIndividualCustomerStore from "@/stores/useIndividualCustomerStore";

const FilterTags = () => {
  const { filters, setFilters, getIndividuaCustomers } =
    useIndividualCustomerStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.firstName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.firstName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, firstName: "" });
              getIndividuaCustomers();
            }}
          />
        </Tag>
      )}
      {filters.lastName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.lastName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, lastName: "" });
              getIndividuaCustomers();
            }}
          />
        </Tag>
      )}
      {filters.email && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.email}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, email: "" });
              getIndividuaCustomers();
            }}
          />
        </Tag>
      )}
      {filters.phone && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.phone}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, phone: "" });
              getIndividuaCustomers();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
