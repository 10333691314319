/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useSellerStore from "@/stores/useSellerStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { sellersWaitingFilters, getSellersWaiting, setSellersWaitingFilters } =
    useSellerStore();

  const [filtersInSheet, setFiltersInSheet] = useState({
    title: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    setFiltersInSheet({
      title: sellersWaitingFilters.title,
      phone: sellersWaitingFilters.phone,
      email: sellersWaitingFilters.email,
    });
  }, [sellersWaitingFilters]);

  const onSaveFilter = () => {
    onOpenChange(false);
    setSellersWaitingFilters({
      ...sellersWaitingFilters,
      ...filtersInSheet,
      page: 1,
    });
    getSellersWaiting();
  };

  const handleFieldChange = (fieldName, value) => {
    setFiltersInSheet({
      ...filtersInSheet,
      [fieldName]: value,
    });
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("sellers.title")}</Label>
          <Input
            value={filtersInSheet.title}
            onChange={(e) => handleFieldChange("title", e.target.value)}
          />
          <Label>{t("sellers.phone")}</Label>
          <Input
            value={filtersInSheet.phone}
            onChange={(e) => handleFieldChange("phone", e.target.value)}
          />
          <Label>{t("sellers.email")}</Label>
          <Input
            value={filtersInSheet.email}
            onChange={(e) => handleFieldChange("email", e.target.value)}
          />
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
