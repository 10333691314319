import { initReactI18next } from "react-i18next";

import translationEN from "./en.json";
import translationRU from "./ru.json";
import translationTR from "./tr.json";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next).init({
  localeStructure: "{{lng}}.json",
  resources: {
    tr: {
      translation: translationTR,
    },
    en: {
      translation: translationEN,
    },
    ru: {
      translation: translationRU,
    },
  },

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

i18n.use(LanguageDetector).init({
  detection: {
    order: ["localStorage", "navigator"],
    lookupLocalStorage: "i18nextLng",
    caches: ["localStorage"],
  },
});

export default i18n;
