import { Button } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useEmployeesStore from "@/stores/useEmployeesStore";

import EmptyTable from "@/components/shared/EmptyTable";

const Employees = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getEmployees, employees, loading } = useEmployeesStore();

  useEffect(() => {
    getEmployees();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end pb-4"></div>
      <div className="rounded-lg">
        <div className="mb-5 flex justify-end">
          <Button onClick={() => navigate("/settings/employees/create")}>
            {t("settingsPage.addEmployee")}
          </Button>
        </div>
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          {loading ? (
            <Table>
              <TableSkeleton colSpan={4} />
            </Table>
          ) : employees.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("settingsPage.fullName")}</TableHead>
                <TableHead>{t("settingsPage.phone")}</TableHead>
                <TableHead>{t("settingsPage.email")}</TableHead>
                <TableHead>{t("settingsPage.status")}</TableHead>
                <TableHead>{t("settingsPage.note")}</TableHead>
              </TableHeader>
              <TableBody>
                {employees?.map((employee) => (
                  <TableRow
                    key={employee.id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/settings/employees/${employee.id}`)
                    }
                  >
                    <TableCell>
                      {employee.firstName} {employee.lastName}
                    </TableCell>
                    <TableCell>{employee.phone}</TableCell>
                    <TableCell>{employee.email}</TableCell>
                    <TableCell>
                      <Tag
                        size="sm"
                        variant={
                          employee.status === "WAITING_MERCHANT" ||
                          employee.status === "WAITING_CUSTOMER"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {employee.status === "ACTIVE" &&
                          t("settingsPage.active")}
                      </Tag>
                    </TableCell>
                    <TableCell>{employee.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("settingsPage.employeeCouldNotFound")}
              desc={t("settingsPage.employeeCouldNotFoundDesc")}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default Employees;
