/* eslint-disable react/prop-types */
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import AdditionalServicesIcon from "../../assets/icons/additionalServices.svg?react";
import ApplicationsIcon from "../../assets/icons/applications.svg?react";
import ClaimsIcon from "../../assets/icons/claims.svg?react";
import CustomersIcon from "../../assets/icons/customers.svg?react";
import DownArrow from "../../assets/icons/downArrow.svg?react";
import LogoutIcon from "../../assets/icons/logout.svg?react";
import DocumentIcon from "../../assets/icons/navbardocuments.svg?react";
import ProductIcon from "../../assets/icons/products.svg?react";
import SettingsIcon from "../../assets/icons/settings.svg?react";
import CheckRole from "../shared/checkRole";

import useAuthStore from "@/stores/useAuthStore";

import HomeIcon from "@/assets/icons/home.svg?react";

import { cn } from "@/lib/utils";

const Navbar = () => {
  const { t } = useTranslation();
  const { logout } = useAuthStore();

  const menuItems = [
    {
      name: t("navbar.menuItems.overview"),
      key: "OVERVIEW",
      icon: <HomeIcon />,
      link: "/",
    },
    {
      name: t("navbar.menuItems.products"),
      key: "PRODUCTS",
      icon: <ProductIcon />,
      openSubNav: true,
      subMenuItems: [
        {
          name: t("navbar.menuItems.products"),
          link: "/products/products",
        },
        {
          name: t("navbar.menuItems.integrations"),
          link: "/products/integrations",
        },
      ],
    },
    {
      name: t("navbar.menuItems.clients"),
      key: "CLIENTS",
      icon: <CustomersIcon />,
      subMenuItems: [
        {
          name: t("navbar.menuItems.individual"),
          link: "/customers/individual",
        },
        {
          name: t("navbar.menuItems.corporate"),
          link: "/customers/corporate",
        },
      ],
    },
    // {
    //   name: t("navbar.menuItems.additionalServices"),
    //   key: "ADDITIONAL_SERVICES",
    //   icon: <AdditionalServicesIcon />,
    //   subMenuItems: [
    //     {
    //       name: t("navbar.menuItems.additionalWarranty"),
    //       link: "/additional-packages/extra-warranty",
    //     },
    //     {
    //       name: t("navbar.menuItems.maintenance"),
    //       link: "/additional-packages/maintenance",
    //     },
    //   ],
    // },
    {
      name: t("navbar.menuItems.claims"),
      key: "CLAIMS",
      icon: <ClaimsIcon />,
      subMenuItems: [
        {
          name: t("navbar.menuItems.service"),
          link: "/claims/service",
        },
        {
          name: t("navbar.menuItems.setup"),
          link: "/claims/setup",
        },
        {
          name: t("navbar.menuItems.serviceRecord"),
          link: "/claims/service-record",
        },
      ],
    },
    // {
    //   name: t("navbar.menuItems.documents"),
    //   key: "DEALERS",
    //   icon: <DocumentIcon />,
    //   link: "/settings/documents",
    // },
    // {
    //   name: t("navbar.menuItems.sellers"),
    //   key: "DEALERS",
    //   icon: <DealerIcon />,
    //   link: "/sellers",
    // },
    {
      name: t("navbar.menuItems.applications"),
      key: "APPS",
      icon: <ApplicationsIcon />,
      link: "/applications",
    },
    {
      name: t("navbar.menuItems.settings"),
      key: "SETTINGS",
      icon: <SettingsIcon />,
      link: "/settings",
    },
  ];

  return (
    <nav className="flex flex-1 flex-col gap-2 overflow-auto pt-6">
      {menuItems.map((item) => (
        <CheckRole key={item.name} action="VIEW" section={item.key}>
          <NavItem
            link={item.link}
            key={item.name}
            hasSubNav={item.subMenuItems}
          >
            {item.icon}
            {item.name}
          </NavItem>
        </CheckRole>
      ))}
      <AlertDialog>
        <AlertDialogTrigger>
          <div
            role="button"
            tabIndex="0"
            className="flex flex-col gap-2 rounded-lg"
          >
            <div className="flex items-center rounded-lg px-5 py-4 font-medium text-[#111729] hover:bg-gray-100">
              <div className="flex items-center gap-3">
                <LogoutIcon />
                <span>{t("navbar.menuItems.logout")}</span>
              </div>
            </div>
          </div>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("navbar.menuItems.warning")}</AlertDialogTitle>
            <AlertDialogDescription>
              {t("navbar.menuItems.logoutWarning")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="sm:mt-0">
              {t("navbar.menuItems.cancel")}
            </AlertDialogCancel>
            <Button onClick={logout} className="bg-red-600 hover:bg-red-700">
              {t("navbar.menuItems.logout")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </nav>
  );
};

export default Navbar;

const NavItem = ({ children, hasSubNav, link }) => {
  const [openSubNav, setOpenSubNav] = useState(false);
  const location = useLocation();

  const isActive = (url) => {
    return location.pathname === url;
  };
  const handleSubNavClick = (e) => {
    e.stopPropagation();

    if (hasSubNav) {
      setOpenSubNav(!openSubNav);
    }
  };
  return (
    <div
      role="button"
      tabIndex="0"
      onClick={(e) => {
        handleSubNavClick(e);
      }}
      className={cn("flex flex-col gap-2 rounded-lg", {
        "bg-[#CCDBFA] text-[#002573]": isActive(link),
      })}
    >
      <NavLink
        to={link || "#"}
        className={cn(
          "flex items-center rounded-lg px-5 py-4 font-medium text-[#111729] hover:bg-gray-100",
        )}
      >
        <div className="flex items-center gap-3">{children}</div>
        {hasSubNav && (
          <DownArrow
            className={`ml-auto transform transition-transform ${
              openSubNav ? "rotate-180" : ""
            }`}
          />
        )}
      </NavLink>
      {hasSubNav && openSubNav && (
        <ul className="flex flex-col gap-2 pl-5">
          {hasSubNav.map((item) => (
            <li key={item.name}>
              <NavItem key={item.name} link={item.link}>
                {item.name}
              </NavItem>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
