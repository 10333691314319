import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import statsService from "@/services/statsService";

import ExportIcon from "@/assets/icons/export.svg?react";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

const WarrantyChartSection = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const [selectedDate, setSelectedDate] = useState(4);
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  function transformData(backendData) {
    return {
      dateIntervalString: backendData.dateIntervalString,
      labels: backendData.labels,
      datasets: backendData.datasets.map((dataset) => ({
        label: t(dataset.label),
        data: dataset.counts,
        backgroundColor: getBackgroundColor(dataset.label), // Renklendirmek için bir fonksiyon
      })),
    };
  }

  function getBackgroundColor(label) {
    const colors = {
      "overviewPage.product": "#0037ADB2",
      "overviewPage.productfromintegration": "#6CE9A6B2",
    };
    return colors[label] || "#000000B2"; // Varsayılan renk
  }

  const fetchData = async () => {
    setLoading(true);

    const intervalSettings = {
      1: { interval: 7, temporalUnitEnum: "DAY" },
      2: { interval: 1, temporalUnitEnum: "MONTH" },
      3: { interval: 3, temporalUnitEnum: "MONTH" },
      4: { interval: 6, temporalUnitEnum: "MONTH" },
      5: { interval: 1, temporalUnitEnum: "YEAR" },
    };

    const { interval, temporalUnitEnum } = intervalSettings[selectedDate] || {
      interval: 7,
      temporalUnitEnum: "DAY",
    };

    await statsService
      .getSendedWarranties({
        interval,
        temporalUnitEnum,
      })
      .then((response) => {
        setData(transformData(response.data));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onExport = async () => {
    setIsSent(true);
    const intervalSettings = {
      1: { interval: 7, temporalUnitEnum: "DAY" },
      2: { interval: 1, temporalUnitEnum: "MONTH" },
      3: { interval: 3, temporalUnitEnum: "MONTH" },
      4: { interval: 6, temporalUnitEnum: "MONTH" },
      5: { interval: 1, temporalUnitEnum: "YEAR" },
    };

    const { interval, temporalUnitEnum } = intervalSettings[selectedDate] || {
      interval: 7,
      temporalUnitEnum: "DAY",
    };

    statsService
      .exportWarranties({
        interval,
        temporalUnitEnum,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href;
        link.setAttribute("href", url);
        link.setAttribute("download", "warranty.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsSent(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  return (
    <div className="flex flex-col gap-4">
      <header className="flex w-full items-center justify-between">
        <h3 className="text-lg font-medium text-[#111729]">
          {t("overviewPage.warrantySendings")}
        </h3>
        <div className="flex items-center gap-4">
          <span className="text-sm font-medium text-[#677389]">
            {data.dateIntervalString}
          </span>
          <div className="bg-white">
            <Select
              onValueChange={(value) => {
                setSelectedDate(value);
              }}
              value={selectedDate.toString()}
            >
              <SelectTrigger>
                <SelectValue placeholder={t("overviewPage.selectDate")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"1"}>{t("date.lastWeek")}</SelectItem>
                <SelectItem value={"2"}>{t("date.lastMonth")}</SelectItem>
                <SelectItem value={"3"}>{t("date.last3Months")}</SelectItem>
                <SelectItem value={"4"}>{t("date.last6Months")}</SelectItem>
                <SelectItem value={"5"}>{t("date.lastYear")}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button disabled={isSent} variant="secondaryGray" onClick={onExport}>
            <ExportIcon />
            {t("common.export")}
          </Button>
        </div>
      </header>
      <section className="rounded-lg border bg-white px-[120px] py-9">
        <Bar options={options} data={data} />
      </section>
    </div>
  );
};

export default WarrantyChartSection;
