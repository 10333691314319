/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import individualCustomersService from "@/services/individualCustomersService";

import * as z from "zod";

const RemindWarrantySheet = ({ selectedClientId, onOpenChange, open }) => {
  const { t } = useTranslation();
  const [reminderType, setReminderType] = useState("PHONE");
  const { toast } = useToast();

  const formSchema = z.object({
    email: z
      .string()
      .email({
        message: t("loginForm.validateMsg.emailInvalid"),
      })
      .min(2, {
        message: t("loginForm.validateMsg.emailMin"),
      })
      .max(50, {
        message: t("loginForm.validateMsg.emailMax"),
      }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  function onSubmit(body) {
    body.type = reminderType;

    individualCustomersService
      .remindIndividualCustomer(selectedClientId, body)
      .then((res) => {
        onOpenChange(false);
        console.log("Result =>", res);
        toast({
          title: t("clientsPage.reminderSent"),
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("clientsPage.remindWarranty")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("clientsPage.reminderType")}</Label>
          <Select
            onValueChange={(value) => {
              setReminderType(value);
            }}
            defaultValue={reminderType}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {[
                { name: t("clientsPage.phone"), value: "PHONE" },
                { name: t("clientsPage.email"), value: "EMAIL" },
              ].map((type, index) => (
                <SelectItem key={index} value={type.value}>
                  {type.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {reminderType === "EMAIL" ? (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-5"
              >
                <div className="flex flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("clientsPage.email")}</Label>{" "}
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <SheetFooter className="mt-4">
                  <Button
                    onClick={() => {
                      form.handleSubmit(onSubmit);
                    }}
                  >
                    {t("common.save")}
                  </Button>
                </SheetFooter>
              </form>
            </Form>
          ) : (
            <SheetFooter className="mt-4">
              <Button
                onClick={() => {
                  onSubmit({ type: "PHONE" });
                }}
              >
                {t("common.save")}
              </Button>
            </SheetFooter>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default RemindWarrantySheet;
