import { Tag } from "@egaranti/components";

import TrashIcon from "../../assets/icons/trash.svg?react";

import useProductsFromIntegrationsStore from "@/stores/useProductsFromIntegrationsStore";

const FilterTags = () => {
  const { filters, setFilters, getProducts } =
    useProductsFromIntegrationsStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, name: "" });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.productCode && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.productCode}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, productCode: "" });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.brand && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.brand}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, brand: "" });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.source && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.source}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, source: "" });
              getProducts();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
