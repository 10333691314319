/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useServiceRecordsStore from "@/stores/useServiceRecordsStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { filters, getClaims, setFilters } = useServiceRecordsStore();

  const [filtersInSheet, setFiltersInSheet] = useState({
    individualCustomerProductName: "",
    firstName: "",
    lastName: "",
    state: "",
    reason: "",
    claimNo: null,
  });

  useEffect(() => {
    setFiltersInSheet({
      individualCustomerProductName: filters.individualCustomerProductName,
      firstName: filters.firstName,
      lastName: filters.lastName,
      state: filters.state,
      reason: filters.reason,
      claimNo: filters.claimNo,
    });
  }, [filters]);

  const onSaveFilter = () => {
    onOpenChange(false);
    setFilters({
      ...filters,
      ...filtersInSheet,
      page: 1,
    });
    getClaims();
  };

  const handleFieldChange = (fieldName, value) => {
    setFiltersInSheet({
      ...filtersInSheet,
      [fieldName]: value,
    });
  };

  const REASON_OPTIONS = [
    {
      id: 1,
      value: "FAULT",
      name: t("claimsPage.fault"),
    },
    {
      id: 2,
      value: "INSTALLATION",
      name: t("claimsPage.setup"),
    },
  ];

  const STATE_OPTIONS = [
    {
      id: 1,
      value: "GUARANTEED",
      name: t("claimsPage.guaranteed"),
    },
    {
      id: 2,
      value: "NOT_GUARANTEED",
      name: t("claimsPage.notGuaranteed"),
    },
  ];

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("claimsPage.requestNumber")}</Label>
          <Input
            type="number"
            value={filtersInSheet.claimNo}
            onChange={(e) => handleFieldChange("claimNo", e.target.value)}
          />
          <Label>{t("claimsPage.productName")}</Label>
          <Input
            value={filtersInSheet.individualCustomerProductName}
            onChange={(e) =>
              handleFieldChange("individualCustomerProductName", e.target.value)
            }
          />
          <Label>{t("common.firstName")}</Label>
          <Input
            value={filtersInSheet.firstName}
            onChange={(e) => handleFieldChange("firstName", e.target.value)}
          />
          <Label>{t("common.lastName")}</Label>
          <Input
            value={filtersInSheet.lastName}
            onChange={(e) => handleFieldChange("lastName", e.target.value)}
          />
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.reasonForApplication")}</Label>
            <Select
              value={filtersInSheet.reason}
              onValueChange={(value) => handleFieldChange("reason", value)}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {REASON_OPTIONS.map((option) => (
                  <SelectItem key={option.id} value={option.value}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.warrantyStatus")}</Label>
            <Select
              value={filtersInSheet.state}
              onValueChange={(value) => handleFieldChange("state", value)}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {STATE_OPTIONS.map((option) => (
                  <SelectItem key={option.id} value={option.value}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
