import { Tag } from "@egaranti/components";

import TrashIcon from "../../../assets/icons/trash.svg?react";

import useServiceRecordsStore from "@/stores/useServiceRecordsStore";

const FilterTags = () => {
  const { filters, setFilters, getClaims } = useServiceRecordsStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.claimNo && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.claimNo}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, claimNo: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.firstName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.firstName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, firstName: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.individualCustomerProductName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.individualCustomerProductName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, individualCustomerProductName: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.lastName && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.lastName}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, lastName: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.reason && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.reason}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, reason: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
      {filters.state && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.state}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, state: "" });
              getClaims();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
