/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  useToast,
} from "@egaranti/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";
import { FileLink } from "@egaranti/components";
import { FileArea } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import serviceClaimsService from "@/services/serviceClaimsService";

const Messages = ({ claimId, status }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [data, setData] = useState({});
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMessages = () => {
    serviceClaimsService.getMessages(claimId).then((res) => {
      setMessages(res);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getMessages(), []);

  const onSubmitMessage = () => {
    if (loading) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("multipartFile", data?.file);
    serviceClaimsService
      .postMessage(claimId, data?.message, formData)
      .then(() => {
        getMessages();
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
    setLoading(false);
  };

  return (
    <div className="container mx-auto mb-10">
      <div className="formBox">
        <h2 className="mb-5 text-lg font-medium">{t("claimsPage.messages")}</h2>
        <div className="flex flex-col gap-4">
          {messages.map((message, index) => (
            <MessageBox key={index} message={message} />
          ))}
        </div>
        <div className="flex justify-end pt-5">
          <AlertDialog>
            <AlertDialogTrigger>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      disabled={status === "REJECTED" || status === "APPROVED"}
                    >
                      {t("claimsPage.sendMessage")}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {status === "REJECTED"
                      ? t("claimsPage.rejected")
                      : t("claimsPage.approved")}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {t("claimsPage.replyCustomer")}
                </AlertDialogTitle>
              </AlertDialogHeader>
              <div className="flex flex-col gap-4">
                <Textarea
                  onChange={(e) => {
                    setData({ ...data, message: e.target.value });
                  }}
                  placeholder={t("claimsPage.enterMessage")}
                />
                <FileArea
                  onFileUpload={(file) => {
                    setData({ ...data, file });
                  }}
                  title={t("claimsPage.uploadDocument")}
                />
              </div>
              <AlertDialogFooter>
                <AlertDialogCancel>{t("claimsPage.reject")}</AlertDialogCancel>
                <AlertDialogAction onClick={onSubmitMessage}>
                  {t("claimsPage.send")}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
    </div>
  );
};

export default Messages;

const MessageBox = ({ message }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-[#F9FAFC] p-5">
      <div className="flex items-center gap-4">
        <h3 className="text-sm font-semibold text-[#111729]">
          {message.sender === "CUSTOMER"
            ? message.individualCustomerFullName
            : message.merchantName}
        </h3>
        <span className="text-xs text-[#677389]">{message?.createdAt}</span>
      </div>
      <p className="text-sm font-medium text-[#111729]">{message.message}</p>
      {message.path && <FileLink name="Dosya Eki" url={message.path} />}
    </div>
  );
};
