import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ExportIcon from "../../assets/icons/export.svg?react";
import FilterIcon from "../../assets/icons/filter.svg?react";
import ImportIcon from "../../assets/icons/import.svg?react";
import PlusIcon from "../../assets/icons/plus.svg?react";

import productService from "@/services/productService";

import useProductsStore from "@/stores/useProductsStore";

import ImportProductSheet from "@/components/individualCustomers/importProductSheet";
import FilterSheet from "@/components/products/filterSheet";
import FilterTags from "@/components/products/filterTags";
import SendWarrantySheet from "@/components/products/sendWarrantySheet";
import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [exportLoading, setExportLoading] = useState(false);
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [openSendWarrantySheet, setOpenSendWarrantySheet] = useState(false);
  const [openImportProductSheet, setOpenImportProductSheet] = useState(false);

  const { products, loading, filters, getProducts, setFilters } =
    useProductsStore();

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    const newFilters = { ...filters, page };
    setFilters(newFilters);

    getProducts();
  };

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    const newFilters = { ...filters, size, page: 1 };
    setFilters(newFilters);

    getProducts();
  };

  const onClickItem = (e, id) => {
    e.stopPropagation();
    navigate(`/products/products/${id}`);
  };

  const onClickImport = () => setOpenImportProductSheet(true);

  const onClickExport = () => {
    setExportLoading(true);
    productService.exportProducts(filters).then((res) => {
      toast({
        description: t("productsPage.exportSuccess"),
        variant: "success",
      });
    });
  };

  const onClickSendWarranty = (e, id) => {
    e.stopPropagation();
    navigate(`?id=${id}`);
    setOpenSendWarrantySheet(true);
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end gap-4 pb-4">
        <Button
          disabled={exportLoading}
          variant="secondaryGray"
          onClick={onClickExport}
        >
          <ExportIcon />
          {t("common.export")}
        </Button>
        <Button variant="secondaryGray" onClick={onClickImport}>
          <ImportIcon />
          {t("common.import")}
        </Button>
        <Button
          className="font-medium"
          onClick={() => navigate("/products/products/create")}
        >
          <PlusIcon />
          <span>{t("common.addProduct")}</span>
        </Button>
      </div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              className="min-w-[150px] font-medium"
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={4} />
            </Table>
          ) : products.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("productsPage.productName")}</TableHead>
                <TableHead>{t("productsPage.brand")}</TableHead>
                <TableHead>{t("productsPage.category")}</TableHead>
                <TableHead>{t("productsPage.warrantyDocument")}</TableHead>
                <TableHead>{t("productsPage.warranty")}</TableHead>
              </TableHeader>
              {loading && <TableSkeleton colSpan={4} />}
              <TableBody>
                {products?.map((product) => (
                  <TableRow
                    role="button"
                    onClick={(e) => onClickItem(e, product.id)}
                    key={product.id}
                  >
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.brand}</TableCell>
                    <TableCell>
                      <Tag size="sm">{product.categoryTree}</Tag>
                    </TableCell>
                    <TableCell>
                      <WarrantDocumentCell product={product} />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={(e) => onClickSendWarranty(e, product.id)}
                        variant="secondaryColor"
                      >
                        {t("productsPage.sendWarranty")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("productsPage.productCouldNotFound")}
              desc={t("productsPage.productCouldNotFoundDesc")}
            />
          )}
          <div className="flex flex-col items-center">
            {filters.totalPages > 1 && (
              <Pagination
                totalPages={filters.totalPages}
                currentPage={filters.page}
                pageSize={filters.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            {!loading && (
              <PageSizeSelect
                onPageSizeChange={onPageSizeChange}
                value={filters.size}
              />
            )}
          </div>
        </div>
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
      <SendWarrantySheet
        open={openSendWarrantySheet}
        onOpenChange={setOpenSendWarrantySheet}
      />
      <ImportProductSheet
        open={openImportProductSheet}
        onOpenChange={setOpenImportProductSheet}
      />
    </main>
  );
};

export default Products;

const WarrantDocumentCell = ({ product }) => {
  const { t } = useTranslation();
  if (product.warrantyDocumentId) {
    return (
      <div className="flex items-center gap-2 text-sm font-medium text-[#111729]">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="20" fill="#CCDBFA" />
          <path
            d="M21.6668 11.8915V15.3337C21.6668 15.8004 21.6668 16.0337 21.7577 16.212C21.8376 16.3688 21.965 16.4963 22.1218 16.5762C22.3001 16.667 22.5335 16.667 23.0002 16.667H26.4423M26.6668 18.3238V24.3337C26.6668 25.7338 26.6668 26.4339 26.3943 26.9686C26.1547 27.439 25.7722 27.8215 25.3018 28.0612C24.767 28.3337 24.067 28.3337 22.6668 28.3337H17.3335C15.9334 28.3337 15.2333 28.3337 14.6985 28.0612C14.2281 27.8215 13.8457 27.439 13.606 26.9686C13.3335 26.4339 13.3335 25.7338 13.3335 24.3337V15.667C13.3335 14.2669 13.3335 13.5668 13.606 13.032C13.8457 12.5616 14.2281 12.1792 14.6985 11.9395C15.2333 11.667 15.9334 11.667 17.3335 11.667H20.01C20.6215 11.667 20.9272 11.667 21.2149 11.7361C21.47 11.7973 21.7139 11.8983 21.9375 12.0354C22.1898 12.19 22.406 12.4062 22.8384 12.8386L25.4953 15.4954C25.9276 15.9278 26.1438 16.144 26.2984 16.3963C26.4355 16.62 26.5365 16.8638 26.5978 17.1189C26.6668 17.4066 26.6668 17.7124 26.6668 18.3238Z"
            stroke="#0049E6"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        {product.warrantyDocumentName}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 text-sm font-medium text-[#111729]">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#F2F5F9" />
        <path
          d="M21.6668 11.8915V15.3337C21.6668 15.8004 21.6668 16.0337 21.7577 16.212C21.8376 16.3688 21.965 16.4963 22.1218 16.5762C22.3001 16.667 22.5335 16.667 23.0002 16.667H26.4423M26.6668 18.3238V24.3337C26.6668 25.7338 26.6668 26.4339 26.3943 26.9686C26.1547 27.439 25.7722 27.8215 25.3018 28.0612C24.767 28.3337 24.067 28.3337 22.6668 28.3337H17.3335C15.9334 28.3337 15.2333 28.3337 14.6985 28.0612C14.2281 27.8215 13.8457 27.439 13.606 26.9686C13.3335 26.4339 13.3335 25.7338 13.3335 24.3337V15.667C13.3335 14.2669 13.3335 13.5668 13.606 13.032C13.8457 12.5616 14.2281 12.1792 14.6985 11.9395C15.2333 11.667 15.9334 11.667 17.3335 11.667H20.01C20.6215 11.667 20.9272 11.667 21.2149 11.7361C21.47 11.7973 21.7139 11.8983 21.9375 12.0354C22.1898 12.19 22.406 12.4062 22.8384 12.8386L25.4953 15.4954C25.9276 15.9278 26.1438 16.144 26.2984 16.3963C26.4355 16.62 26.5365 16.8638 26.5978 17.1189C26.6668 17.4066 26.6668 17.7124 26.6668 18.3238Z"
          stroke="#677389"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {t("productsPage.noWarrantyDocument")}
    </div>
  );
};
