import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Label,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

import SelectDocument from "@/components/products/edit/selectDocument";

const ExistingDocumentForm = ({ control, setValue, trigger, type }) => {
  const { t } = useTranslation();

  return (
    <FormField
      control={control}
      name="selectedDocument"
      render={({ field }) => (
        <FormItem>
          <Label>{t("settingsPage.document")}</Label>
          <FormControl>
            <SelectDocument
              type={type}
              value={field.value}
              setValue={(value) => {
                setValue("selectedDocument", value);
                trigger("selectedDocument");
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ExistingDocumentForm;
