import { Tag } from "@egaranti/components";

import TrashIcon from "../../../assets/icons/trash.svg?react";

import useSellerStore from "@/stores/useSellerStore";

const FilterTags = () => {
  const { filters, getSellers, setFilters } = useSellerStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.title && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.title}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, title: "" });
              getSellers();
            }}
          />
        </Tag>
      )}
      {filters.phone && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.phone}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, phone: "" });
              getSellers();
            }}
          />
        </Tag>
      )}
      {filters.email && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.email}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, email: "" });
              getSellers();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
