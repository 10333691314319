import { useToast } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@egaranti/components";

import SetupClaimsService from "@/services/setupClaimsService";

// eslint-disable-next-line react/prop-types
const RejectAppointment = ({ onOpenChange, open, data }) => {
  const { toast } = useToast();
  const onSubmit = () => {
    SetupClaimsService.updateClaim(data.id, {
      merchantNote: data.merchantNote,
      setupClaimStatus: "REJECTED",
    }).then(() => {
      toast({
        variant: "success",
        title: t("common.success"),
      });
      onOpenChange(false);
    });
  };
  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Talebi Reddetmek İstediğinize Emin Misiniz?
          </AlertDialogTitle>
          <AlertDialogDescription>
            <br />
            <br />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onOpenChange(false)}>
            Vazgeç
          </AlertDialogCancel>
          <AlertDialogAction onClick={onSubmit}>
            Talebi Reddet
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RejectAppointment;
