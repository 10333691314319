/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import additionaPackagesService from "@/services/additionaPackagesService";

const PacketOptions = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    additionaPackagesService.getMaintenancePackagesForPlan(id).then((res) => {
      setPackages(res.data);
    });
  }, []);

  const addFeature = (item) => {
    setPackages(
      packages.map((i) => {
        if (i === item) {
          return {
            ...i,
            features: [...i.features, ""],
          };
        }
        return i;
      }),
    );
  };

  const onChangeFeature = (item, featureIndex, value) => {
    setPackages(
      packages.map((i) => {
        if (i === item) {
          return {
            ...i,
            features: i.features.map((feature, index) => {
              if (index === featureIndex) {
                return value;
              }
              return feature;
            }),
          };
        }
        return i;
      }),
    );
  };

  const onChangeField = (item, field, value) => {
    setPackages(
      packages.map((i) => {
        if (i === item) {
          return {
            ...i,
            [field]: value,
          };
        }
        return i;
      }),
    );
  };

  const deleteFeature = (item, featureIndex) => {
    // Ensure there's at least one feature remaining
    if (item.features.length > 1) {
      setPackages((prevPackages) => {
        return prevPackages.map((i) => {
          if (i === item) {
            return {
              ...i,
              features: [
                ...i.features.slice(0, featureIndex),
                ...i.features.slice(featureIndex + 1),
              ],
            };
          }
          return i;
        });
      });
    }
  };

  const deletePackage = (item) => {
    setPackages(packages.filter((i) => i !== item));

    if (item.id === undefined) {
      toast({
        title: t("common.success"),
        description: "Paket başarıyla silindi.",
        variant: "success",
      });
      return;
    }

    additionaPackagesService.deleteMaintenancePackage(item.id).then((res) => {
      toast({
        description: t("additionalServicesPage.packetDeletedSuccessfully"),
        variant: "success",
      });
    });
  };

  const onAddPackage = () => {
    setPackages([
      ...packages,
      { features: [""], price: 0, suggested: false, period: 0 },
    ]);
  };

  const onSavePackage = (item) => {
    if (item.id) {
      additionaPackagesService
        .updateMaintenancePackage(item.id, item)
        .then(() => {
          toast({
            description: t("additionalServicesPage.packetUpdatedSuccessfully"),
            variant: "success",
          });
        })
        .catch((err) => {
          toast({
            title: err.response?.data?.errors[0]?.message,
            variant: "error",
          });
        });
    } else {
      additionaPackagesService
        .createMaintenancePackage(id, item)
        .then((res) => {
          toast({
            title: t("common.success"),
            description: t("additionalServicesPage.packetCreatedSuccessfully"),
            variant: "success",
          });
        })
        .catch((err) => {
          toast({
            title: err.response?.data?.errors[0]?.message,
            variant: "error",
          });
        });
    }
  };

  return (
    <div className="container mx-auto">
      <div className="formBox">
        {packages.map((item, index) => (
          <div key={index} className="grid grid-cols-3 gap-4 border-b py-9">
            <div className="flex flex-col gap-2">
              <Label>{t("additionalServicesPage.title")}</Label>
              <Input
                required
                onChange={(e) => onChangeField(item, "title", e.target.value)}
                value={item.title}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>{t("additionalServicesPage.price")}</Label>
              <Input
                required
                onChange={(e) => onChangeField(item, "price", e.target.value)}
                value={item.price}
                placeholder="Fiyat"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>{t("additionalServicesPage.duration")}</Label>
              <Input
                required
                onChange={(e) => onChangeField(item, "period", e.target.value)}
                value={item.period}
                placeholder="Süre"
              />
            </div>
            <div className="col-span-3 mt-6 flex flex-col gap-4">
              <div className="flex flex-col">
                <div className="flex flex-col gap-4">
                  {item.features.map((feature, featureIndex) => (
                    <div
                      key={featureIndex}
                      className="flex w-1/2 flex-col gap-3"
                    >
                      <Label>
                        {featureIndex + 1}.{" "}
                        {t("additionalServicesPage.article")}
                      </Label>
                      <div className="flex items-center gap-2">
                        <Input
                          value={feature}
                          onChange={(e) =>
                            onChangeFeature(item, featureIndex, e.target.value)
                          }
                        />

                        <Button
                          variant="secondaryGray"
                          onClick={() => deleteFeature(item, featureIndex)}
                          className="border-none bg-transparent font-medium text-red-500 shadow-none"
                          disabled={item.features.length === 1}
                        >
                          {t("common.delete")}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <button
                    className="mt-4 flex items-center gap-2 text-gray-600 disabled:opacity-15"
                    onClick={() => addFeature(item)}
                    disabled={item.features.length === 4}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_4364_35624)">
                        <path
                          d="M10.0003 6.6665V13.3332M6.66699 9.99984H13.3337M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z"
                          stroke="currentColor"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4364_35624">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {t("additionalServicesPage.addArticle")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-span-3 mt-6 flex items-center justify-between">
              <div className="flex flex-col gap-3">
                <Label className="flex items-center gap-2">
                  <Checkbox
                    onCheckedChange={(value) => {
                      setPackages(
                        packages.map((i) => {
                          if (i === item) {
                            return {
                              ...i,
                              suggested: value,
                            };
                          }
                          return i;
                        }),
                      );
                    }}
                    checked={item.suggested}
                  />
                  {t("additionalServicesPage.suggested")}
                </Label>
              </div>
              <div className="flex items-center gap-2">
                <DeletePackageDialog on={() => deletePackage(item)} />
                <Button onClick={() => onSavePackage(item)}>
                  {t("common.save")}
                </Button>
              </div>
            </div>
          </div>
        ))}
        {packages.length < 4 && (
          <Button
            variant="secondaryGray"
            className="mt-5 border-none text-[#0040C9] shadow-none"
            onClick={onAddPackage}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4364_35624)">
                <path
                  d="M10.0003 6.6665V13.3332M6.66699 9.99984H13.3337M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z"
                  stroke="#0040C9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4364_35624">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("additionalServicesPage.addPacket")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PacketOptions;

const DeletePackageDialog = ({ on }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Button
          variant="secondaryGray"
          className="border-none text-red-500 shadow-none"
        >
          {t("common.delete")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("additionalServicesPage.deleteWarning")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={on}>
            {t("common.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
