import corporateCustomersService from "@/services/corporateCustomersService";

import { create } from "zustand";

const useCorporateCustomerStore = create((set) => ({
  customers: [],
  loading: false,
  products: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    name: "",
    email: "",
    vkn: "",
  },
  getCorporateCustomers: async () => {
    set({ loading: true });

    const data = await corporateCustomersService.getCorparateCustomers(
      useCorporateCustomerStore.getState().filters,
    );

    set({
      customers: data.content,
      loading: false,
      filters: {
        ...useCorporateCustomerStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useCorporateCustomerStore;
