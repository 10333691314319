import additionaPackagesService from "@/services/additionaPackagesService";

import { create } from "zustand";

const useAdditionalPackagesStore = create((set) => ({
  loading: false,
  extraWarrantyPlans: [],
  maintenancePlans: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
    activated: null,
  },
  maintenanceFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    activated: null,
  },
  extraWarrantyUnlinkedProducts: [],
  extraWarrantyLinkedProducts: [],
  extraWarrantyUnlinkedFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
  },
  extraWarrantyLinkedFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
  },
  maintenanceUnlinkedProducts: [],
  maintenanceLinkedProducts: [],
  maintenanceUnlinkedFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
  },
  maintenanceLinkedFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    title: "",
  },
  setFilters: (filters) => {
    set({ filters });
  },
  setMaintenanceFilters: (maintenanceFilters) => {
    set({ maintenanceFilters });
  },
  getExtraWarrantyPlans: async () => {
    set({ loading: true });

    const { data } = await additionaPackagesService.getExtraWarrantyPlans(
      useAdditionalPackagesStore.getState().filters,
    );

    set(() => ({
      extraWarrantyPlans: data.content,
      loading: false,
    }));
  },
  getExtraWarrantyUnlinkedProducts: async () => {
    set({ loading: true });

    const { data } =
      await additionaPackagesService.getExtraWarrantyUnlinkedProducts(
        useAdditionalPackagesStore.getState().extraWarrantyUnlinkedFilters,
      );

    set(() => ({
      loading: false,
      extraWarrantyUnlinkedProducts: data,
    }));
  },
  setExtraWarrantyUnlinkedFilters: (extraWarrantyUnlinkedFilters) => {
    set({ extraWarrantyUnlinkedFilters });
  },
  getExtraWarrantyLinkedProducts: async (id) => {
    set({ loading: true });
    const { data } =
      await additionaPackagesService.getExtraWarrantyLinkedProducts(
        id,
        useAdditionalPackagesStore.getState().extraWarrantyLinkedFilters,
      );

    set(() => ({
      loading: false,
      extraWarrantyLinkedProducts: data,
    }));
  },
  setExtraWarrantyLinkedFilters: (extraWarrantyLinkedFilters) => {
    set({ extraWarrantyLinkedFilters });
  },
  getMaintenancePlans: async () => {
    const { data } = await additionaPackagesService.getMaintenancePlans(
      useAdditionalPackagesStore.getState().maintenanceFilters,
    );

    set(() => ({
      maintenancePlans: data.content,
    }));
  },
  getMaintenancePackagesForPlan: async (id) => {
    const { data } =
      await additionaPackagesService.getMaintenancePackagesForPlan(id);

    set(() => ({
      maintenancePackages: data.content,
    }));
  },
  getMaintenanceLinkedProducts: async (id) => {
    set({ loading: true });
    const { data } =
      await additionaPackagesService.getMaintenanceLinkedProducts(
        id,
        useAdditionalPackagesStore.getState().maintenanceLinkedFilters,
      );

    set(() => ({
      loading: false,
      maintenanceLinkedProducts: data,
    }));
  },
  setMaintenanceLinkedFilters: (maintenanceLinkedFilters) => {
    set({ maintenanceLinkedFilters });
  },
  getMaintenanceUnlinkedProducts: async () => {
    set({ loading: true });
    const { data } =
      await additionaPackagesService.getMaintenanceUnlinkedProducts(
        useAdditionalPackagesStore.getState().maintenanceUnlinkedFilters,
      );

    set(() => ({
      loading: false,
      maintenanceUnlinkedProducts: data,
    }));
  },
  setMaintenanceUnlinkedFilters: (maintenanceUnlinkedFilters) => {
    set({ maintenanceUnlinkedFilters });
  },
}));

export default useAdditionalPackagesStore;
