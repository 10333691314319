import GeneralSummarySection from "@/components/overview/generalSummarySection";
import TablesSection from "@/components/overview/tablesSection";
import WarrantyChartSection from "@/components/overview/warrantyChartSection";

const Overview = () => {
  return (
    <main className="flex h-max min-h-full flex-col gap-12 bg-[#F9FAFC] p-4">
      <GeneralSummarySection />
      <WarrantyChartSection />
      <TablesSection />
    </main>
  );
};

export default Overview;
