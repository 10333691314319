import Login from "@/pages/login";
import Register from "@/pages/register";
import ForgotPassword from "@/pages/forgotPassword";
import IdeaSoftLogin from '@/pages/login/ideaSoftLogin';

import Overview from "@/pages/overview";
import Inbox from "@/pages/inbox";
// Products
import Products from "@/pages/products";
import ProductEditPage from "@/pages/products/productEditPage";
import ProductCreatePage from "@/pages/products/productCreatePage";

// Products From Integrations
import ProductsFromIntegrations from "@/pages/productsFromIntegrations";
import ProductFromIntegrationsEditPage from "@/pages/productsFromIntegrations/productEditPage";

// Customers
import IndividualCustomers from "@/pages/individualCustomers";
import CustomerIndividualEditPage from "@/pages/individualCustomers/customerEditPage";
import CorporateCustomers from "@/pages/corporateCustomers";
import CustomerCorporateEditPage from "@/pages/corporateCustomers/customerEditPage";

// Claims
import ServiceClaims from "@/pages/claims/ServiceClaims";
import ServiceRecords from "@/pages/claims/ServiceRecords";
import SetupClaims from "@/pages/claims/SetupClaims";
import SetupClaimDetailPage from "@/pages/claims/SetupClaims/ClaimEditPage";
import ServiceClaimDetailPage from "@/pages/claims/ServiceClaims/ClaimEditPage";

import ServiceRecordDetailPage from "@/pages/claims/ServiceRecords/ClaimEditPage";
import ServiceRecordCreatePage from "@/pages/claims/ServiceRecords/ClaimCreatePage";

// Applications
import Applications from "@/pages/applications";
import ApplicationInstallPage from "@/pages/applications/applicationInstallPage";
import ApplicationEditPage from "@/pages/applications/applicationEditPage";

//
import InfobipInstallPage from "@/pages/applications/customized/infobip/applicationInstallPage";
import InfobipEditPage from "@/pages/applications/customized/infobip/applicationEditPage";

// Settings
import Settings from "@/pages/settings";
import AccountSettings from "@/pages/settings/accountSettings";
import Documents from "@/pages/settings/documents";
import DocumentEditPage from "@/pages/settings/documents/documentEditPage";
import DocumentCreatePage from "@/pages/settings/documents/documentCreatePage";
import Employees from "@/pages/settings/employees";
import CreateEmployee from "@/pages/settings/employees/createEmployee";
import EditEmployee from "@/pages/settings/employees/editEmployee";
import ActivationsPage from "@/pages/settings/activations";
import LanguageSettings from "@/pages/settings/language";
import ServiceActivationDetails from "@/pages/settings/activations/serviceActivation/activationDetails";
import ProductActivationDetails from "@/pages/settings/activations/productActivation/activationDetails";
import ProductActivationEdit from "@/pages/settings/activations/productActivation/activationEdit";
import ProductActivationCreate from "@/pages/settings/activations/productActivation/activationCreate";
import PaymentSettings from '@/pages/settings/payment';
import AddCreditCard from '@/pages/settings/payment/addCreditCard';

// AdditionalPackages
import ExtraWarranty from "@/pages/additionalPackages/extraWarranty";
import Maintenance from "@/pages/additionalPackages/maintenance";
import CreateExtraWarranty from "@/pages/additionalPackages/extraWarranty/planCreatePage";
import CreateMaintenance from "@/pages/additionalPackages/maintenance/planCreatePage";
import EditExtraWarranty from "@/pages/additionalPackages/extraWarranty/planEditPage";
import EditMaintenance from "@/pages/additionalPackages/maintenance/planEditPage";

import PrivateRoutes from "@/components/privateRoutes";
import MainLayout from "@/components/layout/mainLayout";

// Sellers
import Sellers from "@/pages/sellers";
import ViewSeller from "@/pages/sellers/ViewSeller";
import WaitingSellers from "@/pages/sellers/WaitingSellers";
import OnboardSeller from "@/pages/sellers/onboardSeller";
import { Checkout } from "@/pages/settings/payment/checkout";
import StatusPage from "@/pages/settings/payment/statusPage";
import GlobalError from "@/errorBoundary";

const routes = [
  {
    path: "/",
    element: <PrivateRoutes />,
    errorElement: <GlobalError />,
    children: [
      // PRIVATE ROUTES
      // Main Layout
      {
        path: "/",
        element: <MainLayout />,
        children: [
          // Overview
          { path: "/", element: <Overview /> },
          {
            path: "/inbox",
            element: <Inbox />,
          },
          // Products
          { path: "/products/products", element: <Products /> },
          {
            path: "/products/integrations",
            element: <ProductsFromIntegrations />,
          },
          // Customers
          { path: "/customers/individual", element: <IndividualCustomers /> },
          { path: "/customers/corporate", element: <CorporateCustomers /> },
          // Claims
          { path: "/claims/service", element: <ServiceClaims /> },
          { path: "/claims/setup", element: <SetupClaims /> },
          { path: "/claims/service-record", element: <ServiceRecords /> },
          // Applications
          { path: "/applications", element: <Applications /> },
          // Settings
          { path: "/settings", element: <Settings /> },
          { path: "/settings/account", element: <AccountSettings /> },
          { path: "/settings/documents", element: <Documents /> },
          { path: "/settings/employees", element: <Employees /> },
          { path: "/settings/activations", element: <ActivationsPage /> },
          {
            path: "/settings/language",
            element: <LanguageSettings />,
          },
          // Additional Services
          {
            path: "/additional-packages/extra-warranty",
            element: <ExtraWarranty />,
          },
          {
            path: "/additional-packages/maintenance",
            element: <Maintenance />,
          },
          // Sellers
          {
            path: "/sellers",
            element: <Sellers />,
          },
        ],
      },
      { path: "/products/products/:id", element: <ProductEditPage /> },
      { path: "/products/products/create", element: <ProductCreatePage /> },
      {
        path: "/products/products/sendWarranty/:id",
        element: <ProductEditPage />,
      },
      {
        path: "/products/integrations/:id",
        element: <ProductFromIntegrationsEditPage />,
      },
      // Customers
      {
        path: "/customers/individual/:id",
        element: <CustomerIndividualEditPage />,
      },
      {
        path: "/customers/corporate/:id",
        element: <CustomerCorporateEditPage />,
      },
      // Claims
      {
        path: "/claims/service/:id",
        element: <ServiceClaimDetailPage />,
      },
      {
        path: "/claims/setup/:id",
        element: <SetupClaimDetailPage />,
      },
      {
        path: "/claims/service-record/:id",
        element: <ServiceRecordDetailPage />,
      },
      {
        path: "/claims/service-record/create",
        element: <ServiceRecordCreatePage />,
      },
      // Applications
      { path: "/applications/infobip", element: <InfobipInstallPage /> },
      { path: "/applications/infobip/edit", element: <InfobipEditPage /> },

      { path: "/applications/:name", element: <ApplicationInstallPage /> },
      { path: "/applications/:name/edit", element: <ApplicationEditPage /> },
      // Documents
      { path: "/settings/documents/:id", element: <DocumentEditPage /> },
      { path: "/settings/documents/create", element: <DocumentCreatePage /> },
      // Settings
      { path: "/settings/employees/create", element: <CreateEmployee /> },
      { path: "/settings/employees/:id", element: <EditEmployee /> },
      {
        path: "/settings/activations/service",
        element: <ServiceActivationDetails />,
      },
      {
        path: "/settings/activations/product/:id",
        element: <ProductActivationEdit />,
      },
      {
        path: "/settings/activations/product/create",
        element: <ProductActivationCreate />,
      },
      {
        path: "/settings/activations/product",
        element: <ProductActivationDetails />,
      },
      {
        path: "/settings/payment",
        element: <PaymentSettings />,
      },
      {
        path: "/settings/payment/add-credit-card",
        element: <AddCreditCard/>,
      },
      {
        path: "/settings/payment/checkout",
        element: <Checkout/>,
      },
      {
        path: "/settings/payment/status",
        element: <StatusPage/>,
      },
      // Additional Services
      {
        path: "/additional-packages/extra-warranty/:id",
        element: <EditExtraWarranty />,
      },
      {
        path: "/additional-packages/maintenance/:id",
        element: <EditMaintenance />,
      },
      {
        path: "/additional-packages/extra-warranty/create",
        element: <CreateExtraWarranty />,
      },
      {
        path: "/additional-packages/maintenance/create",
        element: <CreateMaintenance />,
      },
      // Sellers
      {
        path: "/sellers/:id",
        element: <ViewSeller />,
      },
      {
        path: "/sellers/waiting",
        element: <WaitingSellers />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/idea-soft/login",
    element: <IdeaSoftLogin />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/request-demo",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/sellers/onboarding",
    element: <OnboardSeller />
  }
];

export default routes;
