/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useIndividualCustomerStore from "@/stores/useIndividualCustomerStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { filters, getIndividuaCustomers, setFilters } =
    useIndividualCustomerStore();
  const [filtersInSheet, setFiltersInSheet] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const onSaveFilter = () => {
    onOpenChange(false);
    setFilters({
      ...filters,
      ...filtersInSheet,
      page: 1,
    });
    getIndividuaCustomers();
  };

  useEffect(() => {
    setFiltersInSheet({
      firstName: filters.firstName,
      lastName: filters.lastName,
      email: filters.email,
      phone: filters.phone,
    });
  }, [filters]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("common.firstName")}</Label>
          <Input
            value={filtersInSheet.firstName}
            onChange={(e) =>
              setFiltersInSheet({
                ...filtersInSheet,
                firstName: e.target.value,
              })
            }
          />
          <Label>{t("common.lastName")}</Label>
          <Input
            value={filtersInSheet.lastName}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, lastName: e.target.value })
            }
          />
          <Label>{t("common.email")}</Label>
          <Input
            value={filtersInSheet.email}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, email: e.target.value })
            }
          />
          <Label>{t("common.phone")}</Label>
          <Input
            value={filtersInSheet.phone}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, phone: e.target.value })
            }
          />
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
