import { Button } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useCorporateCustomerStore from "@/stores/useCorporateCustomersStore";

import FilterSheet from "@/components/corporateCustomers/filterSheet";
import FilterTags from "@/components/corporateCustomers/filterTags";
import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import FilterIcon from "@/assets/icons/filter.svg?react";

const CorporateCustomers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const { getCorporateCustomers, loading, customers, filters, setFilters } =
    useCorporateCustomerStore();

  useEffect(() => {
    getCorporateCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getCorporateCustomers();
  };

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    setFilters({ ...filters, size, page: 1 });
    getCorporateCustomers();
  };

  const onClickItem = (e, id) => {
    e.stopPropagation();
    navigate(`/customers/corporate/${id}`);
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end pb-4"></div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
              className="min-w-[150px] font-medium"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={4} />
            </Table>
          ) : customers?.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("clientsPage.customer")}</TableHead>
                <TableHead>{t("clientsPage.email")}</TableHead>
                <TableHead>{t("clientsPage.taxNumber")}</TableHead>
                <TableHead>{t("clientsPage.status")}</TableHead>
              </TableHeader>
              <TableBody>
                {customers?.map((customer) => (
                  <TableRow
                    role="button"
                    onClick={(e) => onClickItem(e, customer.id)}
                    key={customer.id}
                  >
                    <TableCell>{customer.name}</TableCell>
                    <TableCell>{customer.email}</TableCell>
                    <TableCell>
                      <Tag size="sm">{customer.vkn}</Tag>
                    </TableCell>
                    <TableCell>
                      {customer.emailVerified ? (
                        <span className="rounded-full bg-[#CCDBFA] px-3 py-1 text-xs font-medium">
                          {t("clientsPage.approved")}{" "}
                        </span>
                      ) : (
                        <span className="rounded-full bg-[#FEF0C7] px-3 py-1 text-xs font-medium">
                          {t("clientsPage.unapproved")}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("clientsPage.clientCouldNotFound")}
              desc={t("clientsPage.clientCouldNotFoundDesc")}
            />
          )}
          <div className="flex flex-col items-center">
            {filters.totalPages > 1 && (
              <Pagination
                totalPages={filters.totalPages}
                currentPage={filters.page}
                pageSize={filters.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            {!loading && (
              <PageSizeSelect
                onPageSizeChange={onPageSizeChange}
                value={filters.size}
              />
            )}
          </div>
        </div>
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </main>
  );
};

export default CorporateCustomers;
