import { Tag } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TrashIcon from "../../../../../../assets/icons/trash.svg?react";

import ProductService from "@/services/productService";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

const FilterTags = () => {
  const { id } = useParams();

  const {
    extraWarrantyLinkedFilters,
    setExtraWarrantyLinkedFilters,
    getExtraWarrantyLinkedProducts,
  } = useAdditionalPackagesStore();

  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [brand, setBrand] = useState("");

  const getMainCategory = async () => {
    const { data } = await ProductService.getMainCategories();
    const mainCategory = data.find(
      (mainCategory) =>
        mainCategory.id == extraWarrantyLinkedFilters.mainCategoryId,
    );
    setMainCategory(mainCategory);
  };

  const getCategory = async () => {
    const { data } = await ProductService.getCategories(
      extraWarrantyLinkedFilters.mainCategoryId,
    );
    const category = data.find(
      (category) => category.id == extraWarrantyLinkedFilters.categoryId,
    );
    setCategory(category);
  };

  const getSubCategory = async () => {
    const { data } = await ProductService.getSubCategories(
      extraWarrantyLinkedFilters.categoryId,
    );
    const subCategory = data.find(
      (subCategory) =>
        subCategory.id == extraWarrantyLinkedFilters.subCategoryId,
    );
    setSubCategory(subCategory);
  };

  const getBrand = async () => {
    const { data } = await ProductService.getSubCategoryBrands(
      extraWarrantyLinkedFilters.subCategoryId,
    );
    const brand = data.find(
      (brand) => brand.id == extraWarrantyLinkedFilters.subCategoryBrandId,
    );
    setBrand(brand);
  };

  useEffect(() => {
    extraWarrantyLinkedFilters.mainCategoryId && getMainCategory();
    extraWarrantyLinkedFilters.categoryId && getCategory();
    extraWarrantyLinkedFilters.subCategoryId && getSubCategory();
    extraWarrantyLinkedFilters.subCategoryBrandId && getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraWarrantyLinkedFilters]);

  // categoryId: "4";
  // mainCategoryId: "1";
  // name: "qwe";
  // page: 1;
  // size : localStorage.getItem("pageSize") || 10;
  // subCategoryBrandId: 723;
  // subCategoryId: "18";
  // title: "";

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {extraWarrantyLinkedFilters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {extraWarrantyLinkedFilters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setExtraWarrantyLinkedFilters({
                ...extraWarrantyLinkedFilters,
                name: "",
              });
              getExtraWarrantyLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {extraWarrantyLinkedFilters.mainCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {mainCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setExtraWarrantyLinkedFilters({
                ...extraWarrantyLinkedFilters,
                mainCategoryId: "",
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getExtraWarrantyLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {extraWarrantyLinkedFilters.categoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {category.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setExtraWarrantyLinkedFilters({
                ...extraWarrantyLinkedFilters,
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getExtraWarrantyLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {extraWarrantyLinkedFilters.subCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {subCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setExtraWarrantyLinkedFilters({
                ...extraWarrantyLinkedFilters,
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getExtraWarrantyLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {extraWarrantyLinkedFilters.subCategoryBrandId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {brand.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setExtraWarrantyLinkedFilters({
                ...extraWarrantyLinkedFilters,
                subCategoryBrandId: "",
              });
              getExtraWarrantyLinkedProducts(id);
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
