/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ProductFromIntegrationsService from "@/services/productFromIntegrationsService";

import useProductsFromIntegrationsStore from "@/stores/useProductsFromIntegrationsStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { filters, getProducts, setFilters } =
    useProductsFromIntegrationsStore();
  const [sources, setSources] = useState([]);
  const [filtersInSheet, setFiltersInSheet] = useState({
    name: "",
    brand: "",
    productCode: "",
    source: "",
  });

  const onSaveFilter = () => {
    onOpenChange(false);
    setFilters({
      ...filters,
      ...filtersInSheet,
      page: 1,
    });
    getProducts();
  };

  const id = useSearchParams();

  useEffect(() => {
    ProductFromIntegrationsService.getSources(id).then((res) => {
      setSources(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFiltersInSheet({
      name: filters.name,
      brand: filters.brand,
      productCode: filters.productCode,
      source: filters.source,
    });
  }, [filters]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("productsPage.productName")}</Label>
          <Input
            placeholder={t("productsPage.productName")}
            value={filtersInSheet.name}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, name: e.target.value })
            }
          />
          <Label>{t("productsPage.brand")}</Label>
          <Input
            placeholder={t("productsPage.brand")}
            value={filtersInSheet.brand}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, brand: e.target.value })
            }
          />
          <Label>{t("productsPage.productCode")}</Label>
          <Input
            placeholder={t("productsPage.productCode")}
            value={filtersInSheet.productCode}
            onChange={(e) =>
              setFiltersInSheet({
                ...filtersInSheet,
                productCode: e.target.value,
              })
            }
          />
          <div className="flex flex-col gap-4">
            <Label>{t("productsPage.source")}</Label>
            <Select
              value={filtersInSheet.source}
              onValueChange={(value) =>
                setFiltersInSheet({ ...filtersInSheet, source: value })
              }
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {sources.map((source, index) => (
                  <SelectItem key={index} value={source}>
                    {source}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
