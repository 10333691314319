/* eslint-disable react/prop-types */
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import addressService from "@/services/addressService";

import * as z from "zod";

const AddAddressDialog = ({ open, onOpenChange }) => {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const form = useForm({
    resolver: zodResolver(
      z.object({
        title: z.string().min(1, t("payment.addAddressDialog.form.msg1")),
        firstName: z.string().min(1, t("payment.addAddressDialog.form.msg2")),
        lastName: z.string().min(1, t("payment.addAddressDialog.form.msg3")),
        cityId: z.string().min(1, t("payment.addAddressDialog.form.msg4")),
        districtId: z.string().min(1, t("payment.addAddressDialog.form.msg5")),
        addressLine: z.string().min(1, t("payment.addAddressDialog.form.msg6")),
        postCode: z.string().min(1, t("payment.addAddressDialog.form.msg7")),
        taxId: z
          .string()
          .regex(/^[0-9]*$/, t("payment.addAddressDialog.form.msg10"))
          .min(10, t("payment.addAddressDialog.form.msg9"))
          .max(11, t("payment.addAddressDialog.form.msg8")),
      }),
    ),
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      cityId: "",
      districtId: "",
      addressLine: "",
      postCode: "",
      taxId: "",
    },
  });

  useEffect(() => {
    addressService.getCities().then((res) => {
      setCities(res);
    });
  }, []);

  const onSelectCity = (id) => {
    addressService.getDistricts(id).then((res) => {
      setDistricts(res);
    });
  };

  const handleSave = (data) => {
    addressService.postAddress(data);
    form.reset();
    onOpenChange(false);
  };

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("payment.addAddressDialog.dialogTitle")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        {/* scroll area */}
        <div className="h-[500px] overflow-y-auto p-1">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSave)} className="">
              <div className="mx-auto mt-6 flex flex-col gap-5">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("payment.addAddressDialog.form.label1")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex flex-col items-center gap-6 md:flex-row">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>
                          {t("payment.addAddressDialog.form.label2")}
                        </Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>
                          {t("payment.addAddressDialog.form.label3")}
                        </Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="cityId"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("payment.addAddressDialog.form.label4")}</Label>
                      <Select
                        onValueChange={(value) => {
                          onSelectCity(value);
                          field.onChange(value);
                        }}
                        defaultValue={field.cityId}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {cities.map((item) => {
                            return (
                              <SelectItem key={item.id} value={String(item.id)}>
                                {item.name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="districtId"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("payment.addAddressDialog.form.label5")}</Label>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {districts.map((item) => {
                            return (
                              <SelectItem key={item.id} value={String(item.id)}>
                                {item.name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="addressLine"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("payment.addAddressDialog.form.label6")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="w-full">
                  <FormField
                    control={form.control}
                    name="postCode"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>
                          {t("payment.addAddressDialog.form.label7")}
                        </Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-full">
                  <FormField
                    control={form.control}
                    name="taxId"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>
                          {t("payment.addAddressDialog.form.label8")}
                        </Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </form>
          </Form>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel id="cancel" onClick={() => onOpenChange(false)}>
            {t("payment.addAddressDialog.btnCancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={form.handleSubmit(handleSave)}>
            {t("payment.addAddressDialog.btnSave")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AddAddressDialog;
