import { Tag } from "@egaranti/components";

import TrashIcon from "../../../assets/icons/trash.svg?react";

import useSellerStore from "@/stores/useSellerStore";

const FilterTags = () => {
  const { sellersWaitingFilters, getSellersWaiting, setSellersWaitingFilters } =
    useSellerStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {sellersWaitingFilters.title && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {sellersWaitingFilters.title}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setSellersWaitingFilters({ ...sellersWaitingFilters, title: "" });
              getSellersWaiting();
            }}
          />
        </Tag>
      )}
      {sellersWaitingFilters.phone && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {sellersWaitingFilters.phone}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setSellersWaitingFilters({ ...sellersWaitingFilters, phone: "" });
              getSellersWaiting();
            }}
          />
        </Tag>
      )}
      {sellersWaitingFilters.email && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {sellersWaitingFilters.email}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setSellersWaitingFilters({ ...sellersWaitingFilters, email: "" });
              getSellersWaiting();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
