import axios from "@/lib/axios";

class CorporateCustomersService {
  constructor() {
    this.api = axios;
  }

  async getCorparateCustomers(query) {
    const { data } = await this.api.get("/corporate-customers", {
      params: query,
    });

    return data;
  }

  async getCorporateCustomer(id) {
    const { data } = await this.api.get(`/corporate-customers/${id}`);

    return data;
  }

  async getCorporateCustomerProducts(id, page) {
    const { data } = await this.api.get(`/corporate-customers/${id}/products`, {
      page: page,
      size: localStorage.getItem("pageSize") || 10,
    });
    return data;
  }

  async deleteCorporateCustomerProduct(id, productId) {
    const { data } = await this.api.delete(
      `/corporate-customers/${id}/products/${productId}`,
    );

    return data;
  }
}

export default new CorporateCustomersService();
