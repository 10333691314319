import { Label } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import LangSwitch from "@/components/layout/langSwitch";

const LanguageSettings = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen bg-[#f9fafc] p-7">
      <Label>{t("titles.languageSettings")}</Label>
      <LangSwitch />
    </div>
  );
};

export default LanguageSettings;
