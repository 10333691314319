import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useAuthStore from "@/stores/useAuthStore";

import AuthHeader from "@/components/auth/authHeader";

import * as z from "zod";

const IdeaSoftLogin = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { ideaSoftLogin, isAuth } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const id = new URLSearchParams(window.location.search).get("id");
  const [showSuccess, setShowSuccess] = useState(true);

  useEffect(() => {
    if (!id) {
      setShowSuccess(false);
    }
  }, [id]);

  const formSchema = z.object({
    email: z
      .string()
      .min(2, {
        message: t("loginForm.validateMsg.emailMin"),
      })
      .max(50, {
        message: t("loginForm.validateMsg.emailMax"),
      }),
    password: z
      .string()
      .min(8, {
        message: t("loginForm.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("loginForm.validateMsg.passwordMax"),
      }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(values) {
    const body = { ...values, id };

    ideaSoftLogin(body)
      .then(() => {
        navigate(location.state?.from ? location.state.from : "/", {
          replace: true,
        });
        toast({
          variant: "success",
          description: t("loginForm.loginSuccess"),
        });
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      });
  }

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  if (!showSuccess) {
    return (
      <div className="flex min-h-screen w-full bg-[#F9FAFC]">
        <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
          <AuthHeader />
          <div className="flex h-full w-full items-center justify-center">
            <div className="md:shadow-default flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6">
              <div className="mb-2 flex flex-col items-center gap-3">
                <h2 className="text-[24px] font-semibold">Oppss</h2>
                <p className="text-center text-[16px] font-normal text-[#677389]">
                  {t("loginForm.verificationFail")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F9FAFC]">
      <AuthHeader />
      <main
        className="flex w-full items-center justify-center"
        style={{ minHeight: "calc(100vh - 7rem)" }}
      >
        <div className="md:shadow-custom flex min-w-[400px] flex-col gap-8 rounded-lg bg-transparent p-6 md:bg-white">
          <div className="flex flex-col gap-3">
            <h1 className="formTitle">{t("loginForm.title")}</h1>
            <p className="formDescription">{t("loginForm.description")}</p>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("loginForm.email")}</Label>
                    <FormControl>
                      <Input placeholder="admin@mail.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("loginForm.password")}</Label>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col gap-4">
                <Button type="submit">{t("common.login")}</Button>
                {/* <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/forgot-password");
                  }}
                  variant="secondaryGray"
                >
                  {t("loginForm.forgotPassword")}
                </Button> */}
              </div>
            </form>
          </Form>

          {/* <span className="formDescription mx-auto">
            {t("loginForm.notRegistered")}{" "}
            <Link to="/register" className="text-[#0049E6]">
              {t("common.register")}
            </Link>
          </span> */}
        </div>
      </main>
    </div>
  );
};

export default IdeaSoftLogin;
