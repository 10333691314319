import { Tag } from "@egaranti/components";

import TrashIcon from "../../assets/icons/trash.svg?react";

import useDocumentsStore from "@/stores/useDocumentsStore";

const FilterTags = () => {
  const { filters, getDocuments, setFilters } = useDocumentsStore();

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, name: "" });
              getDocuments();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
