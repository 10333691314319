import { useEffect, useState } from "react";

import applicationsService from "@/services/applicationsService";

import AppCard from "@/components/applications/appCard";

const Applications = () => {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [installed, setInstalled] = useState([]);

  useEffect(() => {
    applicationsService.getApplications().then((res) => {
      setApplications(res);
      setLoading(false);
    });

    // check installed
    applicationsService.checkInstalled().then((res) => setInstalled(res.data));
  }, []);

  const infoBip = {
    name: "Infobip",
    slug: "infobip",
    subtitle:
      "Infobip ile elektronik garanti belgesine geçin; baskı, arşivleme ve kargo masraflarından kurtulun; garanti belgelerini anında müşterileriniz ile paylaşın.",
    logoUrl:
      "https://cdn-web.infobip.com/uploads/2023/08/Infobip-place-holder-1.png",
    header:
      "Infobip ile elektronik garanti belgesine geçin; baskı, arşivleme ve kargo masraflarından kurtulun; garanti belgelerini anında müşterileriniz ile paylaşın.",
    description:
      "Infobip ile elektronik garanti belgesine geçin; baskı, arşivleme ve kargo masraflarından kurtulun; garanti belgelerini anında müşterileriniz ile paylaşın.",
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-7">
      {!loading && (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          {applications.map((application) => (
            <AppCard
              installed={installed}
              key={application.id}
              application={application}
            />
          ))}
          <AppCard installed={installed} application={infoBip} />
        </div>
      )}
    </main>
  );
};

export default Applications;
