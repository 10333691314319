import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FilterTags from "./filterTags";
import FilterSheet from "./fiterSheet";

import ServiceActivationService from "@/services/serviceActivationService";

import useActivationsStore from "@/stores/useServiceActivationStore";

import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const LinkedProducts = () => {
  const { t } = useTranslation();
  const {
    loading,
    getLinkedProducts,
    linkedProducts,
    setLinkedProductsFilter,
  } = useActivationsStore();
  const { toast } = useToast();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    getLinkedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setLinkedProductsFilter({ ...linkedProducts.filter, page });
    getLinkedProducts();
  };

  const onPageSizeChange = (size) => {
    setLinkedProductsFilter({ ...linkedProducts.filter, size, page: 1 });
    getLinkedProducts();
  };

  const onRemoveProducts = () => {
    ServiceActivationService.unlinkProducts({
      merchantProductIds: selectedProducts,
    }).then(() => {
      toast({
        title: t("settingsPage.productsRemovedSuccesfully"),
        variant: "success",
      });
      setTimeout(() => {
        getLinkedProducts();
        setSelectedProducts([]);
      }, 1000);
    });
  };

  const onSelectAll = () => {
    linkedProducts?.data.length === selectedProducts.length
      ? setSelectedProducts([])
      : setSelectedProducts(linkedProducts?.data.map((product) => product.id));
  };

  return (
    <div>
      <>
        <TableHeaderWrapper className="flex items-center justify-between gap-5">
          <FilterTags />
          <Button
            onClick={() => setOpenFilterSheet(true)}
            variant="secondaryGray"
            className="min-w-[150px] font-medium"
          >
            {t("common.addFilter")}
          </Button>
        </TableHeaderWrapper>
        {loading ? (
          <Table>
            <TableSkeleton colSpan={3} />
          </Table>
        ) : linkedProducts?.data.length ? (
          <Table className="border border-[#EAECF0] bg-white shadow-sm">
            <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
              <TableRow>
                <TableHead>
                  <Checkbox
                    checked={
                      linkedProducts?.data.length === selectedProducts.length
                    }
                    onClick={onSelectAll}
                  />
                </TableHead>
                <TableHead>{t("settingsPage.productName")}</TableHead>
                <TableHead>{t("settingsPage.brand")}</TableHead>
                <TableHead>{t("settingsPage.source")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {linkedProducts?.data.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedProducts.includes(product.id)}
                      onClick={() => {
                        if (selectedProducts.includes(product.id)) {
                          setSelectedProducts(
                            selectedProducts.filter((id) => id !== product.id),
                          );
                        } else {
                          setSelectedProducts([
                            ...selectedProducts,
                            product.id,
                          ]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.brand}</TableCell>
                  <TableCell>{product.source}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyTable
            title={t("common.emptyTableNoData")}
            desc={t("common.emptyTableNoDataDesc")}
          />
        )}
        <div className="flex flex-col items-center">
          {linkedProducts.filter.totalPages > 1 && (
            <Pagination
              totalPages={linkedProducts.filter.totalPages}
              currentPage={linkedProducts.filter.page}
              pageSize={linkedProducts.filter.size}
              onPageChange={(page) => onPageChange(page)}
            />
          )}
          <PageSizeSelect onPageSizeChange={onPageSizeChange} />
        </div>
        <div className="mt-5 flex justify-end">
          <button
            className="rounded-lg border border-[#E3E7EF] bg-red-600 px-4 py-2 text-gray-200 disabled:opacity-50"
            disabled={selectedProducts.length === 0}
            onClick={onRemoveProducts}
          >
            {t("settingsPage.removeProducts")}
          </button>
        </div>
        <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
      </>
    </div>
  );
};

export default LinkedProducts;
