import { useTranslation } from "react-i18next";

import AccountSettingIcon from "../../assets/icons/accountSettings.svg?react";
import ActivationsIcon from "../../assets/icons/activations.svg?react";
import DocumentsIcon from "../../assets/icons/documents.svg?react";
// import MerchantSettingsIcon from "../../assets/icons/merchantSettings.svg?react";
// import ShoppingCenterSettingsIcon from "../../assets/icons/shoppingCenterSettings.svg?react";
import EmployeesIcon from "../../assets/icons/employees.svg?react";
import GlobeIcon from "../../assets/icons/globe.svg?react";
// import FormsIcon from "../../assets/icons/forms.svg?react";
import PaymentSettingsIcon from "../../assets/icons/paymentSettings.svg?react";

import Card from "@/components/settings/card";
// import HelpIcon from "../../assets/icons/help.svg?react";
import CheckRole from "@/components/shared/checkRole";

const Settings = () => {
  const { t } = useTranslation();

  const items = [
    {
      name: t("settingsPage.accountSettings"),
      description: t("settingsPage.accountSettingsDesc"),
      path: "/settings/account",
      icon: <AccountSettingIcon />,
    },
    {
      name: t("settingsPage.activations"),
      description: t("settingsPage.activationsDesc"),
      path: "/settings/activations",
      icon: <ActivationsIcon />,
    },
    {
      name: t("settingsPage.documents"),
      roleKey: "DOCUMENTS",
      path: "/settings/documents",
      description: t("settingsPage.documentsDesc"),
      icon: <DocumentsIcon />,
    },
    // {
    //   name: "Satıcı Ayarları",
    //   description:
    //     "Satıcı/Bayi ayarlarını görüntüleyebilir, yetkilendirmelerini buradan yapabilirsiniz.",
    //   icon: <MerchantSettingsIcon />,
    // },
    // {
    //   name: "Mağaza Ayarları",
    //   description:
    //     "Mağaza ayarlarını görüntüleyebilir, yetkilendirmelerini buradan yapabilirsiniz.",
    //   icon: <ShoppingCenterSettingsIcon />,
    // },
    {
      name: t("settingsPage.employees"),
      description: t("settingsPage.employeesDesc"),
      icon: <EmployeesIcon />,
      path: "/settings/employees",
    },
    // {
    //   name: "Formlar",
    //   description:
    //     "Müşterilerinize anketler oluşturabilir, geri bildirimlerini almaya hemen başlayabilirsiniz",
    //   icon: <FormsIcon />,
    // },
    {
      name: t("settingsPage.paymentTitle"),
      description: t("settingsPage.paymentDesc"),
      icon: <PaymentSettingsIcon />,
      path: "/settings/payment",
    },
    // {
    //   name: "Yardım",
    //   description:
    //     "Herhangi bir sorunuz varsa veya daha fazla bilgi almak istiyorsanız lütfen bize ulaşın. ",
    //   icon: <HelpIcon />,
    // },
    {
      name: t("settingsPage.language"),
      description: t("settingsPage.languageDesc"),
      icon: <GlobeIcon />,
      path: "/settings/language",
    },
  ];

  return (
    <main className="min-h-full bg-[#F9FAFC] p-7">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {items.map((item, index) => (
          <CheckRole key={index} action="VIEW" section={item.roleKey}>
            <Card key={index} item={item} />
          </CheckRole>
        ))}
      </div>
    </main>
  );
};

export default Settings;
