/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import applicationsService from "@/services/applicationsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ApplicationInstallPage = () => {
  const { t } = useTranslation();
  const app = useLocation().state;
  const [isInstalled, setIsInstalled] = React.useState(false);

  React.useEffect(() => {
    applicationsService.checkInstalled().then((res) => {
      setIsInstalled(res.data["infobip"]);
    });
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/applications">
          {t("settingsPage.applications")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.applicationDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <Header isInstalled={isInstalled} app={app} />
        <p className="formBox leading-7 text-[#4A5567]">{app.description}</p>
      </div>
    </main>
  );
};

export default ApplicationInstallPage;

const Header = ({ app, isInstalled }) => {
  const { t } = useTranslation();
  return (
    <div className="formBox flex items-center justify-between gap-4">
      <div className="flex items-center gap-4">
        <div className="flex h-20 w-20 items-center justify-center">
          <img src={app.logoUrl} alt={app.name} className="h-auto w-auto" />
        </div>
        <h2 className="text-lg font-medium text-[#111729]">{app.name}</h2>
      </div>
      <p className="text-sm text-[#4A5567]">{app.subTitle}</p>
      <Button asChild>
        <Link
          state={app}
          to={`/applications/${app.slug}/edit?installed=${isInstalled}`}
        >
          {isInstalled
            ? t("settingsPage.editApplication")
            : t("settingsPage.installApplication")}
        </Link>
      </Button>
    </div>
  );
};
