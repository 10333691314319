/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import applicationsService from "@/services/applicationsService";

import TemporaryForm from "@/components/applications/temporaryForm";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

import { cn } from "@/lib/utils";

const ApplicationEditPage = () => {
  const { t } = useTranslation();
  const app = useLocation().state;

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/applications">
          {t("settingsPage.applications")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("settingsPage.applicationDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-12">
        <div className="flex h-20 w-20 items-center justify-center">
          <img src={app.logoUrl} alt={app.name} className="h-auto w-auto" />
        </div>
        <ApplicationForm app={app} />
        {/* THIS COMPONENT JusT WORK ONE COMPANY THIS FEATURE COME FROM Business */}
        {/* app slug == trendyol and localstorage.mercahntId == 111 */}

        {app.slug === "trendyol" &&
          localStorage.getItem("merchantId") === "109" && <TemporaryForm />}
      </div>
    </main>
  );
};

export default ApplicationEditPage;

const generateSchema = (fields) => {
  const schema = {};

  fields.forEach((item) => {
    schema[item.dataKey] = z.string({ required_error: "Bu alan zorunludur" });

    item.regex &&
      (schema[item.dataKey] = schema[item.dataKey].regex(item.regex, {
        message: "Geçersiz değer",
      }));
  });

  return z.object(schema);
};

const ApplicationForm = ({ app }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const formSchema = generateSchema(app.fields);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const onSubmit = (data) => {
    applicationsService
      .installApplication(app.slug, data)
      .then((res) => {
        toast({
          variant: "success",
          title: t("common.success"),
          description: "Uygulama başarıyla kuruldu",
        });

        navigate("/applications");
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn("formBox", "flex max-w-md flex-col gap-4")}
      >
        {app.fields.map((item) => {
          return (
            <FormField
              key={item.dataKey}
              control={form.control}
              name={item.dataKey}
              render={({ field }) => (
                <FormItem>
                  <Label>{item.label}</Label>
                  <FormControl>
                    <Input placeholder={item.placeholder} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        })}
        <footer className="mt-4 flex items-center justify-end gap-4">
          <Button type="submit">{t("common.save")}</Button>
        </footer>
      </form>
    </Form>
  );
};
