import React from "react";
import { Outlet } from "react-router-dom";

import MainFooter from "./mainFooter";
import MainHeader from "./mainHeader";
import Sidebar from "./sidebar";

const MainLayout = () => {
  return (
    <div className="relative flex h-screen w-full overflow-hidden">
      <Sidebar />
      <div className="flex h-screen w-full flex-col overflow-hidden">
        <MainHeader />
        <div className="flex-1 overflow-y-auto">
          <Outlet />
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

export default MainLayout;
