import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Switch } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import ProductFromIntegrationsService from "@/services/productFromIntegrationsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ProductEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm({
    defaultValues: async () => ProductFromIntegrationsService.getProduct(id),
  });

  const onSubmit = (data) => {
    ProductFromIntegrationsService.updateProduct(data)
      .then(() => {
        toast({
          title: t("productsPage.updateSuccess"),
          variant: "success",
        });
        navigate("/products/integrations");
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/products/integrations">
          {t("productsPage.productsIntegrations")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("productsPage.editProduct")}</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productCategory")}
              </h2>
              <Tag>{form.watch("categoryTree")}</Tag>
            </div>
            <div className="formBox grid grid-cols-2 gap-4">
              <h2 className="col-span-2 mb-5 text-lg font-medium">
                {t("productsPage.productInformation")}
              </h2>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.productName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="warrantyDuration"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.warrantyDuration")}</Label>
                    <FormControl>
                      <Input min="1" {...field} type="number" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="setupRequired"
                render={({ field }) => (
                  <FormItem className="flex flex-col py-4">
                    <Label>{t("productsPage.installationRequired")}</Label>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={true}
                control={form.control}
                name="brand"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.brand")}</Label>
                    <FormControl>
                      <Input disabled={true} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={true}
                control={form.control}
                name="productCode"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.productCode")}</Label>
                    <FormControl>
                      <Input disabled={true} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={true}
                control={form.control}
                name="source"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.source")}</Label>
                    <FormControl>
                      <Input disabled={true} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={true}
                control={form.control}
                name="createdAt"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.creationDate")}</Label>
                    <FormControl>
                      <Input disabled={true} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={true}
                control={form.control}
                name="updatedAt"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.dateOfUpdate")}</Label>
                    <FormControl>
                      <Input disabled={true} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button onClick={form.handleSubmit(onSubmit)} className="ml-auto">
              {t("common.save")}
            </Button>
          </div>
        </form>
      </Form>
    </main>
  );
};

export default ProductEditPage;
