import LangSwitch from "../layout/langSwitch";

import EgarantiLogo from "@/assets/egaranti-logo.png";

const AuthHeader = () => {
  return (
    <header
      className="flex w-full items-center justify-between bg-transparent p-8"
      style={{ minHeight: "6rem" }}
    >
      <img src={EgarantiLogo} alt="Egaranti Logo" className="h-10" />
      <div>
        <LangSwitch />
      </div>
    </header>
  );
};

export default AuthHeader;
