import { Tag } from "@egaranti/components";

import { useEffect, useState } from "react";

import ProductService from "@/services/productService";

import useProductsStore from "@/stores/useProductsStore";

import TrashIcon from "@/assets/icons/trash.svg?react";

const FilterTags = () => {
  const { filters, setFilters, getProducts } = useProductsStore();

  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [brand, setBrand] = useState("");

  const getMainCategory = async () => {
    const { data } = await ProductService.getMainCategories();
    const mainCategory = data.find(
      (mainCategory) => mainCategory.id == filters.mainCategoryId,
    );
    setMainCategory(mainCategory);
  };

  const getCategory = async () => {
    const { data } = await ProductService.getCategories(filters.mainCategoryId);
    const category = data.find((category) => category.id == filters.categoryId);
    setCategory(category);
  };

  const getSubCategory = async () => {
    const { data } = await ProductService.getSubCategories(filters.categoryId);
    const subCategory = data.find(
      (subCategory) => subCategory.id == filters.subCategoryId,
    );
    setSubCategory(subCategory);
  };

  const getBrand = async () => {
    const { data } = await ProductService.getSubCategoryBrands(
      filters.subCategoryId,
    );
    const brand = data.find((brand) => brand.id == filters.subCategoryBrandId);
    setBrand(brand);
  };

  useEffect(() => {
    filters.mainCategoryId && getMainCategory();
    filters.categoryId && getCategory();
    filters.subCategoryId && getSubCategory();
    filters.subCategoryBrandId && getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {filters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {filters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({ ...filters, name: "" });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.mainCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {mainCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({
                ...filters,
                mainCategoryId: "",
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.categoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {category.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({
                ...filters,
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.subCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {subCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({
                ...filters,
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getProducts();
            }}
          />
        </Tag>
      )}
      {filters.subCategoryBrandId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {brand.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setFilters({
                ...filters,
                subCategoryBrandId: "",
              });
              getProducts();
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
