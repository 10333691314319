import axios from "@/lib/axios";

class ProductServiceFromIntegrations {
  constructor() {
    this.api = axios;
  }
  async getProducts(query) {
    const { data } = await this.api.get("/merchant-integration-products", {
      params: query,
    });

    return data;
  }
  async getProduct(id) {
    const { data } = await this.api.get(`/merchant-integration-products/${id}`);

    return data;
  }

  async updateProduct(product) {
    const data = await this.api.put(
      `/merchant-integration-products/${product.id}`,
      product,
    );

    return data;
  }

  async getSources(product) {
    const { data } = await this.api.get(
      `/merchant-integration-products/sources`,
      {
        params: {
          merchantProductId: product.id,
        },
      },
    );

    return data;
  }
}

export default new ProductServiceFromIntegrations();
