import ServiceRecordsService from "@/services/serviceRecordsService";

import { create } from "zustand";

const useServiceRecordsStore = create((set) => ({
  loading: false,
  claims: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    individualCustomerProductName: "",
    firstName: "",
    lastName: "",
    claimNo: null,
    state: "",
    reason: "",
  },
  getClaims: async () => {
    set({ loading: true });
    const data = await ServiceRecordsService.getClaims(
      useServiceRecordsStore.getState().filters,
    );
    set({
      claims: data.content,
      loading: false,
      filters: {
        ...useServiceRecordsStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useServiceRecordsStore;
