/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import paymentService from "@/services/paymentService";

import { cn } from "@/lib/utils";

const PricingSlider = () => {
  const { t } = useTranslation();
  const [pricingPackages, setPricingPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [allowedValues, setAllowedValues] = useState([]);

  const handleAmountChange = (e) => {
    setSelectedPackage(
      pricingPackages.find(
        (item) => item?.quantity === allowedValues[e.target.value],
      ),
    );
  };

  const formatAmount = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const calculatePrice = () => {
    return selectedPackage?.quantity * selectedPackage.priceUnit;
  };

  useEffect(() => {
    paymentService.getPricingPackages().then((data) => {
      const sortedPackages = data.sort((a, b) => a?.quantity - b?.quantity);
      setAllowedValues([
        ...sortedPackages.map((item) => item.quantity),
        250001,
      ]);
      setPricingPackages([...sortedPackages, { quantity: 250001 }]);
      setSelectedPackage(sortedPackages[0]);
    });
  }, []);

  return (
    <div className="false mx-auto max-w-[850px] rounded-lg bg-white">
      <div className="flex w-full flex-col items-center justify-center gap-3 rounded-lg bg-white md:bg-transparent">
        {selectedPackage.quantity <= 250000 && (
          <div className="flex items-center justify-center gap-1 text-2xl font-bold text-[#111729] md:text-3xl">
            {formatAmount(calculatePrice())}₺
            <span className="text-lg font-medium text-[#677389]">
              {t("payment.plansAndFees.card2.perPiece")}{" "}
              <span className="font-bold text-[#0049E6]">
                {selectedPackage?.priceUnit}₺
              </span>
            </span>
            <ArrowRightIcon />
            <span className="text-nowrap text-xs font-semibold text-[#677389]">
              %14,29 {t("payment.plansAndFees.card2.discount")}
            </span>
          </div>
        )}
        <div className="mb-2 mt-2 text-center text-sm font-medium text-[#677389]">
          {t("payment.plansAndFees.card2.description1")}
        </div>
        {selectedPackage?.quantity <= 250000 && (
          <h4 className="mb-6 text-center text-lg font-semibold text-[#0049E6] md:text-xl">
            {t("payment.plansAndFees.card2.description2", {
              count: formatAmount(selectedPackage?.quantity),
            })}
          </h4>
        )}
        <div className="relative w-4/5">
          <input
            type="range"
            min={0}
            max={allowedValues.length - 1}
            step={1}
            value={allowedValues.indexOf(selectedPackage?.quantity)}
            onChange={(e) => handleAmountChange(e)}
            className="h-2 w-full appearance-none rounded-full bg-[#CCDBFA] accent-[#0049E6] outline-none"
          />
          <div className="relative flex h-5 justify-between">
            {allowedValues.map((value, index) => {
              return (
                <div
                  key={index}
                  className="h1 flex w-1 items-center justify-center"
                >
                  <span
                    key={index}
                    className={cn(
                      "text-xs font-medium text-[#677389] transition-all",
                      {
                        "min-w-[70px] text-[#0049E6]": value === 250001,
                        "font-semibold text-[#0049E6]":
                          selectedPackage?.quantity === value,
                      },
                    )}
                  >
                    {value === 250001
                      ? t("payment.plansAndFees.card2.more")
                      : formatAmount(value)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {selectedPackage?.quantity < 250001 ? (
        <div className="mx-auto mt-8 flex w-full max-w-[394px] flex-col rounded-lg border bg-white p-4 px-8 shadow-lg">
          <h2 className="text-2xl font-semibold text-[#364153]">
            {t("payment.plansAndFees.card2.cardTitle1")}
          </h2>
          <p className="my-4 text-base font-medium text-[#364153]">
            {t("payment.plansAndFees.card2.cardSubtitle1")}
          </p>
          <div className="mb-5 w-full border"></div>
          <ul className="flex flex-col gap-4 font-medium text-[#364153]">
            <li className="flex items-center gap-3">
              <CheckmarkIcon />
              {t("payment.plansAndFees.card2.listItem1")}
            </li>
            <li className="flex items-center gap-3">
              <CheckmarkIcon />
              {t("payment.plansAndFees.card2.listItem2", {
                count: selectedPackage.priceUnit,
              })}
            </li>
            <li className="flex items-center gap-3">
              <CheckmarkIcon />
              {t("payment.plansAndFees.card2.listItem3")}
            </li>
          </ul>
          <span className="mt-4 flex w-full items-center justify-center gap-2 text-center text-lg font-medium text-[#677389]">
            {t("payment.plansAndFees.card2.amount")}:{" "}
            <span className="text-3xl font-semibold text-[#0049E6]">
              {formatAmount(calculatePrice())}₺
            </span>
          </span>
          <Button asChild={true} className="mt-4">
            <Link state={{ selectedPackage }} to="/settings/payment/checkout">
              {t("payment.plansAndFees.card2.btnText1")}
            </Link>
          </Button>
        </div>
      ) : (
        <div className="mx-auto mt-8 flex w-full max-w-[394px] flex-col gap-4 rounded-lg border bg-white p-8 shadow-lg">
          <h2 className="text-2xl font-semibold text-[#364153]">
            {t("payment.plansAndFees.card2.cardTitle2")}
          </h2>
          <p className="text-base font-medium text-[#364153]">
            {t("payment.plansAndFees.card2.cardSubtitle2")}
          </p>
          <Button asChild={true} variant="secondaryColor">
            <a target="_blank" href="mailto:info@egaranti.com" rel="noreferrer">
              {t("payment.plansAndFees.card2.btnText2")}
            </a>
          </Button>
        </div>
      )}
    </div>
  );
};

const CheckmarkIcon = () => {
  return (
    <svg
      className="w-6 flex-shrink-0"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="24" height="24" rx="12" fill="#AAC2F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.598 7.38967L10.438 14.2997L8.53797 12.2697C8.18797 11.9397 7.63797 11.9197 7.23797 12.1997C6.84797 12.4897 6.73797 12.9997 6.97797 13.4097L9.22797 17.0697C9.44797 17.4097 9.82797 17.6197 10.258 17.6197C10.668 17.6197 11.058 17.4097 11.278 17.0697C11.638 16.5997 18.508 8.40967 18.508 8.40967C19.408 7.48967 18.318 6.67967 17.598 7.37967V7.38967Z"
        fill="#0049E6"
      />
    </svg>
  );
};

const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="20"
      fill="none"
      viewBox="0 0 54 20"
    >
      <path
        fill="#677389"
        d="M47.555 13.148c-8.195.176-15.862 3.701-24.058 4.142-2.38.088-4.759-.177-6.61-1.234 3.35-.617 6.522-1.763 8.901-3.966 2.556-2.29 2.82-6.521 1.234-9.517C25.524-.247 21.735-.335 19.091.37c-6.257 1.498-10.487 8.988-6.962 14.276-3.878.264-7.755 0-11.545.176-.793.088-.793 1.234.088 1.234 3.878.264 8.549.617 13.131.352.264.177.529.441.793.617 9.342 6.521 23.001-1.674 33.047-1.939 1.146-.088 1.146-2.026-.088-1.938zm-34.633-2.115c-.97-3.613 2.203-6.698 5.111-8.196 2.908-1.498 7.05-1.498 8.02 2.291 1.145 4.406-2.644 7.05-6.257 8.196-1.675.529-3.261.881-4.935 1.057-.97-.88-1.587-1.938-1.94-3.348z"
      ></path>
      <path
        fill="#677389"
        d="M53.548 13.236c-1.233-.793-2.38-1.586-3.525-2.38-.97-.704-1.939-1.498-3.084-1.762-.881-.264-1.763 1.058-.97 1.674.97.705 2.027 1.322 2.997 1.94.705.44 1.41.969 2.027 1.41-.705.352-1.41.616-2.115.968-.53.265-1.146.53-1.675.794a1.063 1.063 0 00-.44.44c-.265.353-.089.177 0 .088-.353.265-.353 1.146.264 1.058.176.088.352.088.617.088.617-.176 1.322-.44 1.938-.705 1.322-.529 2.644-1.058 3.878-1.763.705-.528.705-1.498.088-1.85z"
      ></path>
    </svg>
  );
};

export default PricingSlider;
