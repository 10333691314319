import { Button } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import additionaPackagesService from "@/services/additionaPackagesService";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

import FilterSheet from "@/components/additionalPackages/maintenance/filterSheet";
import FilterTags from "@/components/additionalPackages/maintenance/filterTags";
import EmptyTable from "@/components/shared/EmptyTable";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import EditIcon from "@/assets/icons/edit.svg?react";
import FilterIcon from "@/assets/icons/filter.svg?react";
import TrashIcon from "@/assets/icons/trash.svg?react";

const Maintenance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, maintenancePlans, getMaintenancePlans, maintenanceFilters } =
    useAdditionalPackagesStore();

  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  useEffect(() => {
    getMaintenancePlans();
  }, []);

  const onPageChange = (page) => {
    getMaintenancePlans({
      ...maintenanceFilters,
      page,
    });
  };

  const onDeletePlan = (id) => {
    additionaPackagesService.deleteMaintenancePlan(id).then(() => {
      getMaintenancePlans();
    });
  };

  const onEditPlan = (id) => {
    navigate(`/additional-packages/maintenance/${id}`);
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="mb-4 flex items-center justify-end gap-4">
        <Button
          onClick={() => navigate("/additional-packages/maintenance/create")}
        >
          <span>{t("additionalServicesPage.addMaintenanceRepairPlan")}</span>
        </Button>
      </div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
              className="min-w-[150px] font-medium"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={5} />
            </Table>
          ) : maintenancePlans.length > 0 ? (
            <Table>
              <TableHeader>
                <TableHead>{t("additionalServicesPage.title")}</TableHead>
                <TableHead>{t("additionalServicesPage.packetCount")}</TableHead>
                <TableHead>{t("additionalServicesPage.status")}</TableHead>
                <TableHead>
                  {t("additionalServicesPage.creationDate")}
                </TableHead>
                <TableHead>{t("common.actions")}</TableHead>
              </TableHeader>
              <TableBody>
                {maintenancePlans?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.packages?.length || "0"}</TableCell>
                    <TableCell>
                      <Tag size="sm" variant={item.activated ? "green" : "red"}>
                        {item.activated
                          ? t("additionalServicesPage.active")
                          : t("additionalServicesPage.passive")}
                      </Tag>
                    </TableCell>
                    <TableCell>
                      <span className="flex flex-col items-start justify-center">
                        <span className="text-sm">
                          {item.createdAt?.split(" ")[0]}
                        </span>
                        <span className="text-xs text-[#677389]">
                          {item.createdAt?.split(" ")[1]}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className="flex items-center gap-6">
                      <DeleteButton on={() => onDeletePlan(item.id)} />
                      <button
                        onClick={() => onEditPlan(item.id)}
                        aria-label={t("common.edit")}
                      >
                        <EditIcon />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("additionalServicesPage.dataCouldNotFound")}
              desc={t("additionalServicesPage.dataCouldNotFoundDesc")}
            />
          )}
        </div>
        {maintenanceFilters.totalPages > 1 && (
          <Pagination
            totalPages={maintenanceFilters.totalPages}
            currentPage={maintenanceFilters.page}
            pageSize={maintenanceFilters.size}
            onPageChange={(page) => onPageChange(page)}
          />
        )}
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </main>
  );
};

export default Maintenance;

const DeleteButton = ({ on }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <span aria-label={t("common.delete")} className="text-red-600">
          <TrashIcon />
        </span>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("common.delete")}</AlertDialogTitle>
        </AlertDialogHeader>
        <p>{t("common.areYouSureToDelete")}</p>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={on}>
            {t("common.delete")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
