import ServiceActivationService from "@/services/serviceActivationService";

import { create } from "zustand";

const useServiceActivationsStore = create((set) => ({
  documents: [],
  loading: false,
  linkedProducts: {
    data: [],
    filter: {
      page: 1,
      size: localStorage.getItem("pageSize") || 10,
      mainCategoryId: null,
      categoryId: null,
      subCategoryId: null,
      subCategoryBrandId: null,
      name: "",
    },
  },
  unlinkedProducts: {
    data: [],
    filter: {
      page: 1,
      size: localStorage.getItem("pageSize") || 10,
      mainCategoryId: null,
      categoryId: null,
      subCategoryId: null,
      subCategoryBrandId: null,
      name: "",
    },
  },
  getLinkedProducts: async () => {
    set({ loading: true });
    const data = await ServiceActivationService.getLinkedProducts(
      useServiceActivationsStore.getState().linkedProducts.filter,
    );

    set({
      linkedProducts: {
        data: data.content,
        filter: {
          ...useServiceActivationsStore.getState().linkedProducts.filter,
          totalPages: data.totalPages,
          page: data.page,
        },
      },
      loading: false,
    });
  },
  getUnlinkedProducts: async () => {
    set({ loading: true });
    const data = await ServiceActivationService.getUnlinkedProducts(
      useServiceActivationsStore.getState().unlinkedProducts.filter,
    );

    set({
      unlinkedProducts: {
        data: data.content,
        filter: {
          ...useServiceActivationsStore.getState().unlinkedProducts.filter,
          totalPages: data.totalPages,
          page: data.page,
        },
      },
      loading: false,
    });
  },
  setLinkedProductsFilter: (filter) => {
    set({
      linkedProducts: {
        ...useServiceActivationsStore.getState().linkedProducts,
        filter,
      },
    });
  },
  setUnlinkedProductsFilter: (filter) => {
    set({
      unlinkedProducts: {
        ...useServiceActivationsStore.getState().unlinkedProducts,
        filter,
      },
    });
  },
}));

export default useServiceActivationsStore;
