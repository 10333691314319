/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";

const Card = ({ item }) => {
  return (
    <Link
      to={item.path}
      key={item.id}
      className="w-full cursor-pointer rounded-lg border border-[#E3E7EF] bg-white p-[24px] transition-all hover:border-[#1E86FF]"
    >
      <div className="flex items-center justify-start gap-2">
        {item.icon}
        <span className="font-medium text-[#111729]">{item.name}</span>
      </div>
      <div className="mt-4 text-sm text-[#677389]">{item.description}</div>
    </Link>
  );
};

export default Card;
