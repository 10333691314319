/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";

const AppCard = ({ application, installed }) => {
  return (
    <Link
      to={`/applications/${application.slug}`}
      state={application}
      className="relative flex w-full flex-col gap-3 rounded-lg border border-[#EAECF0] bg-white p-6 hover:border-blue-500"
    >
      {installed[application.slug] && (
        <span aria-label="Bu uygulama yüklü" className="absolute right-2 top-2">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="36" height="36" rx="18" fill="#D1FADF" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.643 11.0855L14.903 21.4505L12.053 18.4055C11.528 17.9105 10.703 17.8805 10.103 18.3005C9.51805 18.7355 9.35305 19.5005 9.71305 20.1155L13.088 25.6055C13.418 26.1155 13.988 26.4305 14.633 26.4305C15.248 26.4305 15.833 26.1155 16.163 25.6055C16.703 24.9005 27.008 12.6155 27.008 12.6155C28.358 11.2355 26.723 10.0205 25.643 11.0705V11.0855Z"
              fill="#12B76A"
            />
          </svg>
        </span>
      )}
      <div className="flex h-16 w-16 items-center justify-center">
        <img
          className="h-auto w-auto"
          src={application.logoUrl}
          alt={application.name}
        />
      </div>
      <h2 className="text-lg font-medium text-[#111729]">{application.name}</h2>
      <p className="text-sm leading-5 text-[#4A5567]">{application.subtitle}</p>
    </Link>
  );
};

export default AppCard;
