import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useAuthStore from "@/stores/useAuthStore";

import * as z from "zod";

const LoginForm = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { login, isAuth } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  const formSchema = z.object({
    email: z
      .string()
      .min(2, {
        message: t("loginForm.validateMsg.emailMin"),
      })
      .max(50, {
        message: t("loginForm.validateMsg.emailMax"),
      }),
    password: z
      .string()
      .min(8, {
        message: t("loginForm.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("loginForm.validateMsg.passwordMax"),
      }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(values) {
    login(values)
      .then(() => {
        navigate(location.state?.from ? location.state.from : "/", {
          replace: true,
        });
        toast({
          variant: "success",
          description: t("loginForm.loginSuccess"),
        });
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      });
  }

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <div className="md:shadow-custom flex min-w-[400px] flex-col gap-8 rounded-lg bg-transparent p-6 md:bg-white">
      <div className="flex flex-col gap-3">
        <h1 className="formTitle">{t("loginForm.title")}</h1>
        <p className="formDescription">{t("loginForm.description")}</p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <Label>{t("loginForm.email")}</Label>
                <FormControl>
                  <Input placeholder="admin@mail.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <Label>{t("loginForm.password")}</Label>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-4">
            <Button type="submit">{t("common.login")}</Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate("/forgot-password");
              }}
              variant="secondaryGray"
            >
              {t("loginForm.forgotPassword")}
            </Button>
          </div>
        </form>
      </Form>

      <span className="formDescription mx-auto">
        {t("loginForm.notRegistered")}{" "}
        <Link to="/register" className="text-[#0049E6]">
          {t("common.register")}
        </Link>
      </span>
    </div>
  );
};

export default LoginForm;
