/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";

const CheckoutTable = ({ selectedPackage }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col rounded-lg border bg-white p-5 shadow">
      <h2 className="font-semibold text-[#677389]">
        {t("payment.checkout.checkoutTable")}
      </h2>
      <div className="mt-6 flex w-full flex-col items-center justify-between rounded-lg border">
        <div className="flex w-full items-center justify-between px-8 py-4">
          <span className="text-lg font-medium text-[#677389]">
            {t("payment.checkout.subtotal")}
          </span>
          <span className="text-lg font-semibold text-[#111729]">
            {selectedPackage?.priceUnit * selectedPackage?.quantity}₺
          </span>
        </div>
        {/* <div className="flex w-full items-center justify-between px-8 py-4">
            <span className="text-lg font-medium text-[#677389]">Vergi</span>
            <span className="text-lg font-semibold text-[#111729]">8750,00₺</span>
          </div> */}
        <div className="flex w-full items-center justify-between border-t px-8 py-4">
          <span className="text-lg font-medium text-[#677389]">
            {t("payment.checkout.total")}
          </span>
          <span className="text-lg font-semibold text-[#111729]">
            {selectedPackage?.priceUnit * selectedPackage?.quantity}₺
          </span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutTable;
