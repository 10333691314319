import { Tabs } from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddProduct from "./addProduct";
import LinkedProducts from "./linkedProducts";

const ProductList = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="formBox">
      <h2 className="mb-5 font-medium">Seçili Ürünler</h2>
      <div className="border-b">
        <Tabs
          tabs={[
            { id: 1, title: t("additionalServicesPage.affiliatedProducts") },
            { id: 2, title: t("additionalServicesPage.addProduct") },
          ]}
          selectedTabId={activeTab}
          onTabChange={(id) => setActiveTab(id)}
        />
      </div>
      {activeTab === 1 && <LinkedProducts />}
      {activeTab === 2 && <AddProduct />}
    </div>
  );
};

export default ProductList;
