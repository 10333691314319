import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FilterSheet from "./filterSheet";
import FilterTags from "./filterTags";

import additionaPackagesService from "@/services/additionaPackagesService";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";

import EmptyTable from "@/components/shared/EmptyTable";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import { set } from "date-fns";

const AddProduct = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();

  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const {
    loading,
    extraWarrantyUnlinkedProducts,
    extraWarrantyUnlinkedFilters,
    getExtraWarrantyUnlinkedProducts,
    setExtraWarrantyUnlinkedFilters,
  } = useAdditionalPackagesStore();

  useEffect(() => {
    getExtraWarrantyUnlinkedProducts();
  }, []);

  const onPageChange = (page) => {
    setExtraWarrantyUnlinkedFilters({ ...extraWarrantyUnlinkedFilters, page });
    getExtraWarrantyUnlinkedProducts(id);
    setSelectedProducts([]);
  };

  const onAddProducts = () => {
    additionaPackagesService
      .linkProducts(id, {
        merchantProductIds: selectedProducts,
      })
      .then(() => {
        toast({
          title: t("additionalServicesPage.productsAddedSuccesfully"),
          variant: "success",
        });

        setTimeout(() => {
          getExtraWarrantyUnlinkedProducts(id);
          setSelectedProducts([]);
        }, 1000);
      });
  };

  const onSelectAll = () => {
    if (
      extraWarrantyUnlinkedProducts?.content?.length === selectedProducts.length
    ) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(
        extraWarrantyUnlinkedProducts?.content?.map((product) => product.id),
      );
    }
  };

  return (
    <div>
      <TableHeaderWrapper className="flex items-center justify-between gap-5">
        <FilterTags />
        <Button
          onClick={() => setOpenFilterSheet(true)}
          variant="secondaryGray"
          className="min-w-[150px] font-medium"
        >
          {t("common.addFilter")}
        </Button>
      </TableHeaderWrapper>
      {loading ? (
        <Table>
          <TableSkeleton colSpan={4} />
        </Table>
      ) : extraWarrantyUnlinkedProducts?.content?.length ? (
        <Table className="border border-[#EAECF0] bg-white shadow-sm">
          <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
            <TableRow>
              <TableHead>
                <Checkbox
                  checked={
                    extraWarrantyUnlinkedProducts?.content?.length ===
                    selectedProducts.length
                  }
                  onClick={onSelectAll}
                />
              </TableHead>
              <TableHead>{t("additionalServicesPage.productName")}</TableHead>
              <TableHead>{t("additionalServicesPage.brand")}</TableHead>
              <TableHead>{t("additionalServicesPage.source")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {extraWarrantyUnlinkedProducts?.content?.map((product, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedProducts.includes(product.id)}
                    onClick={() => {
                      if (selectedProducts.includes(product.id)) {
                        setSelectedProducts(
                          selectedProducts.filter((id) => id !== product.id),
                        );
                      } else {
                        setSelectedProducts([...selectedProducts, product.id]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.brand}</TableCell>
                <TableCell>{product.source}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyTable
          title={t("common.emptyTableNoData")}
          desc={t("common.emptyTableNoDataDesc")}
        />
      )}
      <div className="flex flex-col items-center">
        {extraWarrantyUnlinkedProducts.totalPages > 1 && (
          <Pagination
            totalPages={extraWarrantyUnlinkedProducts.totalPages}
            currentPage={extraWarrantyUnlinkedFilters.page}
            pageSize={extraWarrantyUnlinkedProducts.size}
            onPageChange={(page) => onPageChange(page)}
          />
        )}
        {!loading && (
          <PageSizeSelect
            onPageSizeChange={onPageSizeChange}
            value={filters.size}
          />
        )}
      </div>
      <div className="mt-5 flex justify-end">
        <Button
          disabled={selectedProducts.length === 0}
          onClick={onAddProducts}
        >
          {t("additionalServicesPage.addProducts")}
        </Button>
      </div>
      <FilterSheet
        id={id}
        open={openFilterSheet}
        onOpenChange={setOpenFilterSheet}
      />
    </div>
  );
};

export default AddProduct;
