import { Button, Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";
import { useToast } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

//import ImportIcon from "../../assets/icons/import.svg?react";
import RightArrowIcon from "../../assets/icons/arrowRight.svg?react";
import ExportIcon from "../../assets/icons/export1.svg?react";
import FilterIcon from "../../assets/icons/filter.svg?react";
import EmptyTable from "../shared/EmptyTable";
import FilterSheet from "./view/filterSheet";
import FilterTags from "./view/filterTags";

import SellerService from "@/services/sellerService";

import useSellerStore from "@/stores/useSellerStore";

import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

const TableView = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [isExported, setIsExported] = useState(false);

  const { sellers, loading, filters, setFilters, getSellers } =
    useSellerStore();

  useEffect(() => {
    getSellers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickRow = (id) => {
    navigate(`/sellers/${id}`);
  };

  const onExport = () => {
    SellerService.exportSellers({ ...filters })
      .then(() => {
        toast({
          description: t("sellers.exportSuccess"),
          variant: "success",
        });
        setIsExported(true);
      })
      .error((err) => {
        toast({
          description: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const onPageChange = (page) => {
    setFilters({
      ...filters,
      page: page,
    });
    getSellers();
  };

  const onPageSizeChange = (size) => {
    localStorage.setItem("pageSize", size);
    setFilters({ ...filters, size, page: 1 });
    getSellers();
  };

  return (
    <div className="flex h-full flex-col items-center p-6">
      <div className="ml-auto flex w-max gap-4">
        <Button
          disabled={isExported}
          onClick={onExport}
          variant="secondaryGray"
        >
          <ExportIcon />
          <span>{t("sellers.export")}</span>
        </Button>
        {/* <Button variant="secondaryGray">
          <ImportIcon />
          <span>{t("sellers.import")}</span>
        </Button> */}
      </div>
      <div className="mt-4 w-full bg-white">
        <TableHeaderWrapper className="flex items-center justify-between gap-5">
          <Link
            to="/sellers/waiting"
            className="flex w-full cursor-pointer items-center justify-start gap-2 text-sm font-medium underline"
          >
            <span>{t("sellers.seeIncomingRequests")}</span>
            <RightArrowIcon />
          </Link>
          <FilterTags />
          <Button
            onClick={() => setOpenFilterSheet(true)}
            variant="secondaryGray"
            className="min-w-[150px] font-medium"
          >
            <FilterIcon />
            <span>{t("common.addFilter")}</span>
          </Button>
        </TableHeaderWrapper>
        {loading ? (
          <Table>
            <TableSkeleton colSpan={4} />
          </Table>
        ) : sellers?.content?.length ? (
          <Table>
            <TableHeader>
              <TableHead>{t("sellers.title")}</TableHead>
              <TableHead>{t("sellers.phone")}</TableHead>
              <TableHead>{t("sellers.email")}</TableHead>
            </TableHeader>
            <TableBody>
              {sellers?.content?.map((seller, index) => (
                <TableRow
                  role="button"
                  onClick={() => onClickRow(seller.id)}
                  key={index}
                >
                  <TableCell>{seller.title}</TableCell>
                  <TableCell>{seller.phone}</TableCell>
                  <TableCell>{seller.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyTable
            title={t("sellers.emptyTableNoData")}
            desc={t("sellers.emptyTableNoDataDesc")}
          />
        )}
      </div>
      <Pagination
        totalPages={sellers.totalPages}
        currentPage={sellers.page}
        pageSize={sellers.pageSize}
        onPageChange={onPageChange}
      />
      <PageSizeSelect
        onPageSizeChange={onPageSizeChange}
        value={filters.size}
      />
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </div>
  );
};

export default TableView;
