import { Button } from "@egaranti/components";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@egaranti/components";
import { Popover, PopoverContent, PopoverTrigger } from "@egaranti/components";

import React, { useEffect, useMemo, useState } from "react";

import ProductService from "@/services/productService";

import { Check, ChevronsUpDown } from "lucide-react";

import useDebounce from "@/hooks/useDebounce";
import { cn } from "@/lib/utils";

export function SelectProduct({ value, setValue }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  // when first opened, fetch products
  useEffect(() => {
    if (open) {
      setSearch("");
      setLoading(true);
      ProductService.getProducts({
        page: 1,
        size: localStorage.getItem("pageSize") || 10,
      }).then((res) => {
        setData(
          res.content.map((product) => ({
            label: product.name,
            value: product.id,
          })),
        );
        setLoading(false);
      });
    }
  }, [open]);

  const onChangeSearch = (event) => {
    setSearch(event);

    handleSearch(event);
  };

  const handleSearch = useDebounce((e) => {
    setLoading(true);
    ProductService.getProducts({
      page: 1,
      size: localStorage.getItem("pageSize") || 10,
      name: e,
    })
      .then((res) => {
        setData(
          res.content.map((product) => ({
            label: product.name,
            value: product.id,
          })),
        );

        setLoading(false);
      })
      .catch(() => {
        // Handle any errors here
        console.error("Error while fetching products");
      });
  }, 750);

  let buttonLabel = "";
  if (value) {
    buttonLabel = data.find((product) => product.value === value)?.label;

    if (buttonLabel?.length > 24) {
      buttonLabel = buttonLabel.slice(0, 24) + "...";
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="ring-offset-background focus:ring-ring flex w-full items-center justify-between rounded-lg border border-[#CDD5E0] px-4 py-6 text-[#111729] placeholder:text-[#677389] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 [&>span]:line-clamp-1"
        >
          {buttonLabel}
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="#677389"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.667"
              d="M5 7.5l5 5 5-5"
            ></path>
          </svg>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full min-w-[350px] p-0">
        <Command shouldFilter={false} className="overflow-visible">
          <CommandInput
            value={search}
            onValueChange={(e) => onChangeSearch(e)}
            className="w-full"
            placeholder="Ürün ara"
          />
          <CommandEmpty>Ürün bulunamadı</CommandEmpty>
          <CommandGroup className="h-full overflow-auto">
            {data?.map((product) => (
              <CommandItem
                onSelect={() => {
                  setValue(product.value);
                  setOpen(false);
                }}
                key={product.value}
                value={product.value}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    (value === product.value && "opacity-100") || "opacity-0",
                  )}
                />
                {product.label.length > 24
                  ? product.label.slice(0, 24) + "..."
                  : product.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default SelectProduct;
