/* eslint-disable react/prop-types */
import { Tag } from "@egaranti/components";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import InfoTooltip from "../shared/infoTooltip";
import GeneralSummaryCard from "./generalSummaryCard";
import GeneralSummarySectionSkeleton from "./generalSummarySectionSkeleton";

import StatsService from "@/services/statsService";

import { animate } from "framer-motion";

const GeneralSummarySection = () => {
  const { t } = useTranslation();

  const [data, setData] = useState();

  useEffect(() => {
    StatsService.getGeneralStats().then((data) => {
      setData(data);
    });
  }, []);

  if (!data) return <GeneralSummarySectionSkeleton />;

  return (
    <section className="flex flex-col gap-4">
      <div className="flex flex-col justify-between gap-6">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium text-[#111729]">
            {t("overviewPage.generalSummary")}
          </h2>
          <span className="flex items-center gap-1 rounded-lg border border-[#EAECF0] bg-[#F2F5F9] px-5 py-3 text-xs font-medium text-[#111729]">
            <span
              dangerouslySetInnerHTML={{
                __html: t("overviewPage.creditsMessage", { count: data[5] }),
              }}
            />
            <InfoTooltip>{t("overviewPage.infoCredit")}</InfoTooltip>
          </span>
        </div>
        <div className="grid grid-cols-1 gap-4 xl:max-w-none xl:grid-cols-3">
          <GeneralSummaryCard>
            <GeneralSummaryCard.LeftSide>
              <h2 className="text-sm font-semibold text-[#677389]">
                {t("overviewPage.product")}
              </h2>
              <h3 className="text-2xl font-bold">
                <Counter from={0} to={data[0].merchantProductCount} />
              </h3>
            </GeneralSummaryCard.LeftSide>
            <GeneralSummaryCard.RightSide>
              <h2 className="text-sm font-semibold text-[#677389]">
                {t("overviewPage.productfromintegration")}
              </h2>
              <h3 className="text-2xl font-bold">
                <Counter
                  from={0}
                  to={data[0].merchantIntegrationProductCount}
                />
              </h3>
            </GeneralSummaryCard.RightSide>
          </GeneralSummaryCard>
          <GeneralSummaryCard>
            <GeneralSummaryCard.LeftSide>
              <h2 className="text-sm font-semibold text-[#677389]">
                {t("overviewPage.individualCustomer")}
              </h2>
              <h3 className="text-2xl font-bold">
                <Counter
                  from={0}
                  to={data[3].individualCustomerStatDto.individualCustomerCount}
                />
              </h3>
            </GeneralSummaryCard.LeftSide>
            <GeneralSummaryCard.RightSide>
              <h2 className="text-sm font-semibold text-[#677389]">
                {t("overviewPage.corporateCustomer")}
              </h2>
              <h3 className="text-2xl font-bold">
                <Counter
                  from={0}
                  to={data[3].corporateCustomerStatDto.corporateCustomerCount}
                />
              </h3>
            </GeneralSummaryCard.RightSide>
          </GeneralSummaryCard>
          {/* claims */}
          <GeneralSummaryCard>
            <GeneralSummaryCard.LeftSide>
              <h2 className="text-sm font-semibold text-[#677389]">
                {t("overviewPage.serviceDemands")}
              </h2>
              <h3 className="text-2xl font-bold">
                <Counter from={0} to={data[4].serviceClaimCount} />
              </h3>
              <div className="flex items-center gap-2 text-xs font-medium text-[#364153]">
                {data[4].serviceClaimsPendingStatusCount}
                <Tag size="xs" variant="yellow">
                  {t("overviewPage.pending")}
                </Tag>
              </div>
            </GeneralSummaryCard.LeftSide>
            <GeneralSummaryCard.RightSide>
              <h2 className="text-sm font-semibold text-[#677389]">
                {t("overviewPage.setupDemands")}
              </h2>
              <h3 className="text-2xl font-bold">
                <Counter from={0} to={data[4].setupClaimCount} />
              </h3>
              <div className="flex items-center gap-2 text-xs font-medium text-[#364153]">
                {data[4].setupClaimsPendingStatusCount}
                <Tag size="xs" variant="yellow">
                  {t("overviewPage.pending")}
                </Tag>
              </div>
            </GeneralSummaryCard.RightSide>
          </GeneralSummaryCard>
        </div>
      </div>
    </section>
  );
};

export default GeneralSummarySection;

function Counter({ from, to }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = Math.round(value);
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <p ref={nodeRef} />;
}
