import { Tabs } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import useUserStore from "@/stores/useUserStore";

import AdminTab from "@/components/settings/account/adminTab";
import CompanyTab from "@/components/settings/account/companyTab";

const AccountSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const tab = query.get("tab");

  const { user, merchant, getData } = useUserStore();

  const [activeTab, setActiveTab] = useState(
    tab === "merchant" ? 2 : tab === "admin" ? 1 : 1,
  );

  const tabs = [
    { id: 1, title: t("settingsPage.adminInfo") },
    { id: 2, title: t("settingsPage.merchantInfo") },
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className="min-h-screen bg-[#f9fafc] p-7">
      <Tabs
        tabs={tabs}
        selectedTabId={activeTab}
        onTabChange={(id) => {
          setActiveTab(id);
          navigate(`/settings/account?tab=${id === 1 ? "admin" : "merchant"}`);
        }}
      />
      <div className="mt-7">
        {activeTab === 1 && <AdminTab user={user} getData={getData} />}
        {activeTab === 2 && (
          <CompanyTab merchant={merchant} getData={getData} />
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
