import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import { FileLink } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import serviceRecordsService from "@/services/serviceRecordsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ClaimEditPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [data, setData] = useState({});

  useEffect(() => {
    serviceRecordsService.getClaim(id).then((res) => {
      setData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/service-record">
          {t("claimsPage.serviceRecords")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("claimsPage.serviceRecordDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("claimsPage.serviceRecordDetails")}
          </h2>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.requestNumber")}</Label>
            <Input value={data?.id} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.customerName")}</Label>
            <Input
              value={
                data?.individualCustomerFirstName +
                " " +
                data?.individualCustomerLastName
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.province")}</Label>
            <Input value={data?.city} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.state")}</Label>
            <Input value={data?.district} disabled />
          </div>
        </div>
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">İş Emri</h2>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.productName")}</Label>
            <Input value={data?.individualCustomerProductName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.reason")}</Label>
            <Input
              value={
                data?.reason === "FAULT"
                  ? t("claimsPage.fault")
                  : t("claimsPage.setup")
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.creationDate")}</Label>
            <Input value={data?.createdAt} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.processDate")}</Label>
            <Input value={data?.processDate} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.warrantyStatus")}</Label>
            <Input
              value={
                // t("claimsPage.notGuaranteed"), t("claimsPage.guaranteed")
                data?.state === "GUARANTEED"
                  ? t("claimsPage.guaranteed")
                  : t("claimsPage.notGuaranteed")
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.sparePart")}</Label>
            <Input value={data?.sparePart} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.note")}</Label>
            <Textarea value={data?.note} disabled />
          </div>
          {data?.url && <FileLink name="Dosya Eki" url={data?.url} />}
        </div>
      </div>
    </main>
  );
};

export default ClaimEditPage;
