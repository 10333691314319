import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LeftArrowIcon from "../../assets/icons/leftArrow.svg?react";
import EmptyTable from "../shared/EmptyTable";
//import PaymentCard from "./paymentCard";
import SavedTreeCard from "./savedTreeCard";
import UnSuccessWarraties from "./unSuccessWarraties";

import statsService from "@/services/statsService";

const TablesSection = () => {
  const { t } = useTranslation();

  const [data, setData] = useState({
    integrations: [],
    sellers: [],
    merchantUsers: [],
  });

  const tablesProps = [
    {
      title: t("overviewPage.ratesOfProductsReceivingWarranty"),
      dataKey: "frequentlyMerchantProducts",
      link: "/products/products",
      cols: [
        { name: "Model", key: "productName" },
        { name: t("overviewPage.quantity"), key: "count" },
      ],
    },
    {
      title: t("overviewPage.installedIntegrations"),
      link: "/applications",
      dataKey: "integrations",
      cols: [
        { name: t("overviewPage.integration"), key: "source" },
        { name: t("overviewPage.warrantyPercent"), key: "rate" },
      ],
    },
  ];

  useEffect(() => {
    statsService.getGeneralStats().then((data) => {
      setData({
        integrations: data[6],
        frequentlyMerchantProducts: data[2],
        tree: data[0],
      });
    });
  }, []);

  return (
    <div className="grid grid-cols-1 gap-10 xl:grid-cols-2">
      {tablesProps.map((tableProps, index) => (
        <div className="flex flex-col gap-3" key={index}>
          <Link className="flex items-center gap-2" to={tableProps.link}>
            <h3 className="text-lg font-medium text-[#111729]">
              {tableProps.title}
            </h3>
            <LeftArrowIcon />
          </Link>
          {data[tableProps.dataKey]?.length > 0 ? (
            <div className="rounded-lg">
              <Table className="rounded-lg border border-[#EAECF0] bg-white shadow-sm">
                <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
                  <TableRow>
                    {tableProps.cols?.map((col, index) => (
                      <TableHead key={index}>{col.name}</TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody className="rounded-lg">
                  {data[tableProps.dataKey]?.map((row, index) => (
                    <TableRow key={index}>
                      {tableProps.cols?.map((col, i) => (
                        <TableCell key={i}>
                          <div className="flex items-center gap-2">
                            {col.key === "rate" ? (
                              <Tag variant="yellow" size="sm">
                                {row[col.key]}%
                              </Tag>
                            ) : (
                              row[col.key]
                            )}
                            {/* integration logoUrl */}
                            {col.key === "source" && (
                              <img
                                className="h-6"
                                src={row.logoUrl}
                                alt={row.source}
                              />
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <EmptyTable
              className="rounded-lg bg-white"
              title={t("overviewPage.noData")}
            />
          )}
        </div>
      ))}
      <SavedTreeCard data={data.tree} />
      {/* <PaymentCard /> */}
      <UnSuccessWarraties />
    </div>
  );
};

export default TablesSection;
