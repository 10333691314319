import DocumentsService from "@/services/documentsService";

import { create } from "zustand";

const useDocumentsStore = create((set) => ({
  documents: [],
  loading: false,
  filters: {
    name: "",
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
  },
  documentsProducts: {
    documentId: null,
    linkedProducts: {
      data: [],
      filter: {
        page: 1,
        size: localStorage.getItem("pageSize") || 10,
        mainCategoryId: null,
        categoryId: null,
        subCategoryId: null,
        subCategoryBrandId: null,
        name: "",
      },
    },
    unlinkedProducts: {
      data: [],
      filter: {
        page: 1,
        size: localStorage.getItem("pageSize") || 10,
        mainCategoryId: null,
        categoryId: null,
        subCategoryId: null,
        subCategoryBrandId: null,
        name: "",
      },
    },
  },
  getDocuments: async () => {
    set({ loading: true });
    const data = await DocumentsService.getDocuments(
      useDocumentsStore.getState().filters,
    );

    set({
      documents: data.content,
      loading: false,
      filters: {
        ...useDocumentsStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },

  setLinkedProductsFilter: (filter) => {
    set({
      documentsProducts: {
        ...useDocumentsStore.getState().documentsProducts,
        linkedProducts: {
          ...useDocumentsStore.getState().documentsProducts.linkedProducts,
          filter,
        },
      },
    });
  },
  setUnlinkedProductsFilter: (filter) => {
    set({
      documentsProducts: {
        ...useDocumentsStore.getState().documentsProducts,
        unlinkedProducts: {
          ...useDocumentsStore.getState().documentsProducts.unlinkedProducts,
          filter,
        },
      },
    });
  },

  unlinkProducts: async (documentId, data) => {
    const response = await DocumentsService.unlinkProducts(documentId, data);
    return response;
  },

  unlinkProductsFilter: async (documentId, data) => {
    const response = await DocumentsService.unlinkProductsFilter(
      documentId,
      data,
    );
    return response;
  },

  linkProducts: async (documentId, data) => {
    const response = await DocumentsService.linkProducts(documentId, data);
    return response;
  },

  linkProductsFilter: async (documentId, data) => {
    const response = await DocumentsService.linkProductsFilter(
      documentId,
      data,
    );
    return response;
  },

  getDocument: async (documentId) => {
    const data = await DocumentsService.getDocument(documentId);
    return data;
  },

  getDocumentLinkedProducts: async (documentId) => {
    set({ loading: true });
    const data = await DocumentsService.getDocumentLinkedProducts(
      documentId,
      useDocumentsStore.getState().documentsProducts.linkedProducts.filter,
    );

    set({
      loading: false,
      documentsProducts: {
        ...useDocumentsStore.getState().documentsProducts,
        documentId,
        linkedProducts: {
          ...useDocumentsStore.getState().documentsProducts.linkedProducts,
          data: data.content,
          filter: {
            ...useDocumentsStore.getState().documentsProducts.linkedProducts
              .filter,
            totalPages: data.totalPages,
            page: data.page,
            totalElements: data.totalElements,
          },
        },
      },
    });
  },

  getDocumentUnlinkedProducts: async (documentId) => {
    set({ loading: true });
    const data = await DocumentsService.getDocumentUnlinkedProducts(
      documentId,
      useDocumentsStore.getState().documentsProducts.unlinkedProducts.filter,
    );

    set({
      loading: false,
      documentsProducts: {
        ...useDocumentsStore.getState().documentsProducts,
        documentId,
        unlinkedProducts: {
          ...useDocumentsStore.getState().documentsProducts.unlinkedProducts,
          data: data.content,
          filter: {
            ...useDocumentsStore.getState().documentsProducts.unlinkedProducts
              .filter,
            totalPages: data.totalPages,
            page: data.page,
            totalElements: data.totalElements,
          },
        },
      },
    });
  },

  linkProductsDocument: async (documentId, bodyObj) => {
    const response = await DocumentsService.linkProductsDocument(
      documentId,
      bodyObj,
    );

    return response;
  },

  unlinkProductsDocument: async (documentId, bodyObj) => {
    const response = await DocumentsService.unlinkProductsDocument(
      documentId,
      bodyObj,
    );

    return response;
  },
}));

export default useDocumentsStore;
