import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Switch } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import additionaPackagesService from "@/services/additionaPackagesService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const CreateExtraWarranty = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm({
    resolver: zodResolver(
      z.object({
        title: z.string().min(3, t("common.validateMsg.requiredField")),
        description: z.string().min(3, t("common.validateMsg.requiredField")),
        expiredTime: z.string(),
        actived: z.boolean(),
        file: z.instanceof(File),
      }),
    ),
    defaultValues: {
      title: "",
      description: "",
      expiredTime: "2",
      actived: false,
      file: new File([], ""),
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("multipartFile", data.file);

    additionaPackagesService
      .createExtraWarrantyPlan(data, formData)
      .then((res) => {
        toast({
          title: t("additionalServicesPage.planCreatedSuccessfully"),
          variant: "success",
        });
        form.reset();
        navigate(
          `/additional-packages/extra-warranty/${res.data.merchantExtraWarrantyPlanId}`,
        );
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/additional-packages/extra-warranty">
          {t("additionalServicesPage.additionalPackages")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("additionalServicesPage.addAdditionalWarrantyPackage")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <h2 className="mb-5 font-medium">
            {t("additionalServicesPage.packetInfo")}
          </h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid w-full grid-cols-2 gap-4"
            >
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("additionalServicesPage.title")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("additionalServicesPage.image")}</Label>
                    <FormControl>
                      <Input
                        accept=".jpg, .jpeg, .png, .pdf"
                        type="file"
                        onChange={(e) =>
                          field.onChange(
                            e.target.files ? e.target.files[0] : null,
                          )
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("additionalServicesPage.description")}</Label>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expiredTime"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <Label>{t("additionalServicesPage.validityPeriod")}</Label>
                    <FormControl>
                      <Input type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="activated"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <Label>{t("additionalServicesPage.active")}</Label>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <div className="flex w-full justify-end">
          <Button onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default CreateExtraWarranty;
