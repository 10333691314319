import React from "react";

import AuthHeader from "@/components/auth/authHeader";
import RegisterForm from "@/components/auth/registerForm";

const Register = () => {
  return (
    <div className="bg-[#F9FAFC] pb-12">
      <AuthHeader />
      <main
        className="flex w-full items-center justify-center"
        style={{ minHeight: "calc(100vh - 7rem)" }}
      >
        <RegisterForm />
      </main>
    </div>
  );
};

export default Register;
