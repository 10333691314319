import axios from "@/lib/axios";

class ServiceRecordsService {
  constructor() {
    this.api = axios;
  }

  async getClaims(filters) {
    const { data } = await this.api.get(
      "/individual-customer-product-service-records/for-merchants",
      { params: filters },
    );

    return data;
  }

  async getClaim(id) {
    const { data } = await this.api.get(
      `/individual-customer-product-service-records/${id}/for-merchants`,
    );

    return data;
  }

  async createServiceRecord(payload) {
    const { data } = await this.api.post(
      "/individual-customer-product-service-records/for-merchants",
      payload,
    );

    return data;
  }
}

export default new ServiceRecordsService();
