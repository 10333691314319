const SkeletonGeneralSummaryCard = () => {
  return (
    <div className="grid w-full animate-pulse grid-cols-2 divide-x rounded-lg border border-[#EAECF0] bg-white px-4 py-6 text-center shadow-sm">
      <div className="flex flex-col items-center justify-center gap-3">
        <div className="h-6 w-24 rounded bg-gray-300"></div>
        <div className="h-8 w-16 rounded bg-gray-300"></div>
        <div className="h-4 w-20 rounded bg-gray-300"></div>
      </div>
      <div className="flex flex-col items-center justify-center gap-3">
        <div className="h-6 w-24 rounded bg-gray-300"></div>
        <div className="h-8 w-16 rounded bg-gray-300"></div>
        <div className="h-4 w-20 rounded bg-gray-300"></div>
      </div>
    </div>
  );
};

const GeneralSummarySectionSkeleton = () => {
  return (
    <section
      aria-hidden="true"
      aria-busy="true"
      className="flex flex-col gap-4"
    >
      <div className="flex flex-col justify-between gap-6">
        <div className="flex items-center justify-between">
          <div className="h-6 w-48 rounded bg-gray-300"></div>
          <span className="flex items-center gap-1 rounded-lg border border-[#EAECF0] bg-[#F2F5F9] px-5 py-3">
            <div className="h-4 w-24 rounded bg-gray-300"></div>
          </span>
        </div>
        <div className="grid grid-cols-1 gap-4 xl:max-w-none xl:grid-cols-3">
          <SkeletonGeneralSummaryCard />
          <SkeletonGeneralSummaryCard />
          <SkeletonGeneralSummaryCard />
        </div>
      </div>
    </section>
  );
};

export default GeneralSummarySectionSkeleton;
