import axios from "@/lib/axios";

class ServiceClaimsService {
  constructor() {
    this.api = axios;
  }
  async getClaims(filters) {
    const { data } = await this.api.get(
      "/individual-customer-product-service-claims",
      { params: filters },
    );
    return data;
  }

  async getClaim(id) {
    const { data } = await this.api.get(
      `/individual-customer-product-service-claims/${id}`,
    );
    return data;
  }

  async updateClaim(id, data) {
    const response = await this.api.put(
      `/individual-customer-product-service-claims/${id}/for-merchants`,
      data,
    );
    return response;
  }

  async postMessage(id, message, data) {
    const response = await this.api.post(
      `/individual-customer-product-service-claims/${id}/message/for-merchants?message=${message}`,
      data,
    );
    return response;
  }

  async getMessages(id) {
    const { data } = await this.api.get(
      `/individual-customer-product-service-claims/${id}/message/for-merchants`,
    );
    return data;
  }
}

export default new ServiceClaimsService();
