/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useCorporateCustomerStore from "@/stores/useCorporateCustomersStore";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { filters, getCorporateCustomers, setFilters } =
    useCorporateCustomerStore();
  const [filtersInSheet, setFiltersInSheet] = useState({
    name: "",
    email: "",
    vkn: "",
  });

  const onSaveFilter = () => {
    onOpenChange(false);
    setFilters({
      ...filters,
      ...filtersInSheet,
      page: 1,
    });
    getCorporateCustomers();
  };

  useEffect(() => {
    setFiltersInSheet({
      name: filters.name,
      email: filters.email,
      vkn: filters.vkn,
    });
  }, [filters]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Label>{t("clientsPage.brandName")}</Label>
          <Input
            value={filtersInSheet.name}
            onChange={(e) =>
              setFiltersInSheet({
                ...filtersInSheet,
                name: e.target.value,
              })
            }
          />
          <Label>{t("clientsPage.email")}</Label>
          <Input
            value={filtersInSheet.email}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, email: e.target.value })
            }
          />
          <Label>{t("clientsPage.taxNumber")}</Label>
          <Input
            value={filtersInSheet.vkn}
            onChange={(e) =>
              setFiltersInSheet({ ...filtersInSheet, vkn: e.target.value })
            }
          />
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
