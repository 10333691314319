import { Pagination } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import statsService from "@/services/statsService";

import { cn } from "@/lib/utils";

const UnSuccessWarranties = () => {
  const { t } = useTranslation();
  const [openItem, setOpenItem] = useState(null);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    size: 5,
  });

  const fetchData = (page, size) => {
    statsService
      .getUnSuccessWarranties({ page, size })
      .then((response) => {
        setData(response.data.content);
        setPagination((prevPagination) => ({
          ...prevPagination,
          page,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(pagination.page, pagination.size);
  }, [pagination.page, pagination.size]);

  const toggleItem = (id) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
  };

  const renderPayloadAsTable = (request) => {
    try {
      const message = JSON.parse(request.message);
      return (
        <table className="w-full">
          <tbody>
            {Object.entries(message).map(([key, value]) => (
              <tr key={key}>
                <td className="py-1 pr-2 font-semibold">
                  {t(`unSuccessWarranties.${key}`)}:
                </td>
                <td className="py-1 text-gray-700">
                  {value !== null ? value : t("unSuccessWarranties.unknown")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } catch (error) {
      console.error("Error parsing message: ", error);
    }
  };

  return (
    <div className="flex flex-col">
      <h2 className="flex items-center gap-1 text-lg font-medium text-[#111729]">
        {t("unSuccessWarranties.title")}
      </h2>
      {data.length === 0 && (
        <div className="mt-3 flex h-full flex-col items-center justify-center gap-3 rounded-lg border bg-white p-4 text-[#054F31] shadow-sm">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#A6F4C5] shadow-sm">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 9.16667L10 11.6667L18.3333 3.33333M13.3333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V10"
                stroke="#054F31"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p className="text-center text-lg" aria-live="polite">
            {t("unSuccessWarranties.noDataAvailable")}
          </p>
        </div>
      )}
      {data.length > 0 && (
        <ul className="mt-3 flex h-full flex-col rounded-lg border bg-gray-50">
          {data.map((request) => {
            const productName = JSON.parse(request.message)?.productName;
            return (
              <li
                key={request.id}
                className={cn(
                  "last:border-b-none border-b bg-white p-4 text-[#364153] first:rounded-t-lg last:rounded-b-lg",
                )}
              >
                <button
                  className="flex w-full cursor-pointer items-center justify-between"
                  onClick={() => toggleItem(request.id)}
                  role="button"
                  aria-expanded={openItem === request.id}
                  aria-controls={`request-details-${request.id}`}
                >
                  <span className="flex items-center justify-center text-center text-sm font-medium">
                    {productName.length > 40
                      ? productName.substring(0, 40) + "..."
                      : productName}
                  </span>
                  <span>{openItem === request.id ? "-" : "+"}</span>
                </button>
                {openItem === request.id && (
                  <div
                    id={`request-details-${request.id}`}
                    className={`mt-4 rounded-lg border-l-4 border-gray-200 bg-gray-50 p-4 pl-6 text-sm text-[#111729]`}
                    role="region"
                    aria-labelledby={`request-${request.id}`}
                  >
                    <p className="mb-2 font-semibold">
                      {t("unSuccessWarranties.timestamp")}:
                    </p>
                    <p className="mb-4 text-gray-700">{request.timestamp}</p>
                    <p className="mb-2 font-semibold">
                      {t("unSuccessWarranties.requestMade")}:
                    </p>
                    {renderPayloadAsTable(request)}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
      {data.totalPages > 1 && (
        <div className="rounded-b-lg border-x border-b bg-white pb-3">
          <Pagination
            totalPage={data.totalPages}
            currentPage={pagination.page}
            pageSize={pagination.size}
            onPageChange={(page) => {
              fetchData(page, pagination.size);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UnSuccessWarranties;
