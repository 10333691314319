/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useAuthStore from "@/stores/useAuthStore";

import * as z from "zod";

const PasswordResetSheet = ({ onOpenChange, open, onClose }) => {
  const { t } = useTranslation();
  const { changePassword } = useAuthStore();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const schema = z.object({
    currentPassword: z
      .string()
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      }),
    password: z
      .string()
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      })
      .refine((data) => data !== form.getValues().currentPassword, {
        message: t("common.validateMsg.passwordsShouldNotMatch"),
      }),
    confirmPassword: z
      .string()
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      })
      .refine((data) => data === form.getValues().password, {
        message: t("common.validateMsg.passwordsDoNotMatch"),
      }),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const bodyObj = {
      currentPassword: form.getValues().currentPassword,
      newPassword: form.getValues().password,
    };

    try {
      await changePassword(bodyObj);
      toast({
        description: t("settingsPage.resetPasswordSheet.resetSuccess"),
        variant: "success",
      });
      onClose();
    } catch (err) {
      toast({
        description: err.response?.data?.errors[0]?.message,
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("settingsPage.resetPasswordSheet.title")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <Form {...form}>
            <form className="space-y-5">
              <FormField
                control={form.control}
                name="currentPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <>
                        <Label>
                          {t("settingsPage.resetPasswordSheet.currentPassword")}
                        </Label>
                        <Input type="password" {...field} />
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <>
                        <Label>
                          {t("settingsPage.resetPasswordSheet.newPassword")}
                        </Label>
                        <Input type="password" {...field} />
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <>
                        <Label>
                          {t(
                            "settingsPage.resetPasswordSheet.newPasswordConfirm",
                          )}
                        </Label>
                        <Input type="password" {...field} />
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <SheetFooter className="mt-4">
          <Button variant="secondaryGray" onClick={onClose}>
            {t("settingsPage.resetPasswordSheet.cancel")}
          </Button>
          <Button onClick={form.handleSubmit(onSubmit)}>
            {t("settingsPage.resetPasswordSheet.reset")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default PasswordResetSheet;
