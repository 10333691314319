import BrandLogo from "./brandLogo";
import Navbar from "./navbar";

const Sidebar = () => {
  return (
    <aside
      id="default-sidebar"
      className="hidden h-screen w-full max-w-[300px] flex-col border-r bg-white px-4 py-6 md:flex"
      aria-label="Sidebar"
    >
      <BrandLogo />
      <Navbar />
    </aside>
  );
};

export default Sidebar;
