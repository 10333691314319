/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";

const PageSizeSelect = ({ onPageSizeChange, value }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4 flex items-center">
      <label htmlFor="pageSize" className="mr-2 text-sm text-gray-600">
        {t("common.pageSize")} :
      </label>
      <select
        id="pageSize"
        onChange={(e) => onPageSizeChange(e.target.value)}
        className="rounded-md border border-gray-300 p-1"
        value={value}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
};

export default PageSizeSelect;
