import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FilterTags from "./filterTags";
import FilterSheet from "./fiterSheet";

import documentsService from "@/services/documentsService";

import useDocumentsStore from "@/stores/useDocumentsStore";

import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import { set } from "date-fns";

const LinkedProducts = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { id } = useParams();

  const [pending, setPending] = useState(false);
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const {
    loading,
    getDocumentLinkedProducts,
    setLinkedProductsFilter,
    unlinkProductsDocument,
  } = useDocumentsStore();
  const { linkedProducts } = useDocumentsStore(
    (state) => state.documentsProducts,
  );
  const { filter } = useDocumentsStore(
    (state) => state.documentsProducts.linkedProducts,
  );

  useEffect(() => {
    getDocumentLinkedProducts(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setLinkedProductsFilter({ ...filter, page });
    getDocumentLinkedProducts(id);
  };

  const onPageSizeChange = (size) => {
    setLinkedProductsFilter({ ...filter, size, page: 1 });
    getDocumentLinkedProducts(id);
  };

  const onRemoveProducts = () => {
    setPending(true);
    documentsService
      .unlinkProducts(id, {
        merchantProductIds: selectedProducts,
      })
      .then(() => {
        toast({
          title: t("settingsPage.productsRemovedSuccessfully"),
          variant: "success",
        });

        setTimeout(() => {
          getDocumentLinkedProducts(id);
          setSelectedProducts([]);
          setPending(false);
        }, 1000);
      });
  };

  const onSelectAll = () => {
    if (linkedProducts?.data.length === selectedProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(linkedProducts?.data.map((product) => product.id));
    }
  };

  const onDeleteAll = async () => {
    try {
      await unlinkProductsDocument(id, {
        mainCategoryId: filter.mainCategoryId,
        categoryId: filter.categoryId,
        subCategoryId: filter.subCategoryId,
        subCategoryBrandId: filter.subCategoryBrandId,
        name: filter.name,
      });
      toast({
        title: t("settingsPage.productsDeleted"),
        description: t("settingsPage.thisCanTakeSomeTime"),
        variant: "success",
      });
    } catch (err) {
      toast({
        description: err.response?.data?.errors[0]?.message,
        type: "error",
      });
    }
  };

  return (
    <div>
      <TableHeaderWrapper className="flex items-center justify-between gap-5">
        <FilterTags />
        <Button
          onClick={() => setOpenFilterSheet(true)}
          variant="secondaryGray"
          className="min-w-[150px] font-medium"
        >
          {t("common.addFilter")}
        </Button>
      </TableHeaderWrapper>
      {loading ? (
        <Table>
          <TableSkeleton colSpan={3} />
        </Table>
      ) : linkedProducts?.data?.length ? (
        <Table className="border border-[#EAECF0] bg-white shadow-sm">
          <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
            <TableRow>
              <TableHead>
                <Checkbox
                  checked={
                    linkedProducts?.data.length === selectedProducts.length
                  }
                  onClick={onSelectAll}
                />
              </TableHead>
              <TableHead>{t("settingsPage.productName")}</TableHead>
              <TableHead>{t("settingsPage.brand")}</TableHead>
              <TableHead>{t("settingsPage.source")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {linkedProducts?.data.map((product, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedProducts.includes(product.id)}
                    onClick={() => {
                      if (selectedProducts.includes(product.id)) {
                        setSelectedProducts(
                          selectedProducts.filter((id) => id !== product.id),
                        );
                      } else {
                        setSelectedProducts([...selectedProducts, product.id]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.brand}</TableCell>
                <TableCell>{product.source}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyTable
          title={t("common.emptyTableNoData")}
          desc={t("common.emptyTableNoDataDesc")}
        />
      )}
      <div className="flex flex-col items-center">
        {filter.totalPages > 1 && (
          <Pagination
            totalPages={filter.totalPages}
            currentPage={filter.page}
            pageSize={filter.size}
            onPageChange={(page) => onPageChange(page)}
          />
        )}
        <PageSizeSelect
          onPageSizeChange={onPageSizeChange}
          value={filter.size}
        />
      </div>
      <div className="mt-5 flex justify-end gap-2">
        {linkedProducts?.data?.length > 0 && (
          <Button onClick={onDeleteAll}>
            ({filter.totalElements}) {t("settingsPage.deleteAll")}
          </Button>
        )}
        <Button
          className="rounded-lg border border-[#E3E7EF] bg-red-600 px-4 py-2 text-gray-200 disabled:opacity-50"
          disabled={selectedProducts.length === 0 || pending}
          onClick={onRemoveProducts}
        >
          {t("settingsPage.removeProducts")}
        </Button>
      </div>
      <FilterSheet
        id={id}
        open={openFilterSheet}
        onOpenChange={setOpenFilterSheet}
      />
    </div>
  );
};

export default LinkedProducts;
