import axios from "@/lib/axios";

class ProductActivationService {
  constructor() {
    this.api = axios;
  }

  async getProductActivations() {
    const { data } = await this.api.get(
      "/merchant-product-registration-activations",
    );

    return data;
  }

  async getProductActivation(id) {
    const { data } = await this.api.get(
      `/merchant-product-registration-activations/${id}`,
    );

    return data;
  }

  async getLinkedProducts(productRegistrationActivationId, filters) {
    const { data } = await this.api.get(
      `/merchant-product-registration-activations/${productRegistrationActivationId}/linked-products`,
      { params: filters },
    );

    return data;
  }

  async getUnlinkedProducts(filters) {
    const { data } = await this.api.get(
      "/merchant-product-registration-activations/unlinked-products",
      { params: filters },
    );

    return data;
  }

  async linkProducts(data) {
    const response = await this.api.put(
      `/merchant-product-registration-activations/${data.productRegistrationActivationId}/link-products`,
      data,
    );

    return response;
  }

  async unlinkProducts(data) {
    const response = await this.api.put(
      `/merchant-product-registration-activations/${data.productRegistrationActivationId}/unlink-products`,
      data,
    );

    return response;
  }

  async deleteActivation(id) {
    const response = await this.api.delete(
      `/merchant-product-registration-activations/${id}`,
    );

    return response;
  }

  async createActivation(data, formData) {
    const response = await this.api.post(
      "/merchant-product-registration-activations",
      formData,
      {
        params: {
          ...data,
          file: undefined,
        },
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  }

  async updateActivation(data, formData) {
    const response = await this.api.put(
      `/merchant-product-registration-activations/${data.id}`,
      formData,
      {
        params: {
          ...data,
        },
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  }
}

export default new ProductActivationService();
