import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Switch } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ProductService from "@/services/productService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const ProductCreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const formSchema = z.object({
    mainCategory: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    categoryId: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    subCategoryId: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    subCategoryBrandId: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    name: z.string().min(1, { message: t("common.validateMsg.requiredField") }),
    setupRequired: z.boolean(),
    warrantyDuration: z.string(),
    imageUrl: z.any().optional(),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      mainCategory: "",
      categoryId: "",
      subCategoryId: "",
      subCategoryBrandId: "",
      name: "",
      model: "",
      warrantyDuration: "24",
      setupRequired: false,
      description: "",
      imageUrl: null,
    },
  });

  const [mainCategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const getMainCategories = async () => {
    const { data } = await ProductService.getMainCategories();
    setMainCategories(data);
  };

  const getCategories = async (e) => {
    const { data } = await ProductService.getCategories(e);
    setCategories(data);
  };

  const getSubCategories = async (e) => {
    const { data } = await ProductService.getSubCategories(e);
    setSubCategories(data);
  };

  const getBrands = async (e) => {
    const { data } = await ProductService.getSubCategoryBrands(e);
    setBrands(data);
  };

  useEffect(() => {
    getMainCategories();
  }, []);

  const handleMainCategoryChange = (e) => {
    getCategories(e);
  };

  const handleCategoryChange = (e) => {
    getSubCategories(e);
  };

  const handleSubCategoryChange = (e) => {
    getBrands(e);
  };

  const onSubmit = (data) => {
    setLoading(true);
    ProductService.createProduct(data)
      .then((res) => {
        toast({
          title: t("productsPage.addProductSuccess"),
          variant: "success",
        });
        const formData = new FormData();
        formData.append("file", data.imageUrl);

        ProductService.postProductImage(res?.data?.id, formData);
        navigate(`/products/products`);
        form.reset();
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response?.data?.errors[0].message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/products/products">
          {t("productsPage.products")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("common.addProduct")}</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productCategory")}
              </h2>
              <div className="space-y-5">
                <div className="flex flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="mainCategory"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.mainCategory")} *</Label>
                        <Select
                          {...field}
                          onValueChange={(e) => {
                            field.onChange(e);
                            handleMainCategoryChange(e);
                          }}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {mainCategories.map((category, index) => (
                              <SelectItem
                                key={index}
                                value={category.id.toString()}
                              >
                                {category.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {categories.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="categoryId"
                      render={({ field }) => (
                        <FormItem>
                          <Label>{t("productsPage.category")} *</Label>
                          <Select
                            {...field}
                            onValueChange={(e) => {
                              field.onChange(e);
                              handleCategoryChange(e);
                            }}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {categories.map((category, index) => (
                                <SelectItem
                                  key={index}
                                  value={category.id.toString()}
                                >
                                  {category.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                {subCategories.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="subCategoryId"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            {...field}
                            onValueChange={(value) => {
                              field.onChange(value);
                              handleSubCategoryChange(value);
                            }}
                          >
                            <Label>{t("productsPage.subCategory")} *</Label>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {subCategories.map((category, index) => (
                                <SelectItem
                                  key={index}
                                  value={category.id.toString()}
                                >
                                  {category.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                {brands.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="subCategoryBrandId"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            {...field}
                            onValueChange={(e) => {
                              field.onChange(e);
                            }}
                            value={field.value}
                          >
                            <Label>{t("productsPage.brand")} *</Label>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {brands.map((brand, index) => (
                                <SelectItem
                                  key={index}
                                  value={brand.id.toString()}
                                >
                                  {brand.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="formBox flex flex-col gap-4">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productInformation")}
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.productName")} *</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="model"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Model</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="warrantyDuration"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.warrantyDuration")} *</Label>
                      <FormControl>
                        <Input
                          min="1"
                          onChange={(e) => {
                            field.onChange(Number(e));
                          }}
                          {...field}
                          type="number"
                        />
                      </FormControl>
                      <FormMessage />
                      <FormDescription>
                        {t("productsPage.warrantyDurationDesc")}
                      </FormDescription>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="setupRequired"
                  render={({ field }) => (
                    <FormItem className="flex flex-col py-4">
                      <Label>{t("productsPage.installationRequired")}</Label>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.description")}</Label>
                      <FormControl>
                        <Textarea placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="imageUrl"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.productImage")} </Label>
                      <FormControl>
                        <Input
                          accept=".jpg, .jpeg, .png, .pdf"
                          type="file"
                          onChange={(e) => {
                            field.onChange(
                              e.target.files ? e.target.files[0] : null,
                            );
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Button
              disabled={loading}
              onClick={form.handleSubmit(onSubmit)}
              className="ml-auto"
            >
              {t("common.save")}
            </Button>
          </div>
        </form>
      </Form>
    </main>
  );
};

export default ProductCreatePage;
